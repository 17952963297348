import ReactDOM from 'react-dom/client';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import './_assets/scss/main.scss'
import '@zt/fonts/lib/font-awesome-5/css/all.min.css';
import "@zt/fonts/lib/open-sans/font.css";
import { BrowserRouter } from 'react-router-dom';
import { ContextProvider } from './Context';
import { Header } from 'Components/Header';
import { AppRoutes } from './routes';
import { CookieSettings } from 'Components/CookieSettings';
import { CookiesProvider } from 'react-cookie';



const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <BrowserRouter>
    <ContextProvider>
      <CookiesProvider defaultSetOptions={{ path: '/', maxAge: 31536000 }}>
        <div style={{ display: "flex", flexDirection: "column" }}>
          <CookieSettings />
          <Header />
          <AppRoutes />
        </div>
      </CookiesProvider>
    </ContextProvider >
  </BrowserRouter>
);
