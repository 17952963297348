
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination, EffectFade, } from 'swiper/modules';
import { Context } from 'Context';
import { useContext, useRef, useEffect, useState, } from 'react';
import { Link } from 'react-router-dom';
import { RouteDefinitions } from '../../routes';
import ReactDOM from 'react-dom';


export const HomeSlider = (props: any) => {
  const appContext = useContext(Context);
  const careerOppText = appContext.data.commonWords.find(item => item.key === 'ziraat-teknoloji-kariyer-olanaklari')!.label
  const careerOppTextAsArray = careerOppText.split("\n")
  const swiperRef = useRef<any>(undefined);
  const videoRefs = useRef<any>([]);
  const [showFullVideo, setShowFullVideo] = useState(false)


  useEffect(() => {
    const fullpageIndex = props.fullpageState.destination?.index;
    const swiperInstance = swiperRef.current.swiper;

    if (fullpageIndex === 0 && videoRefs.current[swiperInstance.activeIndex] !== undefined && videoRefs.current[swiperInstance.activeIndex].paused) {
      videoRefs.current[swiperInstance.activeIndex].play();
    }
    // else if (fullpageIndex !== undefined && fullpageIndex !== 0) {
    //   videoRefs.current[swiperInstance.activeIndex].pause();
    // }
  }, [props.fullpageState.destination])



  const Badge = () => {
    return <div className="home-slider__layer">
      <Link to={RouteDefinitions.JoiningZT} className="home-slider__layer-text slider-bg">
        {careerOppTextAsArray.map((item, idx) => <>{item}<br /></>)}
        <div className="home-slider__layer-text-icon">
          <i className="svg svg--user-plus"></i>
        </div>
      </Link>
    </div>
  }


  const YoutubeVideo = <div className='fullpage-iframe-wrapper'>
    <button type="button" className="btn-close" aria-label="Close" onClick={() => setShowFullVideo(false)}></button>
    <iframe
      className='fullpage-iframe'
      width="100%"
      height="100%"
      src={"https://www.youtube.com/embed/vU9w0N-Fmfg?autoplay=1"}
      title="Ziraat Teknoloji "
      frameBorder="0"
      allow={"accelerometer; autoplay; fullscreen; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"}
    ></iframe>
  </div>



  return (
    <div className="home-slider section" >
      <Swiper
        ref={swiperRef}
        threshold={0}
        touchReleaseOnEdges
        className="home-slider-swiper"
        loop={false}
        slidesPerView={1}
        fadeEffect={{ crossFade: true }}
        effect="fade"
        grabCursor
        onActiveIndexChange={(swiper: any) => {
          const { activeIndex, previousIndex } = swiper

          if (videoRefs.current[activeIndex]) {
            videoRefs.current[activeIndex].play()
          }
          if (videoRefs.current[previousIndex]) {
            videoRefs.current[previousIndex].pause()
          }
        }}
        pagination={{
          el: ".swiper-pagination",
          clickable: true,
          renderBullet: function (index: number, className: string) {
            return '<span class="swiper-pagination-bullet">' + (index + 1) + "</span>";
          },
        }}
        modules={[EffectFade, Pagination,]}
        watchSlidesProgress

      >
        {appContext.data.sliders.map((slide, idx) => {

          return slide.video ?
            <SwiperSlide className='swiper-slide-video' key={idx} >
              {({ isActive }: any) => {
                return <>
                  {<video ref={(el) => (videoRefs.current[idx] = el)} preload="auto" loop muted playsInline autoPlay={isActive} width={"100%"}   >
                    <source src={window.innerWidth <= 768 ? `_assets/video/${slide.video}-mobil.mp4` : `_assets/video/${slide.video}.mp4`} type="video/mp4" />
                  </video>}

                  <div className="home-slider__text">
                    {slide.titles.map((title: any, idx) => <span key={idx}>{title}<br /></span>)}
                    {/* <div className="w-100 home-slider__text-btn">
                      <a onClick={() => setShowFullVideo(true)} className="d-flex align-items-center px-4">
                        {appContext.data.commonWords.find(item => item.key === 'view')!.label}
                        <i className="svg svg--right-arrow"></i>
                      </a>
                    </div> */}
                  </div>
                  {Badge()}
                </>
              }
              }

            </SwiperSlide> :
            <SwiperSlide className='swiper-slide' key={idx} >
              <div className="home-slider__bg" style={{ backgroundImage: `url(_assets/img/${slide.image})` }}></div>
              {<div className="home-slider__text">
                <a href={slide.link} target='_blank'>
                  {slide.titles.map((title: any, idx) => <span key={idx}>{title}<br /></span>)}
                </a>
              </div>}
              {Badge()}

            </SwiperSlide>
        })}
        {showFullVideo && ReactDOM.createPortal(YoutubeVideo, document.body)}

        <div className="swiper-pagination"></div>

      </Swiper>
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <a href="#teknoloji" className="down-arrow bounce"><span className="svg svg--down-arrow"></span></a>
      </div>

    </div>
  )
}