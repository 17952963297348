

import LogoSvg from '_assets/img/logo.svg'
import WhiteLogoSvg from '_assets/img/white-logo.svg'
import { RouteDefinitions } from 'routes'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import classNames from 'classnames';
import { useContext, useState } from 'react';
import { Context } from 'Context';
import ScrollToTop from './ScrolltoTop';
import { useCookies } from 'react-cookie';


export const Header = (props: any) => {
  const currentLocation = useLocation(); //Use appLocation.key to reload a route without refreshing page
  const appContext = useContext(Context);
  const [opacityLower, setOpacityLower] = useState(false)
  const appNavigate = useNavigate();
  const [searchText, setSearchText] = useState("")
  const [toggleMenu, setMenuToggle] = useState(false)
  const [toggleSubMenu, setSubMenuToggle] = useState(false)
  const [cookies, setCookie] = useCookies(['language']);



  const Logo = () => {
    return <div className="col-6 col-md-2 header__logo" data-menuanchor="anasayfa">
      <a href="/#anasayfa" onClick={() => window.scrollTo(0, 0)}>
        <img onClick={() => window.scrollTo(0, 0)} src={WhiteLogoSvg} className="header__logo-white" alt="Ziraat Teknoloji" title="Logo" />
        <img src={LogoSvg} className="header__logo-black" alt="Ziraat Teknoloji" title="Logo" />
      </a>
    </div>
  }


  const MobileHeaderButtons = () => {
    return <div className="col-auto d-md-none d-inline-block btn-wrapper-m">
      <div className="header__btns">
        <div className="header__btns-search">
          <i className="svg svg--search-icon-w"></i>
          <i className="svg svg--search-icon-b"></i>

          <div className="search-wrapper">
            <div className="form-group">
              <input
                type="search"
                id=""
                name="keyword"
                onChange={(event) => setSearchText(event.target.value)}
                className="searchText"
                onKeyDown={(key) => key.keyCode === 13 && appNavigate(RouteDefinitions.Arama + "?keyword=" + searchText)}
                autoFocus={false}
                placeholder={appContext.data.commonWords.find(item => item.key === 'enter-search-word')!.label} />
              <span className="form-search" onClick={() => appNavigate(RouteDefinitions.Arama + "?keyword=" + searchText)}>{appContext.data.commonWords.find(item => item.key === 'search')!.label}</span>
            </div>
          </div>
        </div>
        <div className="header__btns-hamburger" onClick={() => setMenuToggle(!toggleMenu)}>
          {toggleMenu ? <i className="svg svg--close active"></i> : <i className="svg svg--hamburger active"></i>}
          <i className="svg svg--hamburger-b"></i>
        </div>
      </div>
    </div>
  }


  const HeaderButtons = () => {

    return <div className="header__btns">
      <div className="header__btns-search d-md-flex d-none">
        <i className="svg svg--search-icon-w"></i>
        <i className="svg svg--search-icon-b"></i>

        <div className="search-wrapper">
          <div className="form-group">
            <input
              type="search"
              id=""
              name="keyword"
              onChange={(event) => setSearchText(event.target.value)}
              className="searchText"
              onKeyDown={(key) => key.keyCode === 13 && appNavigate(RouteDefinitions.Arama + "?keyword=" + searchText)}
              autoFocus={false}
              placeholder={appContext.data.commonWords.find(item => item.key === 'enter-search-word')!.label} />
            <span className="form-search" onClick={() => appNavigate(RouteDefinitions.Arama + "?keyword=" + searchText)}>{appContext.data.commonWords.find(item => item.key === 'search')!.label}</span>
            <div className="search-tooltip d-none">{appContext.data.commonWords.find(item => item.key === 'please-enter-min-char')!.label}.</div>
          </div>
        </div>
      </div>
      <div className="header__btns-languages">
        {appContext.languages.map((lang, idx) => {
          const languageClass = classNames({
            "btn header__btns-languages-item": true,
            "current": lang === appContext.userLanguage,
          });
          return <span key={idx}
            className={languageClass}
            data-lang={lang}
            onClick={() => {
              setOpacityLower(true)
              setTimeout(() => {
                setOpacityLower(false);
                toggleMenu && setMenuToggle(false);
                appContext.changeLanguage?.(lang);
                setCookie('language', lang)
              }, 300)
            }
            } >
            {lang.toLocaleUpperCase()}
          </span>
        })}

      </div>
    </div>
  }

  const Menu = () => {
    return <nav className="header__menu">
      <div className="menu-container">
        {appContext.data.menus.map((menuItem, idx) => {
          const menuItemClass = classNames({
            "header__menu-item": true,
            "active": currentLocation.hash === `#${menuItem.key}`,
          });
          const menuLinkClass = classNames({
            "header__menu-link": true,
            "header__menu-submenu": !!menuItem.children,
          });

          const megamenuClass = classNames({
            "megamenu": true,
            "open": toggleSubMenu,
          });

          return (
            <div key={idx} className={menuItemClass} data-menuanchor={menuItem.key}>

              {menuItem.url ?
                <Link to={menuItem.url} onClick={() => setMenuToggle(false)}> {menuItem.label}</Link> :
                <a onClick={() => menuItem.children ? setSubMenuToggle(true) : setMenuToggle(false)}
                  href={`/#${menuItem.key}`}
                  data-href={menuItem.key}
                  className={menuLinkClass}>
                  {menuItem.label}
                </a>}
              {menuItem.children &&
                <div className={megamenuClass}>
                  {menuItem.children.map((childItem, idx) => {
                    return <div key={idx} className="megamenu__item">
                      <Link
                        to={childItem.key}
                        onClick={() => setMenuToggle(false)}
                        className="megamenu__item-link">
                        <span>
                          {childItem.label}
                        </span>
                      </Link>
                    </div>
                  })}
                </div>
              }

            </div>
          )
        })}
      </div>
      {HeaderButtons()}
    </nav>
  }

  const HeaderClass = classNames({
    "col-12": true,
    "header": true,
    "white": !appContext.menuIsTransparent || toggleMenu,
    "low-opacity": opacityLower
  })

  const wrapperClass = classNames({
    "col-auto": true,
    "open": toggleMenu
  })


  return (
    <header id="header">

      {ScrollToTop()}
      <div className="row">
        <div className={HeaderClass}>
          {Logo()}
          {MobileHeaderButtons()}
          <div className={wrapperClass} id="header-menu-wrapper">
            {Menu()}
          </div>
        </div>
      </div>
    </header >
  )
}