import IfmBanner from '../../_assets/img/ifm-banner.png'
import { Swiper, SwiperSlide } from 'swiper/react';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import CountUp from 'react-countup';
import { Context } from 'Context';
import { useContext, useEffect, useRef, useState } from 'react';
import classNames from 'classnames';

export const TechnologyTab = (props: any) => {

  const appContext = useContext(Context)
  const technologyData = appContext.data.sections.find(section => section.name === "teknoloji");
  const [readMoreOpen, setReadMoreOpen] = useState(false)
  const swiperRef = useRef<any>(undefined);
  const tabRef = useRef<any>(undefined);


  useEffect(() => {
    if (swiperRef && props.selectedSubSection) {

      const subSections = technologyData?.children;

      const swiperInstance = swiperRef.current.swiper;
      const activeIndex = subSections?.findIndex(subSection => subSection.name === props.selectedSubSection);

      if (activeIndex) {
        swiperInstance.slideTo(activeIndex)
      }

    }
  }, [props.selectedSubSection])

  const TabForDesktop = () => {

    return <div className="tab-wrap d-none d-md-flex">
      <Tabs
        variant="pills"
        className='w-100'
      >
        {technologyData?.children.map((tab, idx) => {
          return <Tab key={idx} eventKey={tab.name} title={tab.title} className='tab-pane fade tab__content'>
            <div className="d-flex flex-wrap align-items-center justify-content-between">
              <div className={tab.image ? "col-12 col-md-9" : "col-12 col-md-12"}>
                <p onWheel={(event) => event.stopPropagation()}>{tab.content}</p>
              </div>
              {tab.image &&
                <div className="col-12 col-md-3">
                  <img src={`_assets/img/${tab.image}`} />
                </div>
              }
            </div>

          </Tab>
        })}
      </Tabs>
    </div>
  }


  const SwiperForMobile = () => {
    return <Swiper
      ref={swiperRef}
      threshold={0}
      touchReleaseOnEdges
      className='technology-tab-swiper swiper d-block d-md-none'
      spaceBetween={16}
      slidesPerView={"auto"}
      autoHeight={false}
      slidesOffsetAfter={16}
      slideToClickedSlide
    >

      {technologyData?.children.map((tab, idx) => {
        return <SwiperSlide key={idx} className='mCustomScrollbar'>
          <h2>{tab.title} </h2>
          <p>{tab.content}</p>
        </SwiperSlide>
      })}

    </Swiper>
  }

  const textDescriptionClass = classNames({
    "text-description": true,
    opened: readMoreOpen
  })

  const contents = technologyData?.content.split("\n")

  return (
    <div className="technology-tab section header-white teknoloji" data-id="teknoloji">
      <div className="box">
        <h1 className="section-title d-none d-md-block">{technologyData?.title}</h1>
        <div className="d-flex flex-wrap">
          <div className="col-12 col-md-6 left order-1 order-md-0">
            <h1 className="section-title d-block d-md-none">{technologyData?.title}</h1>
            <div className="text-accordion">
              {contents?.map((content, idx) => {
                return <p key={idx} className={textDescriptionClass}>
                  {content}
                </p>
              })}

              {!readMoreOpen && <span className="more-btn d-block d-md-none" onClick={() => setReadMoreOpen(true)}>
                {appContext.data.commonWords.find(item => item.key === 'read-more')?.label}
              </span>}
            </div>
            {TabForDesktop()}
            {SwiperForMobile()}
          </div>

          <div className="col-12 col-md-5 right offset-md-1  order-0 order-md-0">
            <div className="technology-tab_right">
              <div className="technology-tab_right-img" style={{ backgroundImage: `url(${IfmBanner})` }}></div>
              <div className="technology-tab_right-info">
                <div className="technology-tab_right-info-item">
                  <span>
                    <CountUp start={0} end={1400} delay={0} duration={1.5} separator="" redraw={true}>
                      {({ countUpRef }: any) => (
                        <span ref={countUpRef} />
                      )}
                    </CountUp>
                    +
                  </span>
                  {appContext.data.commonWords.find(item => item.key === 'calisan')?.label}
                </div>
                <div className="technology-tab_right-info-item">
                  <CountUp start={0} end={18} delay={0} duration={1.5} redraw={true}>
                    {({ countUpRef }: any) => (
                      <span ref={countUpRef} />
                    )}
                  </CountUp>
                  {appContext.data.commonWords.find(item => item.key === 'ulke')?.label}

                </div>
                <div className="technology-tab_right-info-item">
                  <CountUp start={0} end={15} delay={0} duration={1.5} redraw={true}>
                    {({ countUpRef }: any) => (
                      <span ref={countUpRef} />
                    )}
                  </CountUp>
                  {appContext.data.commonWords.find(item => item.key === 'kurum')?.label}

                </div>
                <div className="technology-tab_right-info-item">
                  <CountUp start={0} end={4} delay={0} duration={1.5} redraw={true}>
                    {({ countUpRef }: any) => (
                      <span ref={countUpRef} />
                    )}
                  </CountUp>
                  {appContext.data.commonWords.find(item => item.key === 'yerleske')?.label}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}