import { Swiper, SwiperSlide } from 'swiper/react';
import { Autoplay, Navigation, Pagination } from 'swiper/modules';
import { useContext } from 'react';
import { Context } from 'Context';

export const OurAwards  = (props: any) => {
  const appContext = useContext(Context)
  const ourAwardsData = appContext.data.sections.find(section => section.name === "odullerimiz");
  return (
    <div className="our-awards section header-white odullerimiz">
      <div className="box">
        <h1 className="section-title">{ourAwardsData?.title}</h1>
        <Swiper
          threshold={0}
          touchReleaseOnEdges
          className='our-awards-swiper'
          slidesPerView={1}
          slidesOffsetAfter={16}
          loop
          grabCursor
          autoHeight
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          pagination={{
            el: ".swiper-pagination",
            type: "fraction",
          }}
          modules={[Autoplay, Navigation, Pagination]}>

          {ourAwardsData?.children.map((awardItem, idx) => {
            return <SwiperSlide key={idx} className="our-awards_item">
              <div className="our-awards_item-info">
                <div className="our-awards_item-info-icon"> <i className="svg svg--award"></i> </div>
                <div className="our-awards_item-info-subject">{awardItem.title}</div>
                <div className="our-awards_item-info-title">{awardItem.content}</div>
                <div className="our-awards_item-info-year">{awardItem.link}</div>
              </div>
              <div style={{ backgroundImage: `url(_assets/img/${awardItem.image})` }} className="col-12 col-md-9 our-awards_item-img "></div>
            </SwiperSlide>
          })}
          <div className="pagination-wrapper">
            <div className="swiper-button-next"></div>
            <div className="swiper-button-prev"></div>

            <div className="swiper-pagination"></div>
          </div>
        </Swiper>


      </div>
    </div>
  )
}


