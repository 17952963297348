
export enum LANGUAGE {
  TR = 'tr',
  EN = 'en'
}

export enum SECTION_NAMES {
  Main = 'anasayfa',
  Technology = 'teknoloji',
  OurServices = 'hizmetlerimiz',
  OurInnovations = 'yeniliklerimiz',
  OurProjectSolution = 'cozumlerimiz',
  SuccesTogether = 'birlikte-basarmak',
  OpenPositions = 'acik-pozisyonlar',
  OurAwards = 'odullerimiz',
  OurCampuses = 'kampuslerimiz',
  Footer = 'bilgi'
}

export interface IBreadCrumb {
  href: string,
  title: string
}

export interface IAppData {
  [LANGUAGE.TR]: IContent,
  [LANGUAGE.EN]: IContent
}


export interface IContent {
  menus: Menu[]
  sliders: Slider[]
  sections: Section[],
  announcements: Announcements,
  commonWords: ComplementaryWord[],
  subPages: SubPage[]
}

export interface Menu {
  key: string
  label: string
  url?: string
  children?: ChildMenu[]
}

export interface ChildMenu {
  key: string
  label: string
  url: string
}

export interface Slider {
  titles: string[]
  video?: string
  link?: string
  image?: string
  content?: string
}

export interface Section {
  name: string
  title: string
  content: string
  children: Children[]
}

export interface Children {
  name: string,
  title: string
  content?: string
  image?: string
  link?: string
  linkAlt?: string
}

export interface Announcements {
  name: string
  title: string
  data: Announcement[]
}

export interface Announcement {
  name: string,
  title: string
  date: string,
  content?: string
  image?: string
}
export interface SubPage {
  name: string,
  title: string
  date: string,
  content?: string,
  link?: string,
  image?: string
}

export interface ComplementaryWord {
  key: string,
  label: string
}



export const data: IAppData = {
  tr: {
    menus: [
      {
        key: "teknoloji",
        label: "Hakkımızda"
      },
      {
        key: "hizmetlerimiz",
        label: "Hizmetlerimiz"
      },
      {
        key: "yeniliklerimiz",
        label: "Teknoloji ve Yeniliklerimiz"
      },
      {
        key: "cozumlerimiz",
        label: "Güncel Proje ve Çözümlerimiz"
      },
      {
        key: "birlikte-basarmak",
        label: "Kariyer",
        children: [{
          key: "ziraat-teknolojili-olmak",
          label: "Ziraat Teknoloji’li Olmak",
          url: "/ziraat-teknolojili-olmak"
        },
        {
          key: "ziraat-teknolojide-hayat",
          label: "Ziraat Teknoloji'de Hayat",
          url: "/ziraat-teknolojide-hayat"
        }]
      },
      {
        key: "iletisim",
        label: "İletişim",
        url: "iletisim"

      }
    ],
    sliders: [{
      titles: ["Daha Fazlası İçin", "Teknoloji"],
      video: "zt-video-1",
      link: ""
    },
    {
      titles: ["Daha Fazlası İçin", "Teknoloji"],
      video: "zt-video-2",
      link: ""
    },
    {
      titles: ["Ankara ve İstanbul’da", "geleceğe yön verecek", "alanında deneyimli", "ÇALIŞMA ARKADAŞLARI ARIYORUZ"],
      image: "banner.jpg",
      link: "https://ziraatteknoloji.hrpeak.com/jobs"
    }
    ],
    sections: [
      {
        name: SECTION_NAMES.Technology,
        title: "Daha Fazlası İçin Teknoloji",
        content: "Türkiye'nin en büyük finansal teknoloji şirketlerinden biri olarak, kurulduğumuz 2001 yılından bu yana tüm paydaşlarımız ile birlikte “Daha Fazlası için Teknoloji” diyor, müşterilerimize yenilikçi ürün, çözüm ve hizmetler sunmak için tutkuyla çalışıyoruz.\n Ziraat Teknoloji olarak tüm çalışmalarımızın merkezine insanı alıyor, yetkin ve mutlu insan kaynağımız ile birlikte değer yaratıyoruz.",
        children: [{
          name: "mission-tab",
          title: "Misyonumuz",
          content: "Müşterilerinin teknoloji ihtiyaçları için uluslararası standartlar ve çağdaş normlarda hizmet sunarak rekabet avantajı sağlayan, ARGE faaliyetleri ile sürekli gelişen bir şirket olmaktır.",
        },
        {
          name: "vision-tab",
          title: "Vizyonumuz",
          content: "Finansal teknolojiler alanında müşterilerimize değer katan yenilikçi ürün, çözüm ve hizmetlerimizle öncü olmaktır.",
        },
        {
          name: "innovation-tab",
          title: "İnovasyon",
          content: "Tüm paydaşlarımızla birlikte finansal teknolojiler alanındaki trendleri takip etmekte, müşterilerimize yenilikçi ürün ve servisler sunmaktayız. Yapay Zeka, Blok Zincir Tabanlı Uygulamalar, Bulut Bankacılığı ve Servis Modeli Bankacılığı ile ilgili yürüttüğümüz projelerimiz; inovasyonun şirketimiz için önemini temsil etmektedir.",
        },
        {
          name: "our-customers-tab",
          title: "Müşterilerimiz",
          content: "Yıldız Teknopark, İstanbul Teknopark, İstanbul Finans Merkezi ve Ankara Varlık lokasyonlarında faaliyet gösteren şirketimiz; Ziraat Bankası ve Ziraat Katılım Bankası ile 18 farklı ülkedeki şube ve iştiraklerden oluşan Ziraat Finans Grubuna BT kaynak ve süreçlerinin kurumsal hedef ve stratejilerle hizalanarak yönetilmesine imkân tanıyacak hizmetler sunmaktadır.",
          image: "our-customers.png"
        },
        {
          name: "shareholding-tab",
          title: "Ortaklık Yapısı",
          content: "Ziraat Teknoloji A.Ş.’nin sermayesinin %100’ü Ziraat Bankası A.Ş.’ye aittir",
        }
        ]
      },
      {
        name: SECTION_NAMES.OurServices,
        title: "Hizmetlerimiz",
        content: "",
        children: [{
          name: "yazilim-gelistirme",
          title: "Yazılım Geliştirme",
          content: "Ziraat Teknoloji, Ziraat Finans Grubunun kritik iş ve teknoloji ihtiyaçlarını karşılayacak şekilde tasarlanmış yenilikçi uygulamalar geliştirmekte; bu uygulamaların etkin kullanımı için gereken entegrasyon, bakım ve destek hizmetlerini sunmaktadır.",
          image: "yazilim-gelistirme.png"
        },
        {
          name: "uygulama-altyapi",
          title: "Uygulama ve BT Altyapı",
          content: "Ziraat Teknoloji, BT kaynaklarının verimli ve düşük maliyetli bir şekilde kullanılmasına olanak sağlayan planlama, uygulama, operasyon ve yönetim hizmetleri sunmaktadır. Uygulama Altyapı (Uygulama Mimarisi, Devops, Servis Mimarisi, İzleme) ve BT Altyapı (Ağ Yönetimi, Veri Merkezi, Veritabanı, Merkezi, Çevresel Sistemler) sayesinde sunulan ürün ve hizmetlerin modernizasyonu ve sürekliliği garanti edilmektedir.",
          image: "uygulama-bt.png"
        },
        {
          name: "bilgi-guvenligi",
          title: "Bilgi Güvenliği",
          content: "7*24 çalışan Siber Güvenlik Merkezi sayesinde Ziraat Finans Grubu, bilgi güvenliği olaylarına karşı anlık korunmaktadır. Ayrıca zafiyet ve risk yönetim yaklaşımı ile olası vakalar önlenmekte ve Ziraat Finans Grubuna bilgi güvenliği konusunda danışmanlık hizmeti sunulmaktadır.",
          image: "bilgi-guvenligi.png"
        },
        {
          name: "bt-danismanligi",
          title: "BT Danışmanlığı",
          content: "Ziraat Teknoloji, BT kaynak ve süreçlerinin kurumsal hedef ve stratejilerle hizalı yönetilmesine olanak tanıyan hizmetler sunmaktadır. Ayrıca müşterileriyle birlikte mevcut kaynak ve süreçleri değerlendirmekte, ihtiyaçları tanımlamakta, şirketin rekabet gücünü artıracak fırsat ve çözümlerin kısa ve uzun vadeli planlamaları ile yatırımın geri dönüşü analiz edilmektedir.",
          image: "bt-danismanligi.png"
        }
        ]
      },
      {
        name: SECTION_NAMES.OurInnovations,
        title: "Teknoloji ve Yeniliklerimiz",
        content: "",
        children: [
          {
            name: "cevik-yontemler",
            title: "Çevik Yöntemler",
            content: "Ziraat Teknolojide çeviklik prensip ve değerlerini temel alan modern süreçler uygulanmaktadır. Şirket çapında Döngüsel ve Akış Bazlı çevik yaklaşımlar takip edilmektedir. Döngüsel Yöntem; sabit ve tekrarlı döngülerle değerli çıktılar üretilebilecek iş tiplerinde, her döngü sonunda elde edilebilecek anlamlı ve birleştirilebilir çıktılar olduğunda, ürün sahibi-geliştirme takımı vb. rollerin tanımlı olduğu, 3-9 kişilik takımlarda uygulanmaktadır. Akış Bazlı yöntem ise ekiplerin günlük ve operasyonel işlerinde, aynı anda farklı birimlere hizmet verilmesi ihtiyacında, bakım işleri veya üretim hatalarında, takım ve rollerin belirlenemediği durumlarda, geliştirmenin sürekli devam ettiği durumlar için tasarlanmıştır.",
            image: "tech-b4.png"
          },
          {
            name: "devops",
            title: "DevOps",
            content: "Ziraat Teknoloji uygulama geliştirme ve yaygınlaştırma süreçleri en yeni teknolojileri kullanarak hızlı ve kesintisiz olarak işletilmektedir. Golang, .NET core, Java, Python, Javasript vb. popüler programlama dilleri ile ihtiyaca yönelik olarak geliştirilen mikroservis mimarisi tabanlı uygulamaların altyapısal desteği DevOps süreçlerine uygun olarak sağlanmaktadır. Git tabanlı kod deposundan kaynak kodu çekilerek derlenen uygulamalar Continuous Integration/Continuous Delivery metodolojisine uygun şekilde oluşturulan pipelinelar üzerinde derlenerek otomatize testleri çalıştırıldıktan sonra container teknolojisinde versiyonlanıp Harbor imaj deposuna yüklenmekte ve Kubernetes tabanlı Openshift Container Platform'u veya Docker Swarm gibi çeşitli container orkestrasyon araçları üzerinde host edilmektedir. Bunların yanı sıra Nexus, Minio, Consul, Redis, Kafka, Cassandra, Storm, ELK, Prometheus, Grafana, InfluxDB gibi açık kaynaklı ürünlerin kurulum ve bakım hizmeti sunulmaktadır.",
            image: "tech-b5.png"
          },
          {
            name: "onyuz-mimari",
            title: "Modern Front-End Mimarisi",
            content: "Ana bankacılık uygulamamız olan Anahtar uygulaması önyüz mimarisinde modern web teknolojileri React, Html5, Css, Javascript, Typescript kullanılarak geliştirilmiştir. “Responsive” olarak geliştirilen önyüzler işletim sistemi bağımsız olarak “browser” üzerinden çalışabilmektedir. Micro Frontend mimari yaklaşımı ile Anahtar uygulaması içerisinde açılan ekranların ve tüm ara yüz bileşenlerinin birbirinden bağımsız olarak geliştirilmesi, test edilmesi ve yaygınlaştırılması sağlanmaktadır.",
            image: "tech-b1.png"
          },
          {
            name: "backend-framework",
            title: "Modern Back-End Mimarisi",
            content: "Ana bankacılık platformumuzda en güncel .Net ve .Net Core temelli güncel framework’ler üzerinde C# programlama dili ile Restful prensiplere dayalı servis tabanlı mimari kullanılmaktadır.",
            image: "tech-b2.png"
          },
          {
            name: "mobil-uygulama-altyapisi",
            title: "Mobil Uygulama Mimarileri",
            content: "Ziraat Finans Grubumuz içinde en güncel teknolojiler(Swift, Kotlin, React Native ve Flutter) kullanılarak ihtiyaçlara göre native ve cross-platform mobil uygulamalar geliştirilmektedir. Bu uygulamalardaki birçok ortak ihtiyaçlar için hazır altyapılar kullanılmakta ve mobil DevOps süreçleri işletilmektedir.",
            image: "tech-b3.png"
          }
        ]
      },
      {
        name: SECTION_NAMES.OurProjectSolution,
        title: "Güncel Proje ve Çözümlerimiz",
        content: "",
        children: [{
          name: "anahtar",
          title: "Anahtar ve Anahtar Global",
          content: "Ana Bankacılık uygulamamız olan Anahtar, 6000’e yaklaşan ekran adediyle Ziraat Bankası Genel Müdürlük Birimlerinde ve tüm şubelerinde tüm çalışanlarımızın ve müşterilerimizin finansal ve finansal olmayan tüm işlemleri için kullanılmaktadır. Yurtdışı İştiraklerimizin temel bankacılık ihtiyaçları için de yine kendi geliştirdiğimiz Anahtar Global uygulamamız kullanılmaktadır.",
          image: "solution1.jpg"
        },
        {
          name: "entropi",
          title: "Entropi",
          content: "Entropi Uygulaması, kendi öz kaynaklarımızla geliştirdiğimiz IDC Ödüllü Kurumsal Mimari Yönetim aracıdır. Ziraat Teknoloji ve Ziraat Bankası ortamlarında üretilen çıktıların, mimari modellerin ve envanterlerin tek bir ortamda yönetilmesi ve farklı katmanlarda ilişkilendirilmesini sağlar. ",
          image: "solution2.jpg"
        },
        {
          name: "dama",
          title: "Dama +",
          content: "Kurum içinde geliştirdiğimiz, Veri Mimarisi Yönetimi uygulamasıdır ve 2021 yılında Ziraat Teknoloji bünyesinde kullanılmaya başlanmıştır. Geliştirme süreçlerinin en başında, veri modelinin mimari standartlara uygun ve onay süreçlerinden geçerek tasarlanmasına imkan verir.",
          image: "solution3.jpg"
        },
        {
          name: "paloma",
          title: "Paloma",
          content: "Ziraat Finans Grubu bünyesindeki tüm kurumlarımızın tüm SMS ve E-posta gönderimleri için kendi geliştirdiğimiz uygulamadır. Otp SMS, Bulk SMS ve E-Posta gönderimlerinde tüm kurumlarımızın tüm yüksek kapasitedeki gönderim ihtiyaçları 7x24 sorunsuz sağlanmaktadır.",
          image: "solution4.jpg"
        },
        {
          name: "ug-araclari",
          title: "Uygulama Geliştirme Araçlarımız",
          content: "Kendi çözümümüz olan uygulama geliştirme araçlarımızla proje üretim süreçlerimizi hızlandırıyoruz.\listPergel - Talep Yönetim Uygulaması \nRadix - Code Review Yönetim Uygulaması\nFinix - Yaygınlaştırma Yönetim Uygulaması\nFlowart - İş Akış Uygulaması\n DYS - Doküman Yönetim Sistemi Uygulaması\n",
          image: "solution5.jpg"
        },
        {
          name: "super-sube",
          title: "Süper Şube",
          content: "Dijital kanalları yoğun olarak kullanan ve bankacılık işlemlerini geleneksel şubelere gitmeden dijital kanallardan gerçekleştirmek isteyen müşterilere sunulan yeni nesil bir hizmettir. Para transferleri, ödemeler, mevduat ve kredi işlemleri Süper Şube üzerinden avantajlı ve ayrıcalıklı bir şekilde gerçekleştirilebilmektedir.",
          image: "solution6.jpg"
        },
        {
          name: "sanal-asistan",
          title: "Sanal Asistan",
          content: "Bankacılık işlemlerini rahat ve pratik bir şekilde gerçekleştirme imkanı sunan Sanal Asistan, kolay ve anlaşılır cevaplarının yanı sıra ihtiyaca göre müşterileri dijital kanallarda ilgili menülere yönlendirerek işlemlerin hızlıca yapılmasını sağlamaktadır.",
          image: "solution7.jpg"
        },
        {
          name: "acik-bankacilik",
          title: "Açık Bankacılık",
          content: "Ziraat müşterileri, Ziraat Mobil ve İnternet Şubesi üzerinden diğer banka ve kurumlardaki hesaplarını görüntüleyip, bu hesaplar üzerinden para transferi gerçekleştirebilecek, daha avantajlı, teklif ve kampanyalardan faydalanıp bankacılık hizmetlerinden daha hızlı, verimli bir şekilde yararlanabilecektir.",
          image: "solution8.jpg"
        },
        {
          name: "ticaret-yolu",
          title: "Ticaret Yolu",
          content: "Ticaret Yolu’nda firma ve ürünler tanıtılabilir, ürün satışı yapılabilir, yeni iş ortakları edinerek işler genişletilebilir.",
          image: "solution9.jpg"
        }
        ]
      },
      {
        name: SECTION_NAMES.SuccesTogether,
        title: "Birlikte Başarmanın\tYürümenin\tÜretmenin\tGelişmenin Gücüne İnanıyoruz",
        content: "Çalışanlarımızı Şirketimizin vizyonunu gerçekleştirecek itici güç olarak görüyoruz.\n İnsanların eşit olduğuna inanıyor ve iş yaşantımızda bu ilkeyi uyguluyoruz.\n Yöneticilerimizle birlikte, çalışanlarımızın gelişimini destekleyecek mutlu ve huzurlu bir iş ortamı yaratmak için çalışıyoruz.\n Kalite odaklı süreç ve sistemlerle çalışanlarımızın bireysel performansını ve takım performansını geliştiriyoruz.\n “Her insanın içinde keşfedilmeyi bekleyen bir potansiyel vardır.” ilkesiyle tüm çalışanlarımızın kendini gerçekleştirmesine olanak tanıyoruz.",
        children: [{
          name: "ziraat-teknolojili-olmak",
          title: "Ziraat\n Teknolojili \nOlmak",
          image: "success-together-bg1.png",
          link: "ziraat-teknolojili-olmak"
        }, {
          name: "kariyerine-yon-ver",
          title: "Kariyerine\n Yön Ver",
          image: "success-together-bg2.png",
          link: "ziraat-teknolojide-hayat"
        },
        {
          name: "potansiyelini-kesfet",
          title: "Potansiyelini\n Keşfet",
          image: "success-together-bg3.png",
          link: "ziraat-teknolojide-hayat#potansiyelini-kesfet"
        },
        {
          name: "mulakatlar",
          title: "Mülakatlar",
          image: "success-together-bg5.png",
          link: "mulakat-sonuc-sorgulama"
        }
        ]
      },
      {
        name: SECTION_NAMES.OurAwards,
        title: "Ödüllerimiz",
        content: "",
        children: [{
          name: "tech-brands-turkey",
          title: "Tech Brands Turkey",
          content: "En Teknolojik Banka",
          link: "2023",
          image: "Stevie_Awards_2.png"
        }, {
          name: "tech-brands-turkey",
          title: "Tech Brands Turkey",
          content: "En Teknolojik Mobil Banka",
          link: "2023",
          image: "Tech_Brands_Turkey.png"
        }, {
          name: "tech-brands-turkey",
          title: "ECHO Awards",
          content: "Online Bankacılık",
          link: "2023",
          image: "ECHO.png"
        }, {
          name: "tech-brands-turkey",
          title: "Global Banking & Finance Review",
          content: "Dijital Analitik CRM",
          link: "2023",
          image: "Global_Banking_Finance.png"
        }, {
          name: "tech-brands-turkey",
          title: "PSM Awards",
          content: "Dijital Güvenlik",
          link: "2022",
          image: "PSM.png"
        }, {
          name: "tech-brands-turkey",
          title: "Stevie Awards",
          content: "Veri Kalitesi Yönetiminde En iyi Banka",
          link: "2022",
          image: "Stevie_Awards.png"
        }, {
          name: "tech-brands-turkey",
          title: "Globee Business Awards",
          content: "Yılın En İyi Bilgi Teknolojileri Projeleri ve Girişimleri",
          link: "2022",
          image: "Globee_Awards.png"
        }, {
          name: "tech-brands-turkey",
          title: "PSM Awards",
          content: "Uzaktan Müşteri Edinimi Projesi",
          link: "2021",
          image: "PSM.png"
        }, {
          name: "tech-brands-turkey",
          title: "PSM Awards",
          content: "Dijital Dönüşüm",
          link: "2021",
          image: "PSM.png"
        }, {
          name: "tech-brands-turkey",
          title: "Altın Örümcek",
          content: "Ziraat Mobil Projesi",
          link: "2021",
          image: "Altin_Orumcek.png"
        }, {
          name: "tech-brands-turkey",
          title: "Altın Örümcek",
          content: "Mobil Uygulama-Bankacılık & Finans",
          link: "2021",
          image: "Altin_Orumcek.png"
        }, {
          name: "tech-brands-turkey",
          title: "Global Banking & Finance Review",
          content: "2021 Yılı En İyi Bankacılık Teknolojisi Uygulaması",
          link: "2021",
          image: "Global_Banking.png"
        }
        ]
      },
      {
        name: SECTION_NAMES.OurCampuses,
        title: "Kampüslerimiz",
        content: "",
        children: [{
          name: "davutpasa",
          title: "İstanbul - Avrupa / Yıldız Teknopark",
          content: "Yıldız Teknik Üniversitesi, Davutpaşa Kampüsü, Teknopark C2 Blok Esenler / İstanbul",
          link: "https://maps.app.goo.gl/8nfe498GGxuVX28E9",
          image: "davutpasa.png"
        },
        {
          name: "finans-merkezi",
          title: "İstanbul – Anadolu / İstanbul Finans Merkezi",
          content: "Ziraat Bankası Genel Müdürlüğü A Blok 5. Kat Finanskent Mah. Finans Cad. No:44/A Ümraniye / İstanbul",
          link: "https://maps.app.goo.gl/BxD7KaBdaz9tNa2X9",
          image: "finans-merkezi.png"
        },
        {
          name: "ankara",
          title: "Ankara",
          content: "Ziraat Bankası Eğitim Tesisleri, Varlık Mah. Fatih Sultan Mehmet Bulvarı No:10 Kat:3 Yenimahalle / Ankara",
          link: "https://maps.app.goo.gl/972LhRXMQ78XjKpB6",
          image: "ankara.png"
        }
        ]
      },
      {
        name: SECTION_NAMES.OpenPositions,
        title: "Açık Pozisyonlar",
        content: "",
        children: [
          {
            name: "tum-acik-pozisyonlar",
            title: "Tüm Açık Pozisyonlar",
            content: "İstanbul / Ankara",
            link: "https://ziraatteknoloji.hrpeak.com/jobs",
            linkAlt: "İncele",
            image: "svg svg--ziraat"
          },
          {
            name: "is-analisti",
            title: "İş Analisti",
            content: "İstanbul / Ankara",
            link: "https://ziraatteknoloji.hrpeak.com/6tcv9h2rzp73wvcpslvlsna28x.job",
            linkAlt: "İncele",
            image: "svg svg--work-analyze"
          },
          {
            name: "yazilim-muhendisi",
            title: "Yazılım Mühendisi",
            content: "İstanbul / Ankara",
            link: "https://ziraatteknoloji.hrpeak.com/2a6jufy4sd4rylmheqanqshxp2.job",
            linkAlt: "İncele",
            image: "svg svg--microchip"
          }
        ]
      },
    ],
    announcements: {
      name: "duyuru",
      title: "Duyurular",
      data: [{
        name: "/duyuru/kisisel-verilerin-korunmasi-kanunu-hakkinda-bilgilendirme",
        title: "Kişisel Verilerin Korunması Kanunu Hakkında Bilgilendirme",
        content: `<h2>Kişisel Verilerin Korunması Aydınlatma Metni</h2>
        <p>
          Kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülüklerini belirlemek amacıyla 7 Nisan 2016 tarihli ve 29677 Sayılı Resmî Gazete’de yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) hususunda Ziraat Teknoloji A.Ş. (“Ziraat Teknoloji”) olarak veri sorumlusu sıfatıyla Kanun’un “Veri Sorumlusunun Aydınlatma Yükümlülüğü” başlıklı 10. maddesi uyarınca sizleri bilgilendirmek isteriz.
        </p>
        <p>
          Ziraat Teknoloji, veri sahiplerinden alınan kişisel bilgilerin gizliliğini korumak için sistem altyapısı ve internet sunucularını en güvenilir seviyede tutmaktadır.
        </p>


        <h3>Kişisel Verilerin İşlenme Amacı, Toplama Yöntemi ve Hukuki Sebebi</h3>
        <p>Ziraat Teknoloji, sizlere ait kimlik, iletişim, özgeçmiş, hukuki işlem, müşteri işlem, lokasyon, kamera görüntüleri, risk yönetimi bilgisi, finans, mesleki deneyim, görsel ve işitsel kayıtlar, demografik bilgiler, eğitim seviyesi ve gelir seviyesi bilgilerinizi; Şirketimizle hukuki ilişkinizin kurulması esnasında ve söz konusu ilişkinin devamı süresince sizlerden, üçüncü kişilerden ve yasal mercilerden olmak kaydıyla internet sitesi, muhtelif sözleşmeler, elektronik posta, başvuru formları araçları üzerinden, Şirketimiz ile yapılan yazılı veya sözlü iletişim kanalları aracılığıyla sözlü, yazılı veya elektronik ortamda otomatik olarak ve; KVKK’nın 5/2(a) maddesinde belirtilen kanunlarda açıkça öngörülmesi, KVKK’nın 5/2(c) maddesinde belirtilen sözleşmenin kurulması veya ifası için gerekli olması, KVKK’nın 5/2(ç) maddesinde belirtilen şirketimizin hukuki yükümlülüğünü yerine getirmesi, KVKK’nın 5/2(d) maddesinde belirtilen ilgili kişinin kendisi tarafından alenileştirilmiş olması, KVKK’nın 5/2(e) maddesinde belirtilen bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması ve 5/2(f) maddesi uyarınca İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebeplerine dayanarak;</p>

        <ul className="list">
          <li>Sözleşme yükümlülüklerinin gereği gibi ifa edilmesinin sağlanması;</li>
          <li>Tabi olduğumuz mevzuatlar uyarınca müşterimizi tanımamız konusundaki yükümlülüklerimizi yerine getirebilmek için bilgilerinizin kaydedilmesi;</li>
          <li>Şikayet, itiraz, talep, öneri, memnuniyet gibi bildirimlerin sizlere daha iyi hizmet verebilmek için bildirim yönetim sistemimizde kayıt altında tutulması;</li>
          <li>İş faaliyetlerinin ve operasyonel süreçlerin planlanması ve icrası;</li>
          <li>Müşteri ilişkilerinin yürütülmesi ve icrası;</li>
          <li>Sizlere ait verilerin doğru ve güncel tutulmasının sağlanması;</li>
          <li>Destek hizmetleri ile yükümlülüklerinin, müşteri memnuniyetinin, kurumsal iletişim faaliyetlerinin,</li>
          <li>Sosyal Medya üzerinden yapılan talep ve şikâyetlerinizin değerlendirilmesi ve çözüm önerilmesi;</li>
          <li>Ziraat Bankası A.Ş. ve yurtiçi, yurtdışı şube ve iştirakleriyle yürüttüğü işlerin icrası ve ilişkilerin yönetimi;</li>
          <li>Şirketimizin taraf olduğu dava ve icra takiplerinin yürütülmesi;</li>
          <li>Kurumsal sürdürülebilirlik, kurumsal yönetim, stratejik planlama ve bilgi güvenliği süreçlerinin planlanması ve icrası;</li>
          <li>Güvenlik sebebiyle ve kanundan kaynaklanan yükümlülüklerimiz kapsamında kamera görüntülerinin kaydedilmesi;</li>
        </ul>

        <p>Ziraat Teknoloji, yürüttüğü faaliyetlerin bazılarında ise kişisel verilerinizi işlerken sizlerin açık rızanıza ihtiyaç duyabilmektedir. KVKK’nın 6/2. maddesi uyarınca açık rızanızın olması hukuki sebebine dayanarak parmak izi ve engel durumu bilginiz fiziksel güvenlik ve size uygun destek hizmeti sunulması amaçlarıyla ve KVKK’nın 5/1. maddesi uyarınca açık rızanızın olması hukuki sebebine davranış analizlerinize yönelik kişisel verileriniz işe alım süreçleri, tanıtım ve reklam faaliyetlerinin planlanması ve icrası amaçlarıyla işlenmektedir.</p>


        <h3>Kişisel Verilerinizin Aktarılması</h3>
        <p>Kişisel verileriniz; Kanun’un kişisel verilerin aktarılması ve yurtdışına aktarılmasına ilişkin hükümleri kapsamında işbu Aydınlatma Metninin 1. maddesinde yer alan amaçlar ve hukuki sebeplerle; BDDK, SPK, MASAK, TBB, Maliye Bakanlığı gibi kamu kurum ve kuruluşlarına, sigorta şirketlerine, T.C. Ziraat Bankası A.Ş. ve yurtiçi ve yurtdışı iştirakleri başta olmak üzere üçüncü kişilerle var olan gizlilik sözleşmeleri ve benzeri diğer sözleşmeler dahilinde faaliyetlerimizi yürütmek üzere hizmet aldığımız üçüncü taraflara, işbirliği yapılan kuruluşlara ve program ortaklarına aktarılabilmektedir.</p>

        <h3>Kişisel Verileriniz Saklanması</h3>
        <p>Kişisel verileriniz, işlenme amaçlarının gerektirdiği süreler boyunca saklanabilmektedir. Başka bir gerekçe veya hukuki sebep olmaması, uluslararası yasa veya düzenlemenin bulunmaması ve sözleşmelerden kaynaklı zorunlulukların ortadan kalkması halinde işlenme amaçları ortadan kalkan kişisel verileriniz silinmekte, yok edilmekte veya anonim hale getirilmektedir.</p>

        <h3>Kişisel Verisi İşlenen İlgili Kişinin Hakları</h3>
        <p>Kişisel verilerinizin işlenmesine ilişkin olarak aşağıdaki hakları, Ziraat Teknoloji’ye yapacağınız bir talep ile kullanabilirsiniz. Bu kapsamda iletilen talepler Ziraat Teknoloji tarafından en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, Kişisel Verileri Koruma Kurulunca bir ücret öngörülmesi halinde, Şirketimiz tarafından belirlenen tarifedeki ücret alınabilecektir. Bu kapsamda kişisel veri sahibi olarak;</p>


        <ul className="list">
          <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
          <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
          <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
          <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
          <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
          <li>Kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
          <li>Kişisel verilerin düzeltilmesi, silinmesi ya da yok edilmesi halinde bu işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
          <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
          <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme,</li>
        </ul>

        <p>haklarınız bulunmaktadır. 6698 Sayılı Kanun kapsamında kişisel verilerinize ilişkin haklarınızı kimliğinizi teşvik edici belgeler ve talebinizi içeren dilekçeniz ile “Yıldız Teknik Üniversitesi Davutpaşa Kampüsü Teknoloji Geliştirme Bölgesi C2 Blok 34220 Esenler/İSTANBUL” adresine bizzat elden iletebilir, noter kanalıyla ulaştırabilir veya ziraatteknoloji@hs01.kep.tr adresine, güvenli elektronik imza, mobil imza ya da Şirketimize daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adresinizi kullanmak suretiyle iletebilirsiniz.</p>
        <p>Bu kapsamda konuyla ilgili yazılı başvurularınız tarafımızca yürütülecek kimlik doğrulaması sürecini takiben kabul edilecek olup, kurumumuzca yasada belirtilen süreler içerisinde geri dönüş sağlanacaktır.</p>`,
        date: "05 Ocak 2024",
      }
      ]
    },
    subPages: [
      {
        name: "standartlarimiz",
        title: "Standartlarımız",
        content: `  <div><img src="_assets/img/ISO9001.jpg" alt=""  /><h2>ISO 9001 Kalite Yönetim Sistemi</h2>      </div> 
        <div> <img src="_assets/img/ISO22301.jpg" alt=""  /><h2>ISO 22301 İş Sürekliliği Yönetim Sistemi </h2></div>
        <div><img src="_assets/img/ISO27001.png" alt=""  /><h2>ISO 27001 Bilgi Güvenliği Yönetim Sistemi </h2></div>`,
        date: "05 Eylül 2023",
      },
      {
        name: "bilgi-toplumu-hizmetleri",
        title: "Bilgi Toplumu Hizmetleri",
        link: "https://e-sirket.mkk.com.tr/?page=company&company=11626",
        date: "05 Eylül 2023",
      },
      {
        name: "bilgi-guvenligi-politikasi",
        title: "Bilgi Güvenliği Politikası",
        content: `<p>
        Ziraat Teknoloji A.Ş. kendisine ve paydaşlarına ait her tür bilginin, faaliyetlerini gerçekleştirdiği yerleşkelerin ve kurumsal bilgi işleme süreçlerinin güvenliğini, gizlilik, bütünlük ve kullanılabilirliğini sağlamayı hedefler.
        <br />Bu hedefe ulaşmak amacıyla, bilgi güvenliği ile ilgili uygulanabilir şartların karşılandığı TS EN ISO/IEC 27001 standardına uygun bir bilgi güvenliği yönetim sistemi işletir.</p>
<p>
Bu kapsamda aşağıdaki gereklilikleri sağlamayı taahhüt eder.
</p>

        <ul className="list">
        <li>TS EN ISO/IEC 27001 standardının gereği olan Bilgi Güvenliği Yönetim Sistemi'ni kurulması, dokümante edilmesi ve sürekli iyileştirilmesi,</li>
        <li>Sorumluluğundan olan bilgileri ve ilgili bileşenleri değerli ve kritik kabul ederek bilgi güvenliğiyle ilgili yasaların ve standartların gerektirdiği zorunlulukların yerine getirilmesi,</li>
        <li>Kurumsal faaliyetlerin gerçekleşmesinde kullanılan bilgi teknolojileri hizmetlerinin kesintisiz olarak sürdürülmesi ve bilgilere sadece yetkili kişiler tarafından erişilebilmesini sağlayacak gerekli altyapıyı sağlamayı ve güvenlik önlemlerinin alınması,</li>
        <li>Bilgi güvenliği yönetim sistemi ile ilgili yasal mevzuat ve şartların takip edilmesi,</li>
        <li>Başta eğitimler olmak üzere, bilgi güvenliği farkındalığını artırmak amacıyla gerekli aksiyonların alınması,</li>
        <li>Üçüncü taraflar, müşteriler ve tedarikçilerin bilgi güvenliği yönetim sistemi ile ilgili gereksinimlerinin tanımlanması ve bilgi güvenliği yönetim sistemine  uygun davranmalarının sağlanması,</li>
        <li>Bilgi güvenliği yönetim sisteminin, düzenli aralıklarla denetlenme ve sürekli iyileştirmesinin sağlanması.</li>
          
          </ul>
       `,
        date: "14 Haziran 2024",
      },
      {
        name: "kvkk-aydinlatma-metni",
        title: "KVKK Aydınlatma Metni",
        content: `   <h2>Kişisel Verilerin Korunması Aydınlatma Metni</h2>
        <p>
          Kişisel verilerin işlenmesinde başta özel hayatın gizliliği olmak üzere kişilerin temel hak ve özgürlüklerini korumak ve kişisel verileri işleyen gerçek ve tüzel kişilerin yükümlülüklerini belirlemek amacıyla 7 Nisan 2016 tarihli ve 29677 Sayılı Resmî Gazete’de yayımlanan 6698 sayılı Kişisel Verilerin Korunması Kanunu (“KVKK”) hususunda Ziraat Teknoloji A.Ş. (“Ziraat Teknoloji”) olarak veri sorumlusu sıfatıyla Kanun’un “Veri Sorumlusunun Aydınlatma Yükümlülüğü” başlıklı 10. maddesi uyarınca sizleri bilgilendirmek isteriz.
        </p>
        <p>
          Ziraat Teknoloji, veri sahiplerinden alınan kişisel bilgilerin gizliliğini korumak için sistem altyapısı ve internet sunucularını en güvenilir seviyede tutmaktadır.
        </p>


        <h3>Kişisel Verilerin İşlenme Amacı, Toplama Yöntemi ve Hukuki Sebebi</h3>
        <p>Ziraat Teknoloji, sizlere ait kimlik, iletişim, özgeçmiş, hukuki işlem, müşteri işlem, lokasyon, kamera görüntüleri, risk yönetimi bilgisi, finans, mesleki deneyim, görsel ve işitsel kayıtlar, demografik bilgiler, eğitim seviyesi ve gelir seviyesi bilgilerinizi; Şirketimizle hukuki ilişkinizin kurulması esnasında ve söz konusu ilişkinin devamı süresince sizlerden, üçüncü kişilerden ve yasal mercilerden olmak kaydıyla internet sitesi, muhtelif sözleşmeler, elektronik posta, başvuru formları araçları üzerinden, Şirketimiz ile yapılan yazılı veya sözlü iletişim kanalları aracılığıyla sözlü, yazılı veya elektronik ortamda otomatik olarak ve; KVKK’nın 5/2(a) maddesinde belirtilen kanunlarda açıkça öngörülmesi, KVKK’nın 5/2(c) maddesinde belirtilen sözleşmenin kurulması veya ifası için gerekli olması, KVKK’nın 5/2(ç) maddesinde belirtilen şirketimizin hukuki yükümlülüğünü yerine getirmesi, KVKK’nın 5/2(d) maddesinde belirtilen ilgili kişinin kendisi tarafından alenileştirilmiş olması, KVKK’nın 5/2(e) maddesinde belirtilen bir hakkın tesisi, kullanılması veya korunması için veri işlemenin zorunlu olması ve 5/2(f) maddesi uyarınca İlgili kişinin temel hak ve özgürlüklerine zarar vermemek kaydıyla, veri sorumlusunun meşru menfaatleri için veri işlenmesinin zorunlu olması hukuki sebeplerine dayanarak;</p>

        <ul className="list">
          <li>Sözleşme yükümlülüklerinin gereği gibi ifa edilmesinin sağlanması;</li>
          <li>Tabi olduğumuz mevzuatlar uyarınca müşterimizi tanımamız konusundaki yükümlülüklerimizi yerine getirebilmek için bilgilerinizin kaydedilmesi;</li>
          <li>Şikayet, itiraz, talep, öneri, memnuniyet gibi bildirimlerin sizlere daha iyi hizmet verebilmek için bildirim yönetim sistemimizde kayıt altında tutulması;</li>
          <li>İş faaliyetlerinin ve operasyonel süreçlerin planlanması ve icrası;</li>
          <li>Müşteri ilişkilerinin yürütülmesi ve icrası;</li>
          <li>Sizlere ait verilerin doğru ve güncel tutulmasının sağlanması;</li>
          <li>Destek hizmetleri ile yükümlülüklerinin, müşteri memnuniyetinin, kurumsal iletişim faaliyetlerinin,</li>
          <li>Sosyal Medya üzerinden yapılan talep ve şikâyetlerinizin değerlendirilmesi ve çözüm önerilmesi;</li>
          <li>Ziraat Bankası A.Ş. ve yurtiçi, yurtdışı şube ve iştirakleriyle yürüttüğü işlerin icrası ve ilişkilerin yönetimi;</li>
          <li>Şirketimizin taraf olduğu dava ve icra takiplerinin yürütülmesi;</li>
          <li>Kurumsal sürdürülebilirlik, kurumsal yönetim, stratejik planlama ve bilgi güvenliği süreçlerinin planlanması ve icrası;</li>
          <li>Güvenlik sebebiyle ve kanundan kaynaklanan yükümlülüklerimiz kapsamında kamera görüntülerinin kaydedilmesi;</li>
        </ul>

        <p>Ziraat Teknoloji, yürüttüğü faaliyetlerin bazılarında ise kişisel verilerinizi işlerken sizlerin açık rızanıza ihtiyaç duyabilmektedir. KVKK’nın 6/2. maddesi uyarınca açık rızanızın olması hukuki sebebine dayanarak parmak izi ve engel durumu bilginiz fiziksel güvenlik ve size uygun destek hizmeti sunulması amaçlarıyla ve KVKK’nın 5/1. maddesi uyarınca açık rızanızın olması hukuki sebebine davranış analizlerinize yönelik kişisel verileriniz işe alım süreçleri, tanıtım ve reklam faaliyetlerinin planlanması ve icrası amaçlarıyla işlenmektedir.</p>


        <h3>Kişisel Verilerinizin Aktarılması</h3>
        <p>Kişisel verileriniz; Kanun’un kişisel verilerin aktarılması ve yurtdışına aktarılmasına ilişkin hükümleri kapsamında işbu Aydınlatma Metninin 1. maddesinde yer alan amaçlar ve hukuki sebeplerle; BDDK, SPK, MASAK, TBB, Maliye Bakanlığı gibi kamu kurum ve kuruluşlarına, sigorta şirketlerine, T.C. Ziraat Bankası A.Ş. ve yurtiçi ve yurtdışı iştirakleri başta olmak üzere üçüncü kişilerle var olan gizlilik sözleşmeleri ve benzeri diğer sözleşmeler dahilinde faaliyetlerimizi yürütmek üzere hizmet aldığımız üçüncü taraflara, işbirliği yapılan kuruluşlara ve program ortaklarına aktarılabilmektedir.</p>

        <h3>Kişisel Verileriniz Saklanması</h3>
        <p>Kişisel verileriniz, işlenme amaçlarının gerektirdiği süreler boyunca saklanabilmektedir. Başka bir gerekçe veya hukuki sebep olmaması, uluslararası yasa veya düzenlemenin bulunmaması ve sözleşmelerden kaynaklı zorunlulukların ortadan kalkması halinde işlenme amaçları ortadan kalkan kişisel verileriniz silinmekte, yok edilmekte veya anonim hale getirilmektedir.</p>

        <h3>Kişisel Verisi İşlenen İlgili Kişinin Hakları</h3>
        <p>Kişisel verilerinizin işlenmesine ilişkin olarak aşağıdaki hakları, Ziraat Teknoloji’ye yapacağınız bir talep ile kullanabilirsiniz. Bu kapsamda iletilen talepler Ziraat Teknoloji tarafından en geç otuz gün içinde ücretsiz olarak sonuçlandıracaktır. Ancak, Kişisel Verileri Koruma Kurulunca bir ücret öngörülmesi halinde, Şirketimiz tarafından belirlenen tarifedeki ücret alınabilecektir. Bu kapsamda kişisel veri sahibi olarak;</p>


        <ul className="list">
          <li>Kişisel veri işlenip işlenmediğini öğrenme,</li>
          <li>Kişisel verileri işlenmişse buna ilişkin bilgi talep etme,</li>
          <li>Kişisel verilerin işlenme amacını ve bunların amacına uygun kullanılıp kullanılmadığını öğrenme,</li>
          <li>Yurt içinde veya yurt dışında kişisel verilerin aktarıldığı üçüncü kişileri bilme,</li>
          <li>Kişisel verilerin eksik veya yanlış işlenmiş olması hâlinde bunların düzeltilmesini isteme,</li>
          <li>Kişisel verilerin silinmesini veya yok edilmesini isteme,</li>
          <li>Kişisel verilerin düzeltilmesi, silinmesi ya da yok edilmesi halinde bu işlemlerin kişisel verilerin aktarıldığı üçüncü kişilere bildirilmesini isteme,</li>
          <li>İşlenen verilerin münhasıran otomatik sistemler vasıtasıyla analiz edilmesi suretiyle kişinin kendisi aleyhine bir sonucun ortaya çıkmasına itiraz etme,</li>
          <li>Kişisel verilerin kanuna aykırı olarak işlenmesi sebebiyle zarara uğraması hâlinde zararın giderilmesini talep etme,</li>
        </ul>

        <p>haklarınız bulunmaktadır. 6698 Sayılı Kanun kapsamında kişisel verilerinize ilişkin haklarınızı kimliğinizi teşvik edici belgeler ve talebinizi içeren dilekçeniz ile “Yıldız Teknik Üniversitesi Davutpaşa Kampüsü Teknoloji Geliştirme Bölgesi C2 Blok 34220 Esenler/İSTANBUL” adresine bizzat elden iletebilir, noter kanalıyla ulaştırabilir veya ziraatteknoloji@hs01.kep.tr adresine, güvenli elektronik imza, mobil imza ya da Şirketimize daha önce bildirilen ve sistemimizde kayıtlı bulunan elektronik posta adresinizi kullanmak suretiyle iletebilirsiniz.</p>
        <p>Bu kapsamda konuyla ilgili yazılı başvurularınız tarafımızca yürütülecek kimlik doğrulaması sürecini takiben kabul edilecek olup, kurumumuzca yasada belirtilen süreler içerisinde geri dönüş sağlanacaktır.</p>`,
        date: "05 Eylül 2023",
      },
      {
        name: "cerez-politikasi",
        title: "Çerez Politikası",
        content: `   <h3>Genel</h3>
        <p>Kullanılmakta olan internet tarayıcısı aracılığı ile internet ağ sunucusu tarafından kullanıcıların cihazlarına gönderilen küçük veri dosyaları çerez olarak anılmakta olup, internet siteleri bu çerezler vasıtası ile kullanıcıları tanımaktadır. Çerezlerin ömrü tarayıcı ayarlarına bağlı olarak farklılaşmaktadır.</p>
        <p>Bu çerezler, Şirket tarafından yönetilmekte olan sistemler aracılığıyla oluşturulmakla birlikte, aynı zamanda Şirket tarafından yetkilendirilen bazı hizmet sağlayıcılar kullanıcıların cihazlarına benzeri teknolojiler yerleştirerek IP adresi, benzersiz tanımlayıcı ve cihaz tanımlayıcı bilgileri edinebilmektedir. Ayrıca, Şirket sistemlerinde bulunan üçüncü taraflara ait linkler, bu üçüncü taraflara ait gizlilik politikalarına tabi olmakla birlikte, gizlilik uygulamalarına ait sorumluluk Şirket’e ait olmamaktadır ve bu bağlamda ilgili link kapsamındaki site ziyaret edildiğinde siteye ait gizlilik politikasının okunması önerilmektedir.</p>

        <h3>Çerez Türleri</h3>
        <p>Ana kullanım amacı kullanıcılara kolaylık sağlamak olan çerezler, temel olarak 4 ana grupta toplanmaktadır:</p>

        <ul className="list">
          <li>Oturum Çerezleri: İnternet sayfaları arasında bilgi taşınması ve kullanıcı tarafından girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya olanak sağlayan çerezlerdir ve Şirket internet sitesine ait fonksiyonların düzgün bir şekilde işleyebilmesi için gereklidir.</li>
          <li>Performans Çerezleri: Sayfaların ziyaret edilme frekansı, olası hata iletileri, kullanıcıların ilgili sayfada harcadıkları toplam zaman ile birlikte siteyi kullanım desenleri konularında bilgi toplayan çerezlerdir ve Şirket internet sitesinin performansını arttırma amacıyla kullanılmaktadır.</li>
          <li>Fonksiyonel Çerezler: Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve Şirket internet sitesi kapsamında kullanıcılara gelişmiş internet özellikleri sağlanmasını hedeflemektedir.</li>
          <li>Reklam Ve Üçüncü Taraf Çerezleri: Üçüncü parti tedarikçilere ait çerezlerdir ve Şirket internet sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin yapılmasına olanak sağlamaktadır.</li>
        </ul>


        <h3>Çerezlerin Kullanım Amaçları</h3>
        <p>Şirketimiz tarafından kullanılmakta olan çerezlere ait kullanım amaçları aşağıdaki gibidir:</p>

        <ul className="list">
          <li>Operasyonel amaçlı kullanımlar: Şirketimiz, sistemlerinin idaresi ve güvenliğinin sağlanması amacıyla, bu sitedeki fonksiyonlardan yararlanmayı sağlayan veyahut düzensiz davranışları tespit eden çerezler kullanabilmektedir.</li>
          <li>İşlevselliğe yönelik kullanımlar: Şirketimiz, sistemlerinin kullanımını kolaylaştırmak ve kullanıcı özelinde kullanım özellikleri sağlamak amacıyla, kullanıcıların bilgilerini ve geçmiş seçimlerini hatırlatan çerezler kullanabilmektedir.</li>
          <li>Performansa yönelik kullanımlar: Şirketimiz, sistemlerinin performansının artırılması ve ölçülmesi amacıyla, gönderilen iletilerle olan etkileşimi ve kullanıcı davranışlarını değerlendiren ve analiz eden çerezler kullanabilmektedir.</li>
          <li>Reklam amaçlı kullanımlar: Şirketimiz, kendine veya üçüncü taraflara ait sistemlerin üzerinden kullanıcıların ilgi alanları kapsamında reklam ve benzeri içeriklerin iletilmesi amacıyla, bu reklamların etkinliğini ölçen veya tıklanma durumunu analiz eden çerezler kullanabilmektedir.</li>
        </ul>

        <h3>Çerezleri Devre Dışı Bırakmak</h3>
        <p>Çerez kullanımı birçok tarayıcıda önceden tanımlı bir şekilde seçili olarak bulunmaktadır ve kullanıcılar bu seçim durumunu tarayıcı ayarlarından değiştirebilmekte ve dolayısıyla mevcut çerezleri silip ileriki çerez kullanımlarını da reddedebilmektedir; nitekim çerez kullanımının iptal edilmesi halinde, Şirket sistemlerindeki bir takım özelliklerden faydalanılamaması söz konusu olabilmektedir.</p>
        <p>Çerez kullanım seçiminin değiştirilmesine ait yöntem, tarayıcı tipine bağlı olarak değişmekte olup, ilgili hizmet sağlayıcıdan dilendiği zaman öğrenilebilmektedir.</p>

        <h3>Web Sitesinde Bulunan Bilgi ve Materyal</h3>
        <p>Şirketimiz web sitesinde bulunan bilgi, materyal ve bunların düzenlenmesi konusundaki telif hakları yine Şirketimize aittir. Web sitemizde yer alan ve üçüncü şahıslara ait materyaller dışında kalan bilgi ve materyale dair tüm telif hakları, tescilli marka, patent, fikri ve sair mülkiyet hakları Şirketimizde saklıdır.</p>`,
        date: "05 Eylül 2023",
      },
      {
        name: "gizlilik-politikasi",
        title: "Gizlilik Politikası",
        content: `<p>Ziraat Teknoloji A.Ş ilgili yasal mevzuatlar çerçevesinde müşteri bilgilerinin gizliliğini ve güvenliğini en üst seviyede sağlamak konusunda gerekli tedbirleri almakta ve bu doğrultuda Şirketimiz web sitesinde ve internet şubesinde aşağıda belirtilen hususları uygulamaktadır.</p>

        <ul className="list">
          <li>Ziraat Teknoloji A.Ş. faaliyetlerini gerçekleştirebilmek ve hizmetlerinin kesintisiz ilerleyebilmesini sağlamak adına, Genel Müdürlük, çağrı merkezi gibi kanallardan; sözlü, yazılı ya da elektronik ortam üzerinden temin edebildiği kişisel verileri, Veri Sorumlusu sıfatıyla, hukuka uygun bir biçimde işlemektedir.</li>
          <li>Şirketimiz, kişisel verilerin gizliliğini ve güvenliğini korumaya önem verir. Bu doğrultuda, kişisel verileri yetkisiz erişim, zarar, kayıp veya ifşaya karşı korumak için gerekli teknik ve idari güvenlik önlemleri alınır. Şirket, web sitesi üzerinden diğer web sitelerine erişim için verilen bağlantıların güvenlik ve gizliliğinden sorumlu değildir. Bu sitelere giriş sonucu karşılaşılabilecek maddi veya manevi kayıplarda sorumluluk kabul edilmeyecektir.</li>
          <li>Şirket web sitesi üzerinden ürün ve hizmet satışı/başvurusu için talep edilen veya Şirketimiz sisteminde var olan müşterilere ait her türlü kişisel bilgi, müşteri onayı olmadan 3. Kişilerle hiçbir şekilde paylaşılmamaktadır. Yalnızca müşteri bilgilerine erişme yetkisi bulunan yasal kurumlar ve yetkililer ile talep edilmesi durumunda müşteri bilgileri paylaşılabilecektir.</li>
          <li>Müşterilerimizin web sitesi üzerinden girmiş oldukları bilgilere 3. kişilerin erişimi engellenmiştir. Müşterilerimizin kişisel bilgilerinin gizliliğini korumak amacıyla Şirketimiz sistem ve internet alt yapısı en güvenilir seviyede tutularak gerekli önlemler alınmıştır.</li>
          <li>Şirketimiz, gerekli gördüğü durumlarda farklı kuruluşlardan destek hizmeti almakta ve ilgili kuruluşların Şirketin gizlilik standartlarına ve şartlarına uygun hareket etmesini sağlamaktadır. Şirketimiz, sözleşme düzenlediği veri işleyenlerin bilgi güvenliğine en az kendisi kadar önem verdiklerinden ve müşterek sorumluluğun bilinciyle hareket ettiklerinden emin olmakta ve bunu sözleşmesel olarak da güvenceye almaktadır. Veri işleyenler, mevzuattaki tanım ile paralel olarak yalnız Ziraat Teknoloji'nın talimatları doğrultusunda, Ziraat Teknoloji ile akdedilmiş sözleşme çevçevesinde kalmak suretiyle ve mevzuata uygun olarak kişisel verileri işler.</li>
          <li>Şirketimiz web sitesinde bulunan bilgi, materyal ve bunların düzenlenmesi konusundaki telif hakları, Ziraat Teknoloji A.Ş' ye aittir. Şirketimiz web sitesinde yer alan üçüncü şahıslara ait materyaller dışında kalan bilgi ve materyallere dair tüm telif hakları, tescilli marka, patent, entelektüel ve diğer mülkiyet hakları Şirketimizde saklıdır.</li>
        </ul>
        <p>Şirketimiz web sitesine müşterilerimizin/ziyaretçilerimizin giriş yapması, yukarıda belirtilen koşulları kabul ettiği anlamına gelmekte olup, Şirketimiz bu yasal uyarıda yer alan koşulları ve hükümleri, önceden bir ihbara gerek kalmaksızın değiştirme ve güncelleme hakkına sahiptir.</p>`,
        date: "05 Eylül 2023",
      },

      {
        name: "yasal-bilgilendirme",
        title: "Yasal Bilgilendirme",
        content: `
        <div className="text-box">
          <div className="row">
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Ticaret Ünvanı</span>
                <span className="text">Ziraat Teknoloji A.Ş.</span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Sicil No</span>
                <span className="text">823656</span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Mersis No</span>
                <span className="text">0388029350300012</span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">İşletme Merkezi</span>
                <span className="text">
                  İstanbul<br />
                  Yıldız Teknik Üniversitesi Davutpaşa Kampüsü Teknoloji Geliştirme Bölgesi C2 Blok 34220 Esenler
                </span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Telefon</span>
                <span className="text"><a href="tel:+902124846000">(212) 484 6000</a></span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Fax</span>
                <span className="text"><a href="tel:+902124837378">(212) 483 7378</a></span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Sermaye</span>
                <span className="text">Ödenmiş sermaye 55.000.000 TL</span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">İnternet Sitemiz</span>
                <span className="text"><a href="https://www.ziraatteknoloji.com">www.ziraatteknoloji.com</a></span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">IP Numaramız</span>
                <span className="text">194.24.224.3</span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Vergi Dairemiz</span>
                <span className="text">Esenler Vergi Dairesi</span>
              </div>
            </div>
            <div className="col-12 col-sm-4 mb-4">
              <div className="text-box__item pb-4">
                <span className="title">Vergi No</span>
                <span className="text">3880293503</span>
              </div>
            </div>
          </div>

        </div>`,
        date: "05 Eylül 2023",
      },
      {
        name: "ust-yonetim",
        title: "Ziraat Teknoloji Yönetimi",
        content: `
        <h2>Yönetim Kurulu</h2>
        <div className="management-box">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Emrah GÜNDÜZ</span>
                  <span className="text">Yönetim Kurulu Başkanı</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Bayram TUZCU</span>
                  <span className="text">Yönetim Kurulu Başkan Vekili ve Genel Müdür</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Onur Osman HALICI</span>
                  <span className="text">Yönetim Kurulu Üyesi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mehmet TARCAN</span>
                  <span className="text">Yönetim Kurulu Üyesi</span>
                </div>
              </div>
            </div>
             <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Özgür ARIK</span>
                  <span className="text">Yönetim Kurulu Üyesi</span>
                </div>
              </div>
            </div>
          </div>
        </div>


        <h2>Üst Yönetim</h2>
        <div className="management-box">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Bayram TUZCU</span>
                  <span className="text">Genel Müdür</span>
                </div>
              </div>
            </div>
          </div>
        </div>

        <h2>Grup Yöneticileri</h2>
        <div className="management-box">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mehmet Can ÖNAL</span>
                  <span className="text">BT Altyapı Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mehmet Soner ALTAŞ</span>
                  <span className="text">Dijital Bankacılık Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Uğur TEMİR</span>
                  <span className="text">Bilgi Güvenliği ve Destek Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mahmut Sinan SARIKAYA</span>
                  <span className="text">Analitik Bankacılık Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Ahmet ŞEKER</span>
                  <span className="text">Bankacılık Uygulamaları Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Tayfun DURAN</span>
                  <span className="text">Program Yönetimi Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Davut GÜNGÖR</span>
                  <span className="text">Kredi Uygulamaları Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mahmut KUŞ</span>
                  <span className="text">BT Mimari Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Oğuz KAYA</span>
                  <span className="text">Katılım Bankacılığı Grup Yöneticisi</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Muhammed Cuma TAHİROĞLU</span>
                  <span className="text">Katılım Bankacılığı Altyapı ve Program Yönetimi Grup Yöneticisi</span>
                </div>
              </div>
            </div>
          </div>
        </div>`,
        date: "September 05, 2023"
      },
      {
        name: "iletisim",
        title: "İletişim",
        content: `
        <div class="contact-page__maps">
        <a href="https://maps.app.goo.gl/8nfe498GGxuVX28E9" target="_blank">
        <img src="_assets/img/google-maps.png" alt=""  />
        </a>
    </div>


        <div className="row gx-6">
          <div className="col-12 col-sm-6">
            <div className="contact-page__address">
              <div className="contact-page__address__item">
                <h2>İstanbul - Anadolu Yakası İstanbul Finans Merkezi</h2>
                <p>Ziraat Bankası Genel Müdürlüğü A Blok 5. Kat Finanskent Mah. Finans Cad. No:44/A Ümraniye / İstanbul</p>
                <h3>Telefon</h3>
                <p><a href="tel:+902124846000">+90 212 484 6000</a></p>
                <h3>Faks</h3>
                <p><a href="tel:+902124837378">+90 212 483 7378</a></p>
                
              </div>
                <div className="contact-page__address__item">
                <h2>Ankara</h2>
                <p>Ziraat Bankası Eğitim Tesisleri, Varlık Mah. Fatih Sultan Mehmet Bulvarı No:10 Kat:3 Yenimahalle / Ankara</p>
                <h3>Telefon</h3>
                <p><a href="tel:+902124846000">+90 212 484 6000</a></p>
                <h3>Faks</h3>
                <p><a href="tel:+902124837378">+90 212 483 7378</a></p>
                
              </div>
             
            </div>
          </div>
          <div className="col-12 col-sm-6">
            <div className="contact-page__address">
              <div className="contact-page__address__item">
                <h2>İstanbul - Avrupa Yakası Y.T.Ü Teknopark</h2>
                <p>Yıldız Teknik Üniversitesi Davutpaşa Kampüsü Teknoloji Geliştirme Bölgesi C2 Blok 34220 Esenler-İstanbul</p>
                <h3>Telefon</h3>
                <p><a href="tel:+902124846000">+90 212 484 6000</a></p>
                <h3>Faks</h3>
                <p><a href="tel:+902124837378">+90 212 483 7378</a></p>
                
              </div>
            
            </div>
          </div>
        </div>

`,
        date: "05 Eylül 2023",
      },
    ],
    commonWords: [
      { key: "all", label: "Tümü" },
      { key: "home-page", label: "Ana Sayfa" },
      { key: "search", label: "Arama" },
      { key: "ziraat-search", label: "Ziraat Teknoloji'de Ara" },
      { key: "copyright", label: "Ziraat Teknoloji, 'Ziraat Finans Grubu' Üyesidir ©2024" },
      { key: "ziraat-teknoloji-kariyer-olanaklari", label: "Ziraat Teknoloji'de \n Kariyer Olanakları!" },
      { key: "calisan", label: "ÇALIŞAN" },
      { key: "ulke", label: "ÜLKE" },
      { key: "kurum", label: "KURUM" },
      { key: "yerleske", label: "YERLEŞKE" },
      { key: "read-more", label: "Daha fazlasını oku" },
      { key: "follow-us", label: "Bizi Takip Edin" },
      { key: "location", label: "Konum" },
      { key: "view", label: "Görüntüle" },
      { key: "enter-search-word", label: "Aramak istediğiniz sözcük" },
      { key: "please-enter-min-char", label: "Lütfen en az 3 karakter giriniz" },
    ]
  },
  en: {
    menus: [
      {
        key: "teknoloji",
        label: "About Us"
      },
      {
        key: "hizmetlerimiz",
        label: "Our Services"
      },
      {
        key: "yeniliklerimiz",
        label: "Technology and Innovations"
      },
      {
        key: "cozumlerimiz",
        label: "Current Projects and Solutions"
      },
      {
        key: "birlikte-basarmak",
        label: "Careers",
        children: [
          {
            key: "ziraat-teknolojili-olmak",
            label: "Joining Ziraat Teknoloji",
            url: "/ziraat-teknolojili-olmak"
          },
          {
            key: "ziraat-teknolojide-hayat",
            label: "Life at Ziraat Teknoloji",
            url: "/ziraat-teknolojide-hayat"
          }
        ]
      },
      {
        key: "iletisim",
        label: "Contact",
        url: "iletisim"
      }
    ],
    sliders: [
      {
        titles: ["Technology ", "For More"],
        video: "zt-video-1",
        link: ""
      },
      {
        titles: ["Technology", "For More"],
        video: "zt-video-2",
        link: ""
      },
      {
        titles: ["Shaping Future Together:", "Looking For Experienced Teammates", "in Ankara & Istanbul"],
        image: "banner.jpg",
        link: "https://ziraatteknoloji.hrpeak.com/jobs"
      }
    ],
    sections: [
      {
        name: SECTION_NAMES.Technology,
        title: "Technology For More",
        content: "As one of Turkey's largest financial technology companies, since our establishment in 2001, we have been saying 'Technology for More' together with all our stakeholders, passionately working to provide our customers with innovative products, solutions, and services.\n At Ziraat Teknoloji, we prioritize people at the core of all our efforts, creating value together with our competent and happy human resources.",
        children: [{
          name: "mission-tab",
          title: "Our Mission",
          content: "To be a continuously developing company providing a competitive advantage by offering services for the technology needs of its customers in international standards and contemporary norms with R&D activities."
        },
        {
          name: "vision-tab",
          title: "Our Vision",
          content: "To be a pioneer with innovative products, solutions, and services that add value to our customers in the field of financial technologies."
        },
        {
          name: "innovation-tab",
          title: "Innovation",
          content: "Together with all our stakeholders, we follow trends in financial technologies and offer innovative products and services to our customers. Our projects related to Artificial Intelligence, Blockchain-based Applications, Cloud Banking, and Service Model Banking represent the importance of innovation for our company."
        },
        {
          name: "our-customers-tab",
          title: "Our Customers",
          content: "Our company operates in Yıldız Technopark, Istanbul Technopark, Istanbul Financial Center, and Ankara Varlık locations. We provide services that enable the management of IT resources and processes within the Ziraat Financial Group, consisting of Ziraat Bank and Ziraat Participation Bank, and their branches and subsidiaries in 18 different countries, in alignment with corporate goals and strategies."
        },
        {
          name: "shareholding-tab",
          title: "Shareholding",
          content: "100% of the capital of Ziraat Teknoloji A.Ş. is owned by Ziraat Bankası A.Ş.",
        }
        ]
      },
      {
        name: SECTION_NAMES.OurServices,
        title: "Our Services",
        content: "",
        children: [{
          name: "yazilim-gelistirme",
          title: "Software Development",
          content: "Ziraat Teknoloji develops innovative applications designed to meet the critical business and technology needs of Ziraat Finance Group and provides integration, maintenance, and support services for the effective use of these applications.",
          image: "yazilim-gelistirme.png"
        },
        {
          name: "uygulama-altyapi",
          title: "Application and IT Infrastructure",
          content: "Ziraat Teknoloji provides planning, implementation, operation, and management services that enable the efficient and low-cost use of IT resources. Application Infrastructure (Application Architecture, DevOps, Service Architecture, Monitoring) and IT Infrastructure (Network Management, Data Center, Database, Central, Environmental Systems) ensure the modernization and continuity of the products and services offered.",
          image: "uygulama-bt.png"
        },
        {
          name: "bilgi-guvenligi",
          title: "Information Security",
          content: "Thanks to the 24/7 working Cyber Security Center, Ziraat Finance Group is protected against information security incidents in real-time. In addition, with a vulnerability and risk management approach, potential incidents are prevented, and consultancy services on information security are provided to Ziraat Finance Group.",
          image: "bilgi-guvenligi.png"
        },
        {
          name: "bt-danismanligi",
          title: "IT Consulting",
          content: "Ziraat Teknoloji provides services that allow the management of IT resources and processes in alignment with corporate goals and strategies. In addition, it evaluates existing resources and processes with its customers, identifies needs, and analyzes opportunities and solutions to increase the company's competitiveness with short and long-term planning for return on investment.",
          image: "bt-danismanligi.png"
        }
        ]
      },
      {
        name: SECTION_NAMES.OurInnovations,
        title: "Technology and Innovations",
        content: "",
        children: [{
          name: "cevik-yontemler",
          title: "Agile Methods",
          content: "Modern processes based on the principles and values of agility are implemented in Ziraat Teknoloji. Iterative (Scrum) and Flow-based (Kanban) agile approaches are followed company-wide. Iterative Method is applied in teams of 3-9 people, where valuable outputs can be produced with fixed and repetitive cycles. It is used when there are defined roles such as product owner-development team, and meaningful and combinable outputs can be obtained at the end of each cycle. Flow-Based method is designed for situations where teams provide services to different units simultaneously, maintenance tasks, or production errors, and when the team and roles cannot be determined, and development continues continuously.",
          image: "tech-b4.png"
        },
        {
          name: "devops",
          title: "DevOps",
          content: "Ziraat Teknoloji application development and dissemination processes are operated rapidly and seamlessly, utilizing the latest technologies. Infrastructure support for microservices architecture-based applications, developed in popular programming languages such as Golang, .NET Core, Java, Python, JavaScript, etc., is provided in accordance with DevOps processes. Applications, compiled by pulling source code from the Git based code repository, are versioned in container technology after being compiled on pipelines created in accordance with the Continuous Integration/Continuous Delivery methodology. Following automated tests, they are uploaded to the Harbor image repository, and they are hosted on various container orchestration tools such as Kubernetes-based Openshift Container Platform or Docker Swarm. Additionally, installation and maintenance services for open-source products like Nexus, Minio, Consul, Redis, Kafka, Cassandra, Storm, ELK, Prometheus, Grafana, InfluxDB are also provided.",
          image: "tech-b5.png"
        },
        {
          name: "onyuz-mimari",

          title: "Modern Front-End Architecture",
          content: "Our main banking application, the Anahtar app, is developed using modern web technologies such as React, Html5, Css, Javascript, and Typescript for the frontend architecture. The frontend, developed as 'Responsive,' can operate independently through the 'browser' regardless of the operating system. With the Micro Frontend architectural approach, screens and all interface components opened within the Anahtar application are developed, tested, and popularized independently.",
          image: "tech-b1.png"
        },
        {
          name: "backend-framework",
          title: "Modern Back-End Architecture",
          content: "In our core banking platform, the latest .Net and .Net Core-based frameworks are employed, utilizing the C# programming language with a service-oriented architecture based on RESTful principles.",
          image: "tech-b2.png"
        },
        {
          name: "mobil-uygulama-altyapisi",
          title: "Mobile Application Infrastructure",
          content: "Our Ziraat Financial Group employs the latest technologies (Swift, Kotlin, React Native, and Flutter) to develop native and cross-platform mobile applications tailored to our needs. Ready-made infrastructures are used for many common requirements in these applications, and mobile DevOps processes are actively managed.",
          image: "tech-b3.png"
        }
        ]
      },
      {
        name: SECTION_NAMES.OurProjectSolution,
        title: "Current Projects and Solutions",
        content: "",
        children: [{
          name: "anahtar",
          title: "Anahtar and Anahtar Global",
          content: "Our main banking application, Anahtar, with nearly 6000 screens, is utilized by all employees and customers at Ziraat Bank Headquarters and branches for all financial and non-financial transactions. Additionally, our in-house developed application, Anahtar Global, meets the basic banking needs of our international subsidiaries.",
          image: "solution1.jpg"
        },
        {
          name: "entropi",
          title: "Entropy",
          content: "The Entropy Application is an IDC Award-winning Enterprise Architectural Management tool developed with our own resources. It allows the management of outputs produced in Ziraat Teknoloji and Ziraat Bank environments in a single environment and the correlation of architectural models and inventories in different layers.",
          image: "solution2.jpg"
        },
        {
          name: "dama",
          title: "Dama +",
          content: "It is an application we developed internally, a Data Architecture Management application that started to be used within Ziraat Teknoloji in 2021. It allows the design of the data model in compliance with architectural standards and approval processes from the very beginning of the development processes.",
          image: "solution3.jpg"
        },
        {
          name: "paloma",
          title: "Paloma",
          content: "All institutions within Ziraat Financial Group utilize our proprietary application for all SMS and email transmissions. Our application seamlessly addresses the high-capacity transmission needs of all institutions for OTP SMS, bulk SMS, and email deliveries 24/7.",
          image: "solution4.jpg"
        },
        {
          name: "ug-araclari",
          title: "Our Application Development Tools",
          content: "We expedite our project production processes using our proprietary solution development tools.\listPergel - Demand Management Application\nRadix - Code Review Management Application\nFinix - Deployment Management Application\nFlowart - Workflow Application\nDMS - Document Management System Application\n", image: "solution5.jpg"
        },
        {
          name: "super-sube",
          title: "Super Branch",
          content: "A next-generation service offered to customers who use digital channels intensively and want to carry out banking transactions without going to traditional branches. Transfers, payments, deposits, and loan transactions can be carried out advantageously and exclusively through Super Branch.",
          image: "solution6.jpg"
        },
        {
          name: "sanal-asistan",
          title: "Virtual Assistant",
          content: "Providing the opportunity to carry out banking transactions easily and practically, the Virtual Assistant ensures quick transaction by directing customers to relevant menus in digital channels according to their needs, in addition to providing clear and understandable answers.",
          image: "solution7.jpg"
        },
        {
          name: "acik-bankacilik",
          title: "Open Banking",
          content: "Ziraat customers can view and transfer funds from their accounts at other banks and institutions through Ziraat Mobile and Internet Branch. They can benefit more quickly and efficiently from banking services with more advantageous offers and campaigns.",
          image: "solution8.jpg"
        },
        {
          name: "ticaret-yolu",
          title: "Trade Route",
          content: "In Trade Route, companies and products can be introduced, products can be sold, and businesses can be expanded.",
          image: "solution9.jpg"
        }
        ]
      },
      {
        name: SECTION_NAMES.SuccesTogether,
        title: "We Believe in the Power of Achieving\tPerforming\tProducing\tImproving Together",
        content: "We see our employees as the driving force to achieve our company's vision.\n We believe in the equality of individuals and apply this principle in our work life.\n Together with our managers, we strive to create a happy and peaceful work environment that supports the development of our employees.\n We enhance the individual and team performance of our employees through quality-focused processes and systems.\n With the principle of 'There is a potential waiting to be discovered in every person,' we provide opportunities for all our employees to self-actualize.",
        children: [{
          name: "ziraat-teknolojili-olmak",
          title: "Working in\n Ziraat\n Teknoloji",
          image: "success-together-bg1.png",
          link: "ziraat-teknolojili-olmak"
        },
        {
          name: "kariyerine-yon-ver",
          title: "Directing\n Your Career",
          image: "success-together-bg2.png",
          link: "ziraat-teknolojide-hayat"
        },
        {
          name: "potansiyelini-kesfet",
          title: "Discover\n Your Potential",
          image: "success-together-bg3.png",
          link: "ziraat-teknolojide-hayat#potansiyelini-kesfet"
        },
        {
          name: "mulakatlar",
          title: "Interviews",
          image: "success-together-bg5.png",
          link: "mulakat-sonuc-sorgulama"
        }
        ]
      },
      {
        name: SECTION_NAMES.OurAwards,
        title: "Our Awards",
        content: "",
        children: [{
          name: "tech-brands-turkey",
          title: "Tech Brands Turkey",
          content: "Most Technological Bank",
          link: "2023",
          image: "Stevie_Awards_2.png"
        },
        {
          name: "tech-brands-turkey",
          title: "Tech Brands Turkey",
          content: "Most Technological Mobile Bank",
          link: "2023",
          image: "Tech_Brands_Turkey.png"
        },
        {
          name: "tech-brands-turkey",
          title: "ECHO Awards",
          content: "Online Banking",
          link: "2023",
          image: "ECHO.png"
        },
        {
          name: "tech-brands-turkey",
          title: "Global Banking & Finance Review",
          content: "Digital Analytic CRM",
          link: "2023",
          image: "Global_Banking_Finance.png"
        },
        {
          name: "tech-brands-turkey",
          title: "PSM Awards",
          content: "Digital Security",
          link: "2022",
          image: "PSM.png"
        },
        {
          name: "tech-brands-turkey",
          title: "Stevie Awards",
          content: "Best Bank in Data Quality Management",
          link: "2022",
          image: "Stevie_Awards.png"
        },
        {
          name: "tech-brands-turkey",
          title: "Globee Business Awards",
          content: "Best Information Technology Projects and Ventures of the Year",
          link: "2022",
          image: "Globee_Awards.png"
        },
        {
          name: "tech-brands-turkey",
          title: "PSM Awards",
          content: "Remote Customer Acquisition Project",
          link: "2021",
          image: "PSM.png"
        },
        {
          name: "tech-brands-turkey",
          title: "PSM Awards",
          content: "Digital Transformation",
          link: "2021",
          image: "PSM.png"
        },
        {
          name: "tech-brands-turkey",
          title: "Altın Örümcek",
          content: "Ziraat Mobile Project",
          link: "2021",
          image: "Altin_Orumcek.png"
        },
        {
          name: "tech-brands-turkey",
          title: "Altın Örümcek",
          content: "Mobile Application-Banking & Finance",
          link: "2021",
          image: "Altin_Orumcek.png"
        },
        {
          name: "tech-brands-turkey",
          title: "Global Banking & Finance Review",
          content: "Best Banking Technology Application of the Year 2021",
          link: "2021",
          image: "Global_Banking.png"
        }
        ]
      },
      {
        name: SECTION_NAMES.OurCampuses,
        title: "Our Campuses",
        content: "",
        children: [{
          name: "davutpasa",
          title: "Istanbul - Europe / Yıldız Technopark",
          content: "Yıldız Technical University, Davutpaşa Campus, Technopark C2 Block Esenler / Istanbul",
          link: "https://maps.app.goo.gl/8nfe498GGxuVX28E9",
          image: "davutpasa.png"
        },
        {
          name: "finans-merkezi",
          title: "Istanbul – Anatolia / Istanbul Financial Center",
          content: "Ziraat Bank General Directorate A Block 5th Floor Finanskent Mah. Finans Cad. No:44/A Ümraniye / Istanbul",
          link: "https://maps.app.goo.gl/BxD7KaBdaz9tNa2X9",
          image: "finans-merkezi.png"
        },
        {
          name: "ankara",
          title: "Ankara",
          content: "Ziraat Bank Training Facilities, Varlık Mah. Fatih Sultan Mehmet Blvd. No:10 Floor:3 Yenimahalle / Ankara",
          link: "https://maps.app.goo.gl/972LhRXMQ78XjKpB6",
          image: "ankara.png"
        }
        ]
      },
      {
        name: SECTION_NAMES.OpenPositions,
        title: "Open Positions",
        content: "",
        children: [
          {
            name: "tum-acik-pozisyonlar",
            title: "All Open Positions",
            content: "Istanbul / Ankara",
            link: "https://ziraatteknoloji.hrpeak.com/",
            linkAlt: "View",
            image: "svg svg--ziraat",
          },
          {
            name: "is-analisti",
            title: "Business Analyst",
            content: "Istanbul / Ankara",
            link: "https://ziraatteknoloji.hrpeak.com/jobs",
            linkAlt: "View",
            image: "svg svg--work-analyze"
          },
          {
            name: "yazilim-muhendisi",
            title: "Software Engineer",
            content: "Istanbul / Ankara",
            link: "https://ziraatteknoloji.hrpeak.com/jobs",
            linkAlt: "View",
            image: "svg svg--microchip"
          },
        ]
      }
    ],
    announcements: {
      name: "duyuru",
      title: "Announcements",
      data: [{
        name: "/duyuru/kisisel-verilerin-korunmasi-kanunu-hakkinda-bilgilendirme",
        title: "Information on the Law on the Protection of Personal Data",
        content: `<h2>Privacy Notice for the Protection of Personal Data</h2>
      <p>
        In order to protect the fundamental rights and freedoms of individuals, especially the privacy of private life, and to determine the obligations of natural and legal persons processing personal data, Ziraat Teknoloji A.Ş. ("Ziraat Teknoloji") as the data controller, would like to inform you in accordance with Article 10 of the Law on the Protection of Personal Data ("KVKK") titled "Information Obligation of the Data Controller," published in the Official Gazette dated April 7, 2016, and numbered 29677.
      </p>
      <p>
        Ziraat Teknoloji maintains the confidentiality of personal information obtained from data subjects by keeping the system infrastructure and internet servers at the most reliable level.
      </p>
      
      <h3>Purpose, Method of Processing Personal Data, and Legal Basis</h3>
      <p>
        Ziraat Teknoloji processes your identity, communication, resume, legal transaction, customer transaction, location, camera images, risk management information, financial, professional experience, visual and auditory records, demographic information, education level, and income level information; through various contracts, electronic mail, application forms, written or verbal communication channels with our company, automatically in oral, written, or electronic form during the establishment of your legal relationship with our company and throughout the continuation of this relationship. This processing is based on legal reasons, including but not limited to the explicit provisions in the laws specified in Article 5/2(a) of the KVKK, the necessity for the establishment or performance of the contract as stated in Article 5/2(c) of the KVKK, the fulfillment of our company's legal obligations as stated in Article 5/2(d) of the KVKK, the data subject making the information public by themselves as stated in Article 5/2(e) of the KVKK, the establishment, exercise, or protection of a right as stated in Article 5/2(e) of the KVKK, and the necessity of processing for the legitimate interests of the data controller, provided that it does not harm the fundamental rights and freedoms of the data subject as per Article 5/2(f).
      </p>
      
      <ul className="list">
        <li>Ensuring the proper fulfillment of contractual obligations;</li>
        <li>Recording your information to fulfill our obligations regarding knowing our customer in accordance with the regulations we are subject to;</li>
        <li>Recording notifications such as complaints, objections, requests, suggestions, satisfaction in our notification management system to better serve you;</li>
        <li>Planning and executing business activities and operational processes;</li>
        <li>Conducting and executing customer relationships;</li>
        <li>Ensuring the accuracy and currency of your data;</li>
        <li>Fulfillment of support service obligations, customer satisfaction, corporate communication activities;</li>
        <li>Evaluation of requests and complaints made via Social Media and proposing solutions;</li>
        <li>Execution and management of business conducted with Ziraat Bank A.Ş. and domestic, foreign branches, and subsidiaries;</li>
        <li>Execution of lawsuits and execution follow-ups that our company is a party to;</li>
        <li>Planning and execution of corporate sustainability, corporate governance, strategic planning, and information security processes;</li>
        <li>Recording camera images for security reasons and our obligations arising from the law;</li>
      </ul>
      
      <p>
        In some of the activities carried out by Ziraat Teknoloji, explicit consent from you may be required when processing your personal data. In accordance with Article 6/2 of the KVKK, your explicit consent is required for the purposes of providing physical security and appropriate support services based on your fingerprint and disability status information and for the purposes of processing your personal data related to behavioral analyzes, planning, and execution of recruitment processes, introduction, and advertising activities in accordance with Article 5/1 of the KVKK.
      </p>
      
      <h3>Transfer of Your Personal Data</h3>
      <p>
        Your personal data may be transferred to public institutions and organizations such as the BRSA, CMB, MASAK, Union of Turkish Bar Associations, Ministry of Finance, insurance companies, third parties with whom we conduct our activities within the scope of privacy agreements and other similar contracts, including T.C. Ziraat Bank A.Ş. and its domestic and foreign subsidiaries, within the framework of the purposes and legal reasons specified in Article 1 of this Information Text in accordance with the provisions of the Law on the Protection of Personal Data regarding the transfer and international transfer of personal data.
      </p>
      
      <h3>Storage of Your Personal Data</h3>
      <p>
        Your personal data may be stored for the periods required by the purposes of processing. In the absence of another reason or legal ground, the personal data that has fulfilled its purposes is deleted, destroyed, or anonymized when the international law or regulation does not exist, and the contractual obligations arising from the contracts cease to exist.
      </p>
      
      <h3>Rights of the Data Subject Whose Personal Data is Processed</h3>
      <p>
        Regarding the processing of your personal data, you can exercise the following rights by making a request to Ziraat Teknoloji. Requests submitted in this context will be concluded free of charge by Ziraat Teknoloji within thirty days at the latest. However, if a fee is stipulated by the Personal Data Protection Board, a fee determined by our company can be charged. In this context, as the data subject;
      </p>
      
      <ul className="list">
        <li>Learning whether personal data is processed;</li>
        <li>Requesting information if personal data has been processed;</li>
        <li>Learning the purpose of processing personal data and whether they are used appropriately for their purpose;</li>
        <li>Knowing the third parties to whom personal data are transferred domestically or abroad;</li>
        <li>Requesting correction if personal data is incomplete or incorrectly processed;</li>
        <li>Requesting deletion or destruction of personal data;</li>
        <li>Requesting notification of corrections, deletions, or destructions to third parties to whom personal data has been transferred, in case of correction, deletion, or destruction of personal data;</li>
        <li>Objecting to the emergence of a result against the person by analyzing the processed data exclusively through automatic systems;</li>
        <li>Requesting compensation for damages in case of damage due to the illegal processing of personal data;</li>
      </ul>
      
      <p>
        You have these rights. Within the scope of the Law No. 6698, you can personally submit your written applications regarding your rights regarding your personal data, along with identity documents encouraging your identity, and your petition containing your request, to "Yıldız Technical University Davutpaşa Campus Technology Development Zone C2 Block 34220 Esenler/ISTANBUL" in person, by notary, or to ziraatteknoloji@hs01.kep.tr via secure electronic signature, mobile signature, or using the electronic mail address previously notified to our company, and the applications will be accepted by our institution following the identity verification process, and a response will be provided within the periods specified in the law.
      </p>`,
        date: "05 Jan 2024",

      }]
    },
    subPages: [
      {
        name: "standartlarimiz",
        title: "Our Standards",
        content: `  <div><img src="_assets/img/ISO9001.jpg" alt=""  /><h2>ISO 9001 Quality Management System</h2>      </div> 
        <div> <img src="_assets/img/ISO22301.jpg" alt=""  /><h2>ISO 22301 Business Continuity Management System </h2></div>
        <div><img src="_assets/img/ISO27001.png" alt=""  /><h2>ISO 27001 Information Security Management System </h2></div>`,
        date: "05 Eylül 2023",
      },
      {
        name: "bilgi-toplumu-hizmetleri",
        title: "Information Society Services",
        link: "https://e-sirket.mkk.com.tr/?page=company&company=11626",
        date: "September 05, 2023"
      },
      {
        name: "bilgi-guvenligi-politikasi",
        title: "Information Security Policy",
        content: `<p>
        Ziraat Teknoloji A.Ş. aims to ensure the security, confidentiality, integrity and usability of all kinds of information belonging to itself and its stakeholders, the locations where it carries out its activities and corporate information management processes.
        <br />In order to achieve this goal, Ziraat Technology implements an information security management system in accordance with the TS EN ISO/IEC 27001 standard, where the applicable conditions regarding information security are met.</p>
        <p>As a consequence, it undertakes to meet the following conditions.</p>
        
        <ul className="list">
        <li>Implementation, documentation and continuous improvement of an Information Security Management System as required by TS EN ISO/IEC 27001 standard,</li>
        <li>Ensuring the requirements required by laws and standards related to information security by recognizing the information and related components under its responsibility as valuable and critical,</li>
        <li>Providing the necessary infrastructure and taking security measures to ensure that information technology services used in the realization of corporate activities are maintained uninterruptedly and that information can only be accessed by authorized persons,</li>
        <li>Compliance with legal regulations and requirements related to the information security management system,</li>
        <li>Fulfillment of the necessary responsibilities to raise awareness on information security, particularly through trainings,</li>
        <li>Defining the requirements of third parties, customers and suppliers regarding the information security management system and ensuring that they act in accordance with the information security management system,</li>
        <li>Ensuring that the information security management system is regularly audited and continuously improved.</li>
          </ul>`,
        date: "June 14, 2024",
      },
      {
        name: "kvkk-aydinlatma-metni",
        title: "Personal Data Protection",
        date: "September 05, 2023",
        content: `<h2>Privacy Notice for the Protection of Personal Data</h2>
      <p>
        In order to protect the fundamental rights and freedoms of individuals, especially the privacy of private life, and to determine the obligations of natural and legal persons processing personal data, Ziraat Teknoloji A.Ş. ("Ziraat Teknoloji") as the data controller, would like to inform you in accordance with Article 10 of the Law on the Protection of Personal Data ("KVKK") titled "Information Obligation of the Data Controller," published in the Official Gazette dated April 7, 2016, and numbered 29677.
      </p>
      <p>
        Ziraat Teknoloji maintains the confidentiality of personal information obtained from data subjects by keeping the system infrastructure and internet servers at the most reliable level.
      </p>
      
      <h3>Purpose, Method of Processing Personal Data, and Legal Basis</h3>
      <p>
        Ziraat Teknoloji processes your identity, communication, resume, legal transaction, customer transaction, location, camera images, risk management information, financial, professional experience, visual and auditory records, demographic information, education level, and income level information; through various contracts, electronic mail, application forms, written or verbal communication channels with our company, automatically in oral, written, or electronic form during the establishment of your legal relationship with our company and throughout the continuation of this relationship. This processing is based on legal reasons, including but not limited to the explicit provisions in the laws specified in Article 5/2(a) of the KVKK, the necessity for the establishment or performance of the contract as stated in Article 5/2(c) of the KVKK, the fulfillment of our company's legal obligations as stated in Article 5/2(d) of the KVKK, the data subject making the information public by themselves as stated in Article 5/2(e) of the KVKK, the establishment, exercise, or protection of a right as stated in Article 5/2(e) of the KVKK, and the necessity of processing for the legitimate interests of the data controller, provided that it does not harm the fundamental rights and freedoms of the data subject as per Article 5/2(f).
      </p>
      
      <ul className="list">
        <li>Ensuring the proper fulfillment of contractual obligations;</li>
        <li>Recording your information to fulfill our obligations regarding knowing our customer in accordance with the regulations we are subject to;</li>
        <li>Recording notifications such as complaints, objections, requests, suggestions, satisfaction in our notification management system to better serve you;</li>
        <li>Planning and executing business activities and operational processes;</li>
        <li>Conducting and executing customer relationships;</li>
        <li>Ensuring the accuracy and currency of your data;</li>
        <li>Fulfillment of support service obligations, customer satisfaction, corporate communication activities;</li>
        <li>Evaluation of requests and complaints made via Social Media and proposing solutions;</li>
        <li>Execution and management of business conducted with Ziraat Bank A.Ş. and domestic, foreign branches, and subsidiaries;</li>
        <li>Execution of lawsuits and execution follow-ups that our company is a party to;</li>
        <li>Planning and execution of corporate sustainability, corporate governance, strategic planning, and information security processes;</li>
        <li>Recording camera images for security reasons and our obligations arising from the law;</li>
      </ul>
      
      <p>
        In some of the activities carried out by Ziraat Teknoloji, explicit consent from you may be required when processing your personal data. In accordance with Article 6/2 of the KVKK, your explicit consent is required for the purposes of providing physical security and appropriate support services based on your fingerprint and disability status information and for the purposes of processing your personal data related to behavioral analyzes, planning, and execution of recruitment processes, introduction, and advertising activities in accordance with Article 5/1 of the KVKK.
      </p>
      
      <h3>Transfer of Your Personal Data</h3>
      <p>
        Your personal data may be transferred to public institutions and organizations such as the BRSA, CMB, MASAK, Union of Turkish Bar Associations, Ministry of Finance, insurance companies, third parties with whom we conduct our activities within the scope of privacy agreements and other similar contracts, including T.C. Ziraat Bank A.Ş. and its domestic and foreign subsidiaries, within the framework of the purposes and legal reasons specified in Article 1 of this Information Text in accordance with the provisions of the Law on the Protection of Personal Data regarding the transfer and international transfer of personal data.
      </p>
      
      <h3>Storage of Your Personal Data</h3>
      <p>
        Your personal data may be stored for the periods required by the purposes of processing. In the absence of another reason or legal ground, the personal data that has fulfilled its purposes is deleted, destroyed, or anonymized when the international law or regulation does not exist, and the contractual obligations arising from the contracts cease to exist.
      </p>
      
      <h3>Rights of the Data Subject Whose Personal Data is Processed</h3>
      <p>
        Regarding the processing of your personal data, you can exercise the following rights by making a request to Ziraat Teknoloji. Requests submitted in this context will be concluded free of charge by Ziraat Teknoloji within thirty days at the latest. However, if a fee is stipulated by the Personal Data Protection Board, a fee determined by our company can be charged. In this context, as the data subject;
      </p>
      
      <ul className="list">
        <li>Learning whether personal data is processed;</li>
        <li>Requesting information if personal data has been processed;</li>
        <li>Learning the purpose of processing personal data and whether they are used appropriately for their purpose;</li>
        <li>Knowing the third parties to whom personal data are transferred domestically or abroad;</li>
        <li>Requesting correction if personal data is incomplete or incorrectly processed;</li>
        <li>Requesting deletion or destruction of personal data;</li>
        <li>Requesting notification of corrections, deletions, or destructions to third parties to whom personal data has been transferred, in case of correction, deletion, or destruction of personal data;</li>
        <li>Objecting to the emergence of a result against the person by analyzing the processed data exclusively through automatic systems;</li>
        <li>Requesting compensation for damages in case of damage due to the illegal processing of personal data;</li>
      </ul>
      
      <p>
        You have these rights. Within the scope of the Law No. 6698, you can personally submit your written applications regarding your rights regarding your personal data, along with identity documents encouraging your identity, and your petition containing your request, to "Yıldız Technical University Davutpaşa Campus Technology Development Zone C2 Block 34220 Esenler/ISTANBUL" in person, by notary, or to ziraatteknoloji@hs01.kep.tr via secure electronic signature, mobile signature, or using the electronic mail address previously notified to our company, and the applications will be accepted by our institution following the identity verification process, and a response will be provided within the periods specified in the law.
      </p>`
      },
      {
        name: "cerez-politikasi",
        title: "Cookie Policy",
        content: `<h3>General</h3>
        <p>Cookies refer to small data files sent to users' devices by the internet server through the internet browser in use, and websites use these cookies to recognize users. The lifespan of cookies varies depending on browser settings.</p>
        <p>While these cookies are managed by the Company's systems, certain authorized service providers, endorsed by the Company, may also place similar technologies on users' devices to obtain IP addresses, unique identifiers, and device identification information. Additionally, links to third-party sites within the Company's systems are subject to the privacy policies of those third parties. The responsibility for privacy practices in this context does not belong to the Company, and it is recommended to read the privacy policy of the visited site within the relevant link.</p>
        
        <h3>Types of Cookies</h3>
        <p>Cookies, primarily intended to provide convenience to users, are broadly categorized into 4 main groups:</p>
        
        <ul className="list">
          <li>Session Cookies: These cookies allow various features, such as the transfer of information between web pages and the systemic remembrance of information entered by the user, and are necessary for the proper functioning of the Company's website functions.</li>
          <li>Performance Cookies: These cookies collect information about the frequency of page visits, potential error messages, total time users spend on the relevant page, and usage patterns to enhance the performance of the Company's website.</li>
          <li>Functional Cookies: These cookies remind users of pre-selected options to provide ease of use and aim to provide advanced internet features to users within the scope of the Company's website.</li>
          <li>Advertising and Third-Party Cookies: These are cookies from third-party providers that enable the use of certain functions on the Company's website and allow for ad tracking.</li>
        </ul>
        
        <h3>Purposes of Cookie Usage</h3>
        <p>The purposes of using cookies by our Company are as follows:</p>
        
        <ul className="list">
          <li>Operational Uses: For the administration and security of its systems, the Company may use cookies that enable the use of functions on this site or detect irregular behaviors.</li>
          <li>Functionality Uses: To facilitate the use of its systems and provide user-specific usage features, the Company may use cookies that remind users of their information and past choices.</li>
          <li>Performance Uses: To enhance and measure the performance of its systems, the Company may use cookies that evaluate and analyze interactions with sent messages and user behaviors.</li>
          <li>Advertising Uses: To transmit advertisements and similar content to users based on their areas of interest through its own or third-party systems, the Company may use cookies that measure the effectiveness of these advertisements or analyze click-through situations.</li>
        </ul>
        
        <h3>Disabling Cookies</h3>
        <p>Cookie usage is predefined as selected in many browsers, and users can change this selection status from browser settings, thereby deleting existing cookies and rejecting future cookie usage. However, if cookie usage is disabled, some features on the Company's systems may not be available.</p>
        <p>The method of changing the cookie usage selection depends on the browser type and can be learned from the relevant service provider when desired.</p>
        
        <h3>Information and Material on the Website</h3>
        <p>The copyright, registered trademark, patent, intellectual property, and other proprietary rights of information, material, and their organization on our Company's website, except for materials owned by third parties, belong to our Company.</p>
        `,
        date: "September 5, 2023",
      },
      {
        name: "gizlilik-politikasi",
        title: "Privacy Policy",
        content: `<p>Ziraat Teknoloji Inc. takes necessary measures to ensure the privacy and security of customer information within the framework of relevant legal regulations. In this regard, our company implements the following practices on its website and internet branch:</p>

        <ul className="list">
          <li>Ziraat Teknoloji Inc., as the Data Controller, processes personal data obtained verbally, in writing, or electronically through channels such as the General Directorate and the call center in a lawful manner to carry out its activities and ensure the uninterrupted progress of its services.</li>
          <li>Our company values the privacy and security of personal data. Accordingly, necessary technical and administrative security measures are taken to protect personal data from unauthorized access, damage, loss, or disclosure. The company is not responsible for the security and privacy of links provided for access to other websites via the website. No liability will be accepted for material or moral losses that may be encountered as a result of entering these sites.</li>
          <li>Personal information of customers requested for product and service sales/application via the company's website and existing customers' information in the company's system is not shared with any third parties without customer consent. Customer information may only be shared with legal institutions and authorities with the authorization to access customer information if requested.</li>
          <li>Access by third parties to the information entered by our customers on the website is prevented. Necessary measures have been taken to keep the personal information of our customers confidential by maintaining the company's system and internet infrastructure at the most reliable level.</li>
          <li>Our company, when deemed necessary, receives support services from different organizations and ensures that these organizations act in accordance with the company's privacy standards and conditions. Our company ensures that data processors, with whom contracts are arranged, give importance to information security at least as much as itself and act with the awareness of joint responsibility. Data processors process personal data only in accordance with Ziraat Teknoloji's instructions, within the framework of the contract concluded with Ziraat Teknoloji, and in compliance with the legislation.</li>
          <li>The copyright, registered trademark, patent, intellectual property, and other proprietary rights of information, material, and their organization on our company's website, except for materials owned by third parties, belong to Ziraat Teknoloji Inc.</li>
        </ul>
        
        <p>Entering our company's website by customers/visitors implies acceptance of the conditions and provisions stated in this legal notice. Our company reserves the right to change and update the conditions and provisions in this legal notice without prior notice.</p>`,
        date: "September 5, 2023"
      },
      {
        name: "yasal-bilgilendirme",
        title: "Legal Disclaimer",
        content: `<div className="text-box">
        <div className="row">
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Trade Name</span>
              <span className="text">Ziraat Teknoloji Inc.</span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Trade Registry No</span>
              <span className="text">823656</span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">MERSIS No</span>
              <span className="text">0388029350300012</span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Business Headquarters</span>
              <span className="text">
                Istanbul<br />
                Yildiz Technical University Davutpasa Campus Technology Development Zone C2 Block 34220 Esenler
              </span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Phone</span>
              <span className="text"><a href="tel:+902124846000">(212) 484 6000</a></span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Fax</span>
              <span className="text"><a href="tel:+902124837378">(212) 483 7378</a></span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Capital</span>
              <span className="text">Paid-in capital 55,000,000 TL</span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Our Website</span>
              <span className="text"><a href="https://www.ziraatteknoloji.com">www.ziraatteknoloji.com</a></span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">IP Address</span>
              <span className="text">194.24.224.3</span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Tax Office</span>
              <span className="text">Esenler Tax Office</span>
            </div>
          </div>
          <div className="col-12 col-sm-4 mb-4">
            <div className="text-box__item pb-4">
              <span className="title">Tax ID</span>
              <span className="text">3880293503</span>
            </div>
          </div>
        </div>
      </div>`,
        date: "September 05, 2023"
      },
      {
        name: "ust-yonetim",
        title: "Ziraat Teknoloji Management",
        content: `<h2>Board of Directors</h2>
        <div className="management-box">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-circle"></span>
                </div>
                <div className="content">
                  <span className="title">Emrah GÜNDÜZ</span>
                  <span className="text">Chairman of the Board</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Bayram TUZCU</span>
                  <span className="text">Vice Chairman of the Board and General Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Onur Osman HALICI</span>
                  <span className="text">Board Member</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mehmet TARCAN</span>
                  <span className="text">Board Member</span>
                </div>
              </div>
            </div>
               <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Özgür ARIK</span>
                  <span className="text">Board Member</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <h2>Top Management</h2>
        <div className="management-box">
          <div className="row">
            <div className="col-12 col-sm-12">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Bayram TUZCU</span>
                  <span className="text">General Manager</span>
                </div>
              </div>
            </div>
          </div>
        </div>
        
        <h2>Group Managers</h2>
        <div className="management-box">
          <div className="row">
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mehmet Can ÖNAL</span>
                  <span className="text">IT Infrastructure Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mehmet Soner ALTAŞ</span>
                  <span className="text">Digital Banking Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Uğur TEMİR</span>
                  <span className="text">Information Security and Support Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mahmut Sinan SARIKAYA</span>
                  <span className="text">Analytical Banking Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Ahmet ŞEKER</span>
                  <span className="text">Banking Applications Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Tayfun DURAN</span>
                  <span className="text">Program Management Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Davut GÜNGÖR</span>
                  <span className="text">Credit Applications Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Mahmut KUŞ</span>
                  <span className="text">IT Architecture Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Oğuz KAYA</span>
                  <span className="text">Participation Banking Group Manager</span>
                </div>
              </div>
            </div>
            <div className="col-12 col-sm-6">
              <div className="management-box__item">
                <div className="icon">
                  <span className="svg svg--user-1"></span>
                </div>
                <div className="content">
                  <span className="title">Muhammed Cuma TAHİROĞLU</span>
                  <span className="text">Participation Banking Infrastructure and Program Management Group Manager</span>
                </div>
              </div>
            </div>
          </div>
        </div>`,
        date: "September 05, 2023"
      },
      {
        name: "iletisim",
        title: "Contact",
        content: ` <div class="contact-page__maps">
        <a href="https://maps.app.goo.gl/8nfe498GGxuVX28E9" target="_blank"><img src="_assets/img/google-maps.png" alt=""  /></a>
    </div>
      
      <div className="row gx-6">
        <div className="col-12 col-sm-6">
          <div className="contact-page__address">
            <div className="contact-page__address__item">
              <h2>Istanbul - Anatolian Side Istanbul Finance Center</h2>
              <p>Ziraat Bank General Directorate Block A 5th Floor Finanskent Mah. Finans Cad. No:44/A Ümraniye / Istanbul</p>
              <h3>Phone</h3>
              <p><a href="tel:+902124846000">+90 212 484 6000</a></p>
              <h3>Fax</h3>
              <p><a href="tel:+902124837378">+90 212 483 7378</a></p>
              
            </div>
            <div className="contact-page__address__item">
              <h2>Ankara</h2>
              <p>Ziraat Bank Education Facilities, Varlik Mah. Fatih Sultan Mehmet Bulvari No:10 Floor:3 Yenimahalle / Ankara</p>
              <h3>Phone</h3>
              <p><a href="tel:+902124846000">+90 212 484 6000</a></p>
              <h3>Fax</h3>
              <p><a href="tel:+902124837378">+90 212 483 7378</a></p>
              
            </div>
          </div>
        </div>
        <div className="col-12 col-sm-6">
          <div className="contact-page__address">
            <div className="contact-page__address__item">
              <h2>Istanbul - European Side YTU Technopark</h2>
              <p>Yildiz Technical University Davutpasa Campus Technology Development Zone C2 Block 34220 Esenler-Istanbul</p>
              <h3>Phone</h3>
              <p><a href="tel:+902124846000">+90 212 484 6000</a></p>
              <h3>Fax</h3>
              <p><a href="tel:+902124837378">+90 212 483 7378</a></p>
              
            </div>
        
          </div>
        </div>
      </div>
      `,
        date: "05 Eylül 2023",
      },

    ],
    commonWords: [
      { key: "all", label: "View All" },
      { key: "home-page", label: "Home Page" },
      { key: "search", label: "Search" },
      { key: "ziraat-search", label: "Search in Ziraat Teknoloji Website" },
      { key: "copyright", label: "Ziraat Teknoloji is a member of 'Ziraat Financial Group' ©2024" },
      { key: "ziraat-teknoloji-kariyer-olanaklari", label: "Career Opportunities at \n Ziraat Teknoloji" },
      { key: "calisan", label: "EMPLOYEES" },
      { key: "ulke", label: "COUNTRIES" },
      { key: "kurum", label: "ORGANIZATIONS" },
      { key: "yerleske", label: "LOCATIONS" },
      { key: "read-more", label: "Read More" },
      { key: "follow-us", label: "Follow Us" },
      { key: "location", label: "Location" },
      { key: "view", label: "View" },
      { key: "enter-search-word", label: "Enter search word" },
      { key: "please-enter-min-char", label: "Please enter at least 3 characters" },
    ]

  }

}