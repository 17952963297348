import { useContext } from 'react';
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import parse from 'html-react-parser';


export const Contact = () => {

  const appContext = useContext(Context)
  const contactData = appContext.data.subPages.find(subPage => subPage.name === "iletisim");

  const ContactForm = () => {
    return <form action="#" className="form contact-form">

      <h2 className="mb-3 mb-sm-1">İletişim Formu</h2>
      <p>Lütfen tüm alanları eksiksiz bir şekilde doldurunuz.</p>
      <div className="form-group">
        <label htmlFor="name-surname" className="form-label">Ad Soyad</label>
        <input type="text" className="form-control only-letter required" id="name-surname" name="name-surname" placeholder="Adınızı ve soyadınız giriniz" />
      </div>
      <div className="form-group">
        <label htmlFor="email" className="form-label">E-posta</label>
        <input type="email" className="form-control required" id="email" name="email" placeholder="E-posta adresinizi giriniz" />
      </div>
      <div className="form-group">
        <label htmlFor="phone" className="form-label">Cep Telefonu</label>
        <input type="tel" className="form-control telephone only-number required" id="gsm" name="gsm" placeholder="Cep telefonu numaranızı giriniz" />
      </div>
      <div className="form-group">
        <label htmlFor="message" className="form-label">Mesaj</label>
        <textarea className="form-control required" name="message" id="message" rows={3} placeholder="Mesajınızı yazınız"></textarea>
      </div>
      <div className="form-group">
        <label htmlFor="captcha" className="form-label">Güvenlik Kodu</label>
        <div className="row gx-2">
          <div className="col-6 col-sm-8">
            <input type="text" className="form-control required" id="captcha" name="captcha" placeholder="Yandaki kodu giriniz" />
          </div>
          <div className="col-6 col-sm-4">
            <div className="captcha-content">
              <img src="_assets/img/captcha.png" alt="" className="captcha-content__img" />
            </div>
          </div>
        </div>
      </div>
      <div className="form-group form-check">
        <input type="checkbox" className="checkbox form-check-input required" id="kvkk" name="kvkk" />
        <label className="form-check-label" htmlFor="kvkk">Kişisel Verilerin Korunması Kanunu uyarınca gerçekleştirilen ilgili <a href="#">bilgilendirmeyi</a> okudum.</label>
      </div>
      <div className="form-group form-bottom">
        <div className="row">
          <div className="col">
            <button type="reset" className="btn btn-remove">Temizle</button>
          </div>
          <div className="col">
            <button type="submit" className="btn btn-send">Gönder</button>
          </div>
        </div>
      </div>
    </form>
  }

  return <PageWrapper>
    <div className="box">
      <div className="breadcrumb mb-4 px-0">
        <div>
          <ul>
            <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
            <li>{contactData?.title}</li>
          </ul>
        </div>
        <h1 className="section-title">{contactData?.title}</h1>
      </div>
    </div>
    <div className="contact-page">
      <div className="section">
        <div className="box">
          {parse(contactData?.content || "")}

          <p className="mail-info">
          <span className="svg svg--email"></span>
            <a href="mailto:iletisim@ziraatteknoloji.com">iletisim@ziraatteknoloji.com</a>
          </p>

        </div>
      </div>

    </div>
  </PageWrapper>
}