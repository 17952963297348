
import { Footer } from '../Footer'

export const PageWrapper = (props: any) => {

  return (
    <div className='subpage'>
      {props.children}
      <Footer />
    </div>
  )
}