import { useContext, useReducer, useRef, useState } from 'react';
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import Form from 'react-bootstrap/Form';
import Captcha from '../../Components/Captcha';
import { Button, Dropdown, DropdownButton, InputGroup, Modal } from 'react-bootstrap';
import parse from 'html-react-parser';
import FormSelect from '../../Components/Select';
import InputMask from 'react-input-mask';
import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';
import { Document, Page, pdfjs } from 'react-pdf';
// import Denemepdf from '_assets/pdf/deneme.pdf'


pdfjs.GlobalWorkerOptions.workerSrc = new URL(
  'pdfjs-dist/build/pdf.worker.min.mjs',
  import.meta.url,
).toString();

// Firebase yapılandırma bilgilerini buraya ekleyin
const firebaseConfig = {
  apiKey: "AIzaSyCb55Zgr6QOD7UuJz6cjQreBCMILaY3mLo",
  authDomain: "kariyer-gunleri-414516.firebaseapp.com",
  projectId: "kariyer-gunleri-414516",
  storageBucket: "kariyer-gunleri-414516.appspot.com",
  messagingSenderId: "189306712616",
  appId: "1:189306712616:web:c81a7729afde540855bb1d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

const getRandomChar = () => {
  const chars = 'AaBbCcDdEeFfGgHhIiJjKkLMmNnOoPpQqRrSsTtUuVvWwXxYyZz';
  return chars[Math.floor(Math.random() * chars.length)];
};

const getRandomDigit = () => {
  return Math.floor(Math.random() * 10).toString();
};

const getRandomPunctuation = () => {
  const punctuation = '-/;()@?!';
  return punctuation[Math.floor(Math.random() * punctuation.length)];
};


const generateRandomText = () => {
  let text = '';
  for (let i = 0; i < 6; i++) {
    if (i % 3 === 0) {
      text += getRandomChar();
    } else if (i % 3 === 1) {
      text += getRandomDigit();
    } else {
      text += getRandomPunctuation();
    }
  }
  return text;
};

interface IFormData {
  name: string,
  tckn: string,
  university: string
  bachelorDepartment: string
  classYear: string
  graduation: string
  applicationType: string
  email: string
  phoneNumber: string
  captcha: string
  kvkkChecked: boolean,
  consentAccepted: boolean
}




export const CareerDays = () => {

  const appContext = useContext(Context)
  const contactData = appContext.data.subPages.find(subPage => subPage.name === "iletisim");
  const kvkkData = appContext.data.subPages.find(subPage => subPage.name === "kvkk-aydinlatma-metni");
  const [showKVKKModal, setShowKVKKModal] = useState(false)
  const [showConsentModal, setShowConsentModal] = useState(false)
  const [spamText, setSpamText] = useState(generateRandomText())
  const [validated, setValidated] = useState(false);
  const [loading, setLoading] = useState(false);
  const [success, setSuccess] = useState(false);
  const [failure, setFailure] = useState(false);


  const [numPages, setNumPages] = useState<number>();
  const [pageNumber, setPageNumber] = useState<number>(1);

  const initialState: IFormData = {
    name: '',
    tckn: '',
    university: '',
    bachelorDepartment: '',
    classYear: '',
    graduation: '',
    applicationType: '',
    email: '',
    phoneNumber: '',
    captcha: '',
    kvkkChecked: false,
    consentAccepted: false
  };

  const reducer = (formState: any, action: any) => {
    switch (action.type) {
      case 'updateField':
        return { ...formState, [action.field]: action.value };
      case 'reset':
        return initialState;
      default:
        throw new Error();
    }
  };

  const [formState, dispatch] = useReducer(reducer, initialState);


  function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
    setNumPages(numPages);
  }

  const handleChange = (event: any) => {

    const { name, value, checked } = event.target;
    if (name === 'kvkkChecked' || name === 'consentAccepted')
      dispatch({ type: 'updateField', field: name, value: checked });
    else
      dispatch({ type: 'updateField', field: name, value });
  };

  const handleSubmit = async (event: any) => {
    event.preventDefault();
    event.stopPropagation();
    const form = event.currentTarget;
    
    setValidated(true);
    if (form.checkValidity() === true && formState.captcha === spamText) {
      setLoading(true)
      try {
        const basvurularCol = collection(db, 'basvurular');
        await addDoc(basvurularCol, {
          name: formState.name,
          tckn: formState.tckn,
          university: formState.university,
          bachelorDepartment: formState.bachelorDepartment,
          classYear: formState.classYear?.key,
          graduation: formState.graduation.map((item: any) => item.key).join(' - '),
          applicationType: formState.applicationType?.key,
          email: formState.email,
          phoneNumber: formState.phoneNumber,
          gpa: formState.GPA,
          osym: formState.OSYM,
          date: new Date().toLocaleString('tr-TR', { timeZone: 'UTC' })
        });
        setSuccess(true);
        setLoading(false);
      }
      catch (err) {
        setFailure(true);
        setLoading(false);
      }
    }
  };


  const KVKKModal = () => {
    const stajyerPDF = "ZİRAAT TEKNOLOJİ ANONİM ŞİRKETİ STAJYER AYDINLATMA METNİ.pdf"
    const uzunDonemStajyerPDF = "Uzun DöNEM STAJYER AYDINLATMA METNİ.pdf"
    const mezunPDF = "ZİRAAT TEKNOLOJİ ANONİM ŞİRKETİ ÇALIŞAN ADAYI AYDINLATMA METNİ.pdf"

    const getModalPdf = () => {
      if (formState.applicationType.value === 1 || formState.applicationType.value === 2) {
        return uzunDonemStajyerPDF
      }
      else if (formState.applicationType.value === 3)
        return stajyerPDF
      else return mezunPDF
    }

    return <Modal show={showKVKKModal} centered size="xl" onHide={() => setShowKVKKModal(false)} >
      <Modal.Header closeButton style={{ border: "none" }}>
      </Modal.Header>

      <Modal.Body style={{ height: "60vh", padding: "10px" }}>
        <object type="application/pdf"
          data={`_assets/pdf/${getModalPdf()}#toolbar=0`}
          style={{ width: "100%", height: "100%", border: "none;" }}>
        </object>

      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <Button variant="secondary" onClick={() => {
          setShowKVKKModal(false);
          kvkkRef.current.checked = true;
        }}>Okudum, anladım</Button>

      </Modal.Footer>
    </Modal>
  }

  const ConsentModal = () => {
    const stajyerPDF = "STAJYER ADAYI AÇIK RIZA BEYANI.pdf"
    const uzunDonemStajyerPDF = "Uzun DöNEM STAJYER AÇIK RIZA METNİ.pdf"
    const mezunPDF = "ÇALIŞAN ADAYI AÇIK RIZA BEYANI.pdf"


    const getModalPdf = () => {
      if (formState.applicationType.value === 1 || formState.applicationType.value === 2) {
        return uzunDonemStajyerPDF
      }
      else if (formState.applicationType.value === 3)
        return stajyerPDF
      else return mezunPDF
    }

    return <Modal show={showConsentModal} centered size="xl" onHide={() => setShowConsentModal(false)} >
      <Modal.Header closeButton style={{ border: "none" }}>
      </Modal.Header>

      <Modal.Body style={{ height: "60vh", padding: "10px" }}>
        <object type="application/pdf"
          data={`_assets/pdf/${getModalPdf()}#toolbar=0`}
          style={{ width: "100%", height: "100%", border: "none;" }}>
        </object>
      </Modal.Body>

      <Modal.Footer style={{ border: "none" }}>
        <Button variant="secondary" onClick={() => {
          setShowConsentModal(false);
          consentRef.current.checked = true;
        }}>Kabul Ediyorum</Button>

      </Modal.Footer>
    </Modal>
  }


  const Loading = () => {
    return <div className="result-page">
      <div className="section-one box mb-48">
        <div className="d-flex flex-column flex-wrap gap-4 gap-sm-5 justify-content-center align-items-center text-center">
          <div style={{ background: "#1aa6ff", borderRadius: "50%" }}>
            <i className="svg svg--loading"></i>
          </div>
          <h1 className="title">
            Yükleniyor
          </h1>
          <h2 className="sub-title">
            Lütfen Bekleyiniz
          </h2>
        </div>
      </div>
    </div>
  }

  const Result = () => {
    return <div className="result-page">
      <div className="section-one box mb-48">
        <div className="d-flex flex-column flex-wrap gap-4 gap-sm-5 justify-content-center align-items-center text-center">
          <div style={{ background: "#05d105", borderRadius: "50%" }}>
            <i className="svg svg--check"></i>
          </div>
          <h1 className="title">
            Başvuru İşlemi Başarılı
          </h1>
          <h2 className="sub-title">
            Form başarıyla gönderildi. İlginiz için teşekkür ederiz.
          </h2>
        </div>
      </div>
    </div>
  }

  const ResultError = () => {
    return <div className="result-page">
      <div className="section-one box mb-48">
        <div className="d-flex flex-column flex-wrap gap-4 gap-sm-5 justify-content-center align-items-center text-center">
          <div style={{ background: "#e10514", borderRadius: "50%" }}>
            <i className="svg svg--cancel"></i>
          </div>
          <h1 className="title">
            Başvuru İşlemi Başarısız
          </h1>
          <h2 className="sub-title">
            Form gönderilirken bir hata oluştu, tekrar denemek için tıklayınız.
          </h2>
        </div>
      </div>
    </div>
  }
  const formRef = useRef<any>([]);
  const kvkkRef = useRef<any>(undefined);
  const consentRef = useRef<any>(undefined);

  const InputTCKN = () => (
    <InputMask mask=" 99999999999" onChange={handleChange}>
      {(inputProps: any) => <Form.Control required onChange={handleChange} name="tckn" type="text" placeholder="Kimlik Numaranızı Giriniz" />}
    </InputMask>)

  const InputTel = () => (
    <InputMask mask=" 999 999 99 99" onChange={handleChange}>
      {(inputProps: any) => <Form.Control {...inputProps} required name="phoneNumber" type="phone" style={{ borderTopRightRadius: "32px", borderBottomRightRadius: "32px" }}
        placeholder="Cep Telefonu numaranızı giriniz" />}
    </InputMask>)

  const ContactForm = () => {
    return <Form className="form contact-form row" ref={formRef} noValidate validated={validated} onSubmit={handleSubmit} onResetCapture={() => console.log("reset")}>
      <h2 className="mb-3 mb-sm-1">ZİRAAT TEKNOLOJİ ÖN BAŞVURU FORMU </h2>

      <Form.Group className='mb-2 col-lg-6' controlId="controlName">
        <Form.Label>Ad Soyad</Form.Label>
        <Form.Control required onChange={handleChange} name="name" type="text" placeholder="Adınızı ve soyadınız giriniz" />
        <Form.Control.Feedback type="invalid">
          Bu alan zorunludur
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-2 col-lg-6' controlId="controlName">
        <Form.Label>TCKN</Form.Label>
        {InputTCKN()}
        <Form.Control.Feedback type="invalid">
          Bu alan zorunludur
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className='mb-2 col-lg-6' controlId="controlUniversity">
        <Form.Label>Üniversite</Form.Label>
        <Form.Control required onChange={handleChange} name="university" type="text" placeholder="Üniversitenizi giriniz" />
        <Form.Control.Feedback type="invalid">
          Bu alan zorunludur
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 col-lg-6" controlId="controlBachelorDepartment">
        <Form.Label>Lisans Bölümü</Form.Label>
        <Form.Control required onChange={handleChange} name="bachelorDepartment" type="text" placeholder="Lisans Bölümünüzü giriniz" />
        <Form.Control.Feedback type="invalid">
          Bu alan zorunludur
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="form-group mb-2 col-lg-6" controlId="controlClassYear">
        <FormSelect onChange={handleChange} name="classYear" validated={validated} title="Sınıf" options={[{ key: "3. Sınıf - 1. Dönem", value: 1 }, { key: "3. Sınıf - 2. Dönem", value: 2 }, { key: "4. Sınıf - 1. Dönem", value: 3 }, { key: "4. Sınıf - 2. Dönem", value: 4 }, { key: "Mezun", value: 5 }]} />
      </Form.Group>

      <Form.Group className="form-group mb-2 col-lg-6" controlId="controlGraduation">
        <FormSelect onChange={handleChange} name="graduation" multiple title="Mezuniyet" validated={validated} options={[{ key: "2025 Ocak", value: 2 }, { key: "2025 Haziran", value: 3 },{ key: "2026 Ocak", value: 4},{ key: "2026 Haziran", value: 5 }, { key: "Mezun", value: 6 }]} />
      </Form.Group>

      <Form.Group className="form-group mb-2 " controlId="controlApplicationType">
        <FormSelect onChange={handleChange} name="applicationType" title="Başvuru Şekli"
          validated={validated}
          options={[{ key: "Uzun Dönem Staj (Haftada 4 ya da 5 tam gün)", value: 1 },
          { key: "Uzun Dönem Staj (Haftada 2 ya da 3 tam gün)", value: 2 },
          { key: "Kısa Dönem Zorunlu Yaz Stajı", value: 3 },
          { key: "Yeni Mezun İşe Alım", value: 4 }]} />
      </Form.Group>




      <Form.Group className='mb-2 col-lg-6' controlId="controlOSYM">
        <Form.Label>ÖSYM TBS (Taban Başarı Sıralaması)</Form.Label>
        <Form.Control onChange={handleChange} name="OSYM" type="text" placeholder="Lütfen Taban Başarı Sıralamanızı giriniz " />
      </Form.Group>

      <Form.Group className='mb-2 col-lg-6' controlId="controlGPA">
        <Form.Label>Not Ortalaması GPA </Form.Label>
        <Form.Control onChange={handleChange} name="GPA" type="text" placeholder="Lütfen Not Ortalamanızı giriniz" />
      </Form.Group>








      <Form.Group className="mb-2 col-lg-6" controlId="controlEmail">
        <Form.Label>E-posta</Form.Label>
        <Form.Control required onChange={handleChange} name="email" type="email" placeholder="E-posta adresinizi giriniz" />
        <Form.Control.Feedback type="invalid">
          {formState.email !== "" ? "Lütfen emaili doğru formatta giriniz" : "Bu alan zorunludur"}
        </Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-2 col-lg-6" controlId="controlPhoneNumber">
        <Form.Label>Cep Telefonu</Form.Label>
        <InputGroup >
          <InputGroup.Text id="basic-addon1">+90</InputGroup.Text>
          {InputTel()}
          <Form.Control.Feedback type="invalid">
            Bu alan zorunludur
          </Form.Control.Feedback>
        </InputGroup>
      </Form.Group>
      <Form.Group controlId="controlCaptcha">
        <Form.Label>Güvenlik Kodu</Form.Label>
        <div className="row gx-2">
          <div className="col-lg-6 mb-2">
            <Form.Control required onChange={handleChange} name="captcha" type="text" placeholder="Lütfen kodu giriniz" />
          </div>
          <div className="col-lg-6 mb-2">
            <div className="captcha-content">
              {<Captcha text={spamText} />}
              <i className="svg svg--refresh" onClick={() => setSpamText(generateRandomText())}></i>
            </div>
          </div>
        </div>
      </Form.Group>
      <Form.Group className='form-group mb-2 form-check' controlId="controlKvkkChecked" >
        <Form.Label className='form-check-label'>Kişisel Verilerin Korunması Kanunu uyarınca gerçekleştirilen ilgili <a href="#" onClick={() => setShowKVKKModal(true)}>bilgilendirmeyi</a> okudum.</Form.Label>
        <Form.Control ref={kvkkRef} required onChange={handleChange} className="checkbox form-check-input required" id="kvkkChecked" name="kvkkChecked" type="checkbox" />
        <Form.Control.Feedback type="invalid">
          Bu alan zorunludur
        </Form.Control.Feedback>
      </Form.Group>





      <Form.Group className='form-group mb-2 form-check' controlId="controlConsentAccepted" >
        <Form.Label className='form-check-label'>Kişisel verilerimin belirtilen amaçlar doğrultusunda işlenmesine  <a href="#" onClick={() => setShowConsentModal(true)}>açık rızamı</a> verdiğimi kabul ediyorum.</Form.Label>
        <Form.Control ref={consentRef} required onChange={handleChange} className="checkbox form-check-input required" id="consentAccepted" name="consentAccepted" type="checkbox" />
        <Form.Control.Feedback type="invalid">
          Bu alan zorunludur
        </Form.Control.Feedback>
      </Form.Group>



      <div className="form-group mb-2 form-bottom">
        <div className="row">
          <div className="col">
            <button type="button" className="btn btn-remove" onClick={() => {
              const formElement = formRef.current;
              formElement.reset();
              dispatch({ type: 'reset' })
            }}>
              Temizle
            </button>

          </div>
          <div className="col">
            <button type="submit" className="btn btn-send">Gönder</button>
          </div>
        </div>
      </div>

    </Form>
  }

  return <PageWrapper>
    <div className="box" style={{ maxWidth: "750px" }}>
      <div className="breadcrumb mb-3 px-0">
        <div>
          <ul>
            <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
            <li>Kariyer Günleri</li>
          </ul>
        </div>
      </div>
    </div>
    <div className="contact-page">
      <div className="box" style={{ maxWidth: "750px" }}>
        {!loading && !success && !failure && ContactForm()}
        {!loading && success && Result()}
        {!loading && failure && ResultError()}
        {loading && Loading()}
        {KVKKModal()}
        {ConsentModal()}

      </div>

    </div>
  </PageWrapper>
}