import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, Controller } from 'swiper/modules';
import { useContext, useEffect, useRef, useState } from 'react'
import { Context } from 'Context';
import { SECTION_NAMES } from '../../data';

export const OurProjectSolution = (props: any) => {
  const appContext = useContext(Context)
  const projectsData = appContext.data.sections.find(section => section.name === SECTION_NAMES.OurProjectSolution);
  const swiperRef = useRef<any>(undefined);
  const imageSwiperRef = useRef<any>(undefined);

  useEffect(() => {
    if (swiperRef && props.selectedSubSection) {

      const subSections = projectsData?.children;

      const swiperInstance = swiperRef.current.swiper;
      const activeIndex = subSections?.findIndex(subSection => subSection.name === props.selectedSubSection);

      if (activeIndex) {
        swiperInstance.slideTo(activeIndex)
      }

    }
  }, [props.selectedSubSection])

  return (
    <div className="our-project-solution section header-white">
      <div className="box">
        <h1 className="section-title white">{projectsData?.title}</h1>
        <div className="align-items-center d-flex">
          <div className="col-6 left d-none d-md-block">
            <Swiper
              threshold={0}
              ref={imageSwiperRef}
              touchReleaseOnEdges
              className='our-project-solution-swiper-1'
              slidesPerView={1}
              allowTouchMove={false}
            >
              {projectsData?.children.map((projectItem, idx) => {
                return <SwiperSlide key={`slider-1-${idx}`}>
                  <div className="left-img" style={{ backgroundImage: `url(_assets/img/${projectItem.image})` }}></div>
                </SwiperSlide>
              })}
            </Swiper>

          </div>

          <div className="col-12 col-md-6 right">
            <Swiper
              ref={swiperRef}
              threshold={0}
              touchReleaseOnEdges className='our-project-solution-swiper-2'
              slidesPerView={1}
              loop
              grabCursor
              autoHeight
              onSlideChange={(a: any) => {
                const swiperInstance = imageSwiperRef.current.swiper;

                if (a.realIndex === 0 && a.activeIndex !== 0) {
                  swiperInstance?.slideTo(0)
                } else {
                  swiperInstance?.slideTo(a.realIndex)
                }

              }}
              modules={[Navigation, Pagination, Controller]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{
                el: ".our-project-solution-swiper-pagination .swiper-pagination",
                type: "fraction",
              }}
              breakpoints={{
                991: {
                  slidesPerView: "auto"
                }
              }}

            >
              {projectsData?.children.map((projectItem, idx) => {
                const contentWithSubs = projectItem.content?.split("\list")
                const subContent = contentWithSubs?.[1]?.split("\n")
                return <SwiperSlide key={`slider-2-${idx}`} className='our-project-solution_item' >
                  <div className="our-project-solution_item-title">{projectItem.title}</div>
                  <div className="our-project-solution_item-text">{contentWithSubs?.[0]}</div>

                  {subContent &&
                    <div className='our-project-solution_item-info'><ul>
                      {subContent.map((item, idx) => {
                        return item.length > 0 ? <li key={idx}><i className="svg svg--list-icon"></i>{item}</li> : null
                      })}
                    </ul></div>}
                </SwiperSlide>
              })}
            </Swiper>
            <div className="our-project-solution-swiper-pagination pagination-wrapper">
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>

              <div className="swiper-pagination"></div>
            </div>
          </div>
        </div>
      </div>
    </div >
  )
}