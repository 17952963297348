import { useContext, useEffect, useState } from 'react'
import { Context } from 'Context';
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import AOS from 'aos';
import 'aos/dist/aos.css';


export const SuccessTogether = (props: any) => {
	const [visibleIndex, setVisibleIndex] = useState(0);


	useEffect(() => {
		const intervalId = setInterval(() => {
			setVisibleIndex(prevIndex => (prevIndex + 1) % 4);
		}, 2000);

		return () => clearInterval(intervalId);

	}, []);


	const appContext = useContext(Context)
	const successTogetherData = appContext.data.sections.find(section => section.name === "birlikte-basarmak");
	const successTogetherContent = successTogetherData?.content.split("\n")
	const successTogetherTitle = successTogetherData?.title.split(" ")


	return (
		<div className="success-together section  birlikte-basarmak" data-id="birlikte-basarmak">
			<div className="box">
				<ul className="section-title white">
					{successTogetherTitle?.map(title => {
						const isTitleChange = title.includes('\t');
						if (isTitleChange) {
							const titleChangeSpan = title.split("\t")

							return <li className="title__change">{titleChangeSpan.map((changetTitle, idx) => {
								return <span className={visibleIndex === idx ? 'visible' : ''}>{changetTitle}</span>
							})}
							</li>
						}
						else return <li>{title}</li>
					})}

				</ul>
				<div className="align-items-center d-flex flex-wrap overflow-hidden">
					<div className="row w-100">
						<div className="col-md-5 col-sm-12">
							<div className="success-together_item d-flex w-100">
								<ul className="w-100" >
									{successTogetherContent?.map((contentItem, idx) => {
										return <li key={idx}>{contentItem}</li>
									})}
								</ul>
							</div>
						</div>
						<div className="col-md-7 col-sm-12">
							<div className="row">
								{successTogetherData?.children?.map((childItem, idx) => {
									const childItemSplitTitle = childItem.title.split("\n")
									return <div className="col-md-6 col-sm-12 success-together_item d-none d-md-inline-block" key={idx}>
										<Link to={childItem.link || ""}>
											<div className="success-together_item-img" style={{ backgroundImage: `url(_assets/img/${childItem.image})` }}></div>
											<div className="success-together_item-title">
												{childItemSplitTitle.map((title, idx) => {
													return <span key={idx}>{title}<br /></span>
												})}
											</div>
										</Link>
									</div>
								})}

							</div>
						</div>
					</div>

					<Swiper
						threshold={0}
						touchReleaseOnEdges
						className="col-md-8 col-sm-12 success-together-swiper swiper success-together-mobile-slider"
						spaceBetween={16}
						autoHeight
						slidesPerView={1.3}
						slidesOffsetAfter={16}
						breakpoints={{
							991: {
								slidesPerView: "auto",
								slidesOffsetAfter: 0,
							}
						}}

					>
						{successTogetherData?.children?.map((childItem, idx) => {
							const childItemSplitTitle = childItem.title.split("\n")
							return <SwiperSlide key={idx} className='col-md-6 col-sm-12  success-together_item first-mobile-item'>
								<Link to={childItem.link || ""} >
									<div className="success-together_item-img" style={{ backgroundImage: `url(_assets/img/${childItem.image})` }}></div>

									<div className="success-together_item-title">
										{childItemSplitTitle.map((title, idx) => {
											return <span key={idx}>{title}<br /></span>
										})}
									</div>
								</Link>
							</SwiperSlide>
						})}
					</Swiper>
				</div>
			</div>
		</div>
	)
}