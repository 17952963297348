export function colorizeFoundString(text: string, query: string, color?: string) {
  const firstFound = text.toLocaleLowerCase().search(query.toLocaleLowerCase())
  if (firstFound === -1) return <span>{text}</span>


  const firstPart = text.substring(0, firstFound);
  const coloredPart = text.substring(firstFound, firstFound + query.length)
  const lastPart = text.substring(firstFound + query.length, firstFound + query.length + text.length)

  return <>
    <span>{firstPart}</span>
    <span style={{ color: color || "#e10514", fontFamily: "GothamNarrow-Medium" }}>{coloredPart}</span>
    <span>{lastPart}</span>
  </>
}
