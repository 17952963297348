
import { useContext, useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { RouteDefinitions } from "routes"
import { useCookies } from "react-cookie"
import { Modal, Form, Accordion, Button, Table } from "react-bootstrap"
import { Context } from "Context"



export const CookieSettings = (props: any) => {
  const appContext = useContext(Context);

  const [showCookieBar, setShowCookieBar] = useState(window.innerWidth <= 768 ? false : true);
  const [showCookieModal, setShowCookieModal] = useState(window.innerWidth <= 768 ? true : false);
  const [showCookieSettings, setShowCookieSettings] = useState(false);
  const [cookies, setCookie, removeCookie] = useCookies(['language']);
  const [functionalCookies, setFunctionalCookies] = useState(cookies.language === undefined ? false : true)


  const CookieButtons = () => {
    return <div className="cookie-message-and-buttons">
      <div>Sitemizde size en iyi hizmeti sunabilmek için çerez kullanılmaktadır. Detaylar için <Link to={RouteDefinitions.Cerez}>Çerez Politikamızı</Link> inceleyebilirsiniz.</div>
      <div className="buttons">
        <button onClick={() => {
          setShowCookieModal(true);
          setShowCookieSettings(true);
        }}>Ayarlar</button>
        <button onClick={() => {
          setShowCookieBar(false);
          setShowCookieModal(false);
          removeCookie('language'); //reddet
        }}>
          Reddet
        </button>
        <button
          style={{
            background: '#e10514',
            borderColor: '#e10514',
            color: 'white'
          }}
          onClick={() => {
            setShowCookieBar(false);
            setShowCookieModal(false);
            setCookie('language', appContext.userLanguage);
          }}>
          Kabul Et
        </button>
      </div>
    </div>
  }



  const CookieBar = () => {
    return showCookieBar && <div className='cookie-bar'>
      {CookieButtons()}
      <div><i className="fas fa-times" onClick={() => setShowCookieBar(false)}></i></div>
    </div>
  }


  const Settings = () => {
    return <div className="cookie-settings">

      <Accordion defaultActiveKey={window.innerWidth <= 768 ? "-1" : "0"} style={{ width: "100%" }}>
        <div className="accordion-switch-holder">
          <Accordion.Item eventKey="0" style={{ flex: "10" }}>
            <Accordion.Header>Oturum Çerezleri</Accordion.Header>
            <Accordion.Body>
              İnternet sayfaları arasında bilgi taşınması ve kullanıcı tarafından girilen bilgilerin sistemsel olarak hatırlanması gibi çeşitli özelliklerden faydalanmaya olanak sağlayan çerezlerdir ve Şirket internet sitesine ait fonksiyonların düzgün bir şekilde işleyebilmesi için gereklidir.

            </Accordion.Body>
            <Table striped bordered hover>
              <thead>
                <tr>
                  <th>Çerez Adı</th>
                  <th>Çerez Amacı</th>
                  <th>Çerez Tipi</th>
                  <th>Saklama Süresi</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                  <td>1a</td>
                  <td>Kullanıcının seçtiği dil tercihi ile çalışması amacıyla gerekli olan çerezler olup, talep edilen bir bilgi toplumu hizmetinin yerine getirilebilmesi için zorunlu olarak kullanılmaktadır. Sayfaya girildiğinde aktif olarak yerleşmektedir. Kullanıcı tarafından dil tercihi değiştirilebilmektedir.</td>
                  <td>Birinci Taraf Çerez</td>
                  <td>365 gün</td>
                </tr>

              </tbody>
            </Table>
          </Accordion.Item>
          {/* <Form.Check
            style={{ flex: "1" }}
            disabled
            type="switch"
            checked={true}
            id="disabled-custom-switch"
          /> */}
        </div>
        <div className="accordion-switch-holder">
          <Accordion.Item eventKey="1" style={{ flex: "10" }}>
            <Accordion.Header>Performans Çerezleri</Accordion.Header>
            <Accordion.Body>
              Sayfaların ziyaret edilme frekansı, olası hata iletileri, kullanıcıların ilgili sayfada harcadıkları toplam zaman ile birlikte siteyi kullanım desenleri konularında bilgi toplayan çerezlerdir ve Şirket internet sitesinin performansını arttırma amacıyla kullanılmaktadır.
            </Accordion.Body>
          </Accordion.Item>
          <span>Bu çerez türü kullanılmamaktadır</span>
        </div>
        <div className="accordion-switch-holder">
          <Accordion.Item eventKey="2" style={{ flex: "10" }}>
            <Accordion.Header>Fonksiyonel Çerezler</Accordion.Header>
            <Accordion.Body>
              Kullanıcıya kolaylık sağlanması amacıyla önceden seçili olan seçeneklerin hatırlatılmasını sağlayan çerezlerdir ve Şirket internet sitesi kapsamında kullanıcılara gelişmiş internet özellikleri sağlanmasını hedeflemektedir.
            </Accordion.Body>
          </Accordion.Item>
          <span>Bu çerez türü kullanılmamaktadır</span>

        </div>
        <div className="accordion-switch-holder">
          <Accordion.Item eventKey="3" style={{ flex: "10" }}>
            <Accordion.Header>Reklam Ve Üçüncü Taraf Çerezleri</Accordion.Header>
            <Accordion.Body>
              Üçüncü parti tedarikçilere ait çerezlerdir ve Şirket internet sitesindeki bazı fonksiyonların kullanımına ve reklam takibinin yapılmasına olanak sağlamaktadır.
            </Accordion.Body>
          </Accordion.Item>
          <span>Bu çerez türü kullanılmamaktadır</span>
        </div>
      </Accordion>
      <Button onClick={() => {
        setShowCookieModal(false);
        setShowCookieBar(false)
        functionalCookies ? setCookie('language', appContext.userLanguage) : removeCookie('language')
      }}>Kaydet</Button>
    </div>
  }


  return (cookies.language ? null : <>
    {CookieBar()}
    <Modal backdrop={"static"}
      centered
      size='lg'
      show={showCookieModal}
      id="cookie-modal"
      onHide={() => setShowCookieModal(false)}>
      <Modal.Header closeButton>
        Ziraat Teknoloji Çerez Ayarları
      </Modal.Header>
      <Modal.Body>
        {showCookieSettings ? Settings() : CookieButtons()}
      </Modal.Body>
    </Modal>
  </>
  )
}