
import { useContext, useEffect, useState } from 'react'
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context'
import { Link, useLocation, useSearchParams } from 'react-router-dom'
import { RouteDefinitions } from 'routes'
import { colorizeFoundString } from 'utils'
import { IBreadCrumb, Section } from '../../data'


export const Search = () => {
  const appContext = useContext(Context)
  const [searchParams, setSearchParams] = useSearchParams();
  const searchQuery = searchParams.get("keyword") || ""


  const [searchText, setSearchText] = useState(searchQuery)
  const [resultText, setResultText] = useState("")
  const [filteredAnnouncements, setFilteredAnouncements] = useState<any>([])
  const [filteredSections, setFilteredSections] = useState<any>([])


  useEffect(() => {
    if (searchQuery !== "") {
      setSearchText(searchQuery)
      filterData(searchQuery)
    }
  }, [searchQuery])

  const filterData = (searchQuery?: string) => {
    const resultText = searchQuery || searchText
    setResultText(resultText)
    setSearchParams({ keyword: resultText })
    const filteredAnnouncements = appContext.data.announcements.data.filter(item => item.content?.toLocaleLowerCase().includes(resultText.toLocaleLowerCase()))
    setFilteredAnouncements(filteredAnnouncements)
    const sectionContents = appContext.data.sections.flatMap(item => [
      {
        sectionName: item.name,
        sectionTitle: item.title,
        content: item.content,
      },
      ...item.children.map(child => ({
        sectionName: item.name,
        sectionTitle: item.title,
        subSectionName: child.name,
        subSectionTitle: child.title,
        content: child.content,
      }))
    ]);

    const filteredSections = sectionContents.filter((childItem: any) => childItem.content?.toLocaleLowerCase().includes(resultText.toLocaleLowerCase()))
    setFilteredSections(filteredSections)
  }


  const createSectionBreadCrumbs = (section: any) => {
    const sectionLink = { href: `/?section=${section.sectionName}`, title: section.sectionTitle }

    if (section.subSectionName) {
      const subSectionLink = { href: `/?section=${section.sectionName}&subsection=${section.subSectionName}`, title: section.subSectionTitle }
      return [sectionLink, subSectionLink]
    } else {
      return [sectionLink]
    }
  }

  const getSectionLink = (sectionItem: any) => {
    if (sectionItem.subSectionName) {
      return <Link to={`/?section=${sectionItem.sectionName}&subsection=${sectionItem.subSectionName}`}>{sectionItem.subSectionTitle}</Link>
    } else {
      return <Link to={`/?section=${sectionItem.sectionName}`}>{sectionItem.sectionTitle}</Link>
    }
  }

  const BreadCrumb = (links: IBreadCrumb[]) => {
    return <ul className="link">
      <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
      {links.map(link => {
        return <li><a href={link.href}>{link.title}</a></li>
      })}
    </ul>
  }

  const SearchInput = () => {
    return <div className="form">
      <div className="form-group">
        <input
          type="search"
          id="search"
          name="search"
          className="form-control required min3"
          value={searchText}
          onKeyDown={(key) => key.keyCode === 13 && filterData()}
          onChange={(event) => setSearchText(event.target.value)} />
        <button onClick={() => filterData()} >
          <i className="svg svg--search-icon-b"></i>
        </button>
      </div>
    </div>
  }

  const SearchResultTotal = () => {
    return appContext.userLanguage === 'en' ? <div className="search-page__text">
      Found  {filteredAnnouncements.length + filteredSections.length} results with “<strong>{resultText}</strong>’’
    </div> :
      <div className="search-page__text">
        “<strong>{resultText}</strong>’’ ile {filteredAnnouncements.length + filteredSections.length} adet sonuç bulundu.
      </div>
  }


  const SearchResult = () => {
    return <div className="search-page__list">

      {filteredSections.map((sectionItem: any) => {
        return <div className="search-page__list__item">
          <div className="title">{getSectionLink(sectionItem)}</div>
          <div className="text">{colorizeFoundString(sectionItem.content, resultText)}</div>
          {BreadCrumb(createSectionBreadCrumbs(sectionItem))}

        </div>
      })}

      {filteredAnnouncements.map((announcementItem: any) => {
        return <div className="search-page__list__item">
          <div className="title"><Link to={announcementItem.name}>{announcementItem.title}</Link></div>
          <div className="text">{colorizeFoundString(announcementItem.content.replace(/<(.|\n)*?>/g, ''), resultText)}</div>
          {BreadCrumb([{ href: RouteDefinitions.Announcements, title: appContext.data.announcements.title }])}
        </div>
      })}

    </div>
  }

  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            {BreadCrumb([{ href: RouteDefinitions.Arama, title: appContext.data.commonWords.find(item => item.key === 'search')!.label }])}
          </div>
          <h1 className="section-title">{appContext.data.commonWords.find(item => item.key === 'ziraat-search')!.label}</h1>
        </div>
      </div>
      <div className="search-page">

        <div className="section">
          <div className="box">
            {SearchInput()}
            {SearchResultTotal()}
            {SearchResult()}
          </div>
        </div>

      </div>

    </PageWrapper>

  )
}