import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import parse from 'html-react-parser';
import { useContext } from 'react';

export const Cerez = () => {
  const appContext = useContext(Context)
  const cookiesData = appContext.data.subPages.find(subPage => subPage.name === "cerez-politikasi");

  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            <ul>
              <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
              <li>{cookiesData?.title}</li>
            </ul>
          </div>
          <h1 className="section-title">{cookiesData?.title}</h1>
        </div>
      </div>
      <div className="legal-page">
        <div className="section">
          <div className="box">
            {parse(cookiesData?.content || "")}
          </div>
        </div>

      </div>

    </PageWrapper>
  )
}