
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, } from 'swiper/modules';
import classNames from 'classnames';
import { Context } from 'Context';
import { useContext, useEffect, useRef, useState } from 'react'
import SimpleBar from 'simplebar-react';

export const OurInnovations = (props: any) => {
  const appContext = useContext(Context)
  const innovationsData = appContext.data.sections.find(section => section.name === "yeniliklerimiz");
  const [activeSwipers, setActiveSwipers] = useState<any>([])
  const [activeIndex, setActiveIndex] = useState(0)
  const swiperRef = useRef<any>(undefined);

  const toggleSlideActive = (clickedIndex: number) => {

    if (activeSwipers.find((activeIndex: any) => activeIndex === clickedIndex) !== undefined) {
      setActiveSwipers(activeSwipers.filter((activeIndex: any) => activeIndex !== clickedIndex))
    }
    else
      setActiveSwipers([clickedIndex, ...activeSwipers])
  }

  useEffect(() => {
    if (swiperRef && props.selectedSubSection) {

      const subSections = innovationsData?.children;

      const swiperInstance = swiperRef.current.swiper;
      const activeIndex = subSections?.findIndex(subSection => subSection.name === props.selectedSubSection);
      
      if (activeIndex) {
        swiperInstance.slideTo(activeIndex)
        toggleSlideActive(activeIndex)
      }

    }
  }, [props.selectedSubSection])


  const showPrevButtonClass = classNames({
    "swiper-button-prev": true,
    "d-none": activeIndex === 0
  });

  const showNextButtonClass = classNames({
    "swiper-button-next": true,
    "d-none": activeIndex === (innovationsData?.children.length || 0) - 1
  });

  return (
    <div className="our-innovations section header-white yeniliklerimiz">
      <div className="box">
        <h1 className="section-title">{innovationsData?.title}</h1>

        <Swiper
          ref={swiperRef}
          threshold={0}
          onActiveIndexChange={(swiper: any) => setActiveIndex(swiper.activeIndex)}
          className='our-innovations-swiper'
          onClick={(swiper: any) => toggleSlideActive(swiper.clickedIndex)}
          spaceBetween={30}
          slidesPerView={"auto"}
          autoHeight
          slidesOffsetAfter={30}
          touchReleaseOnEdges
          modules={[Navigation]}
          loop={false}
          navigation={{
            nextEl: ".swiper-button-next",
            prevEl: ".swiper-button-prev",
          }}
          breakpoints={{
            991: {
              slidesOffsetAfter: 0,
            }
          }}

        >

          {innovationsData?.children.map((innovationItem, idx) => {

            const activeSwiperClass = classNames({
              "active": activeSwipers.find((activeIndex: any) => activeIndex === idx) !== undefined,
            });


            return <SwiperSlide className={activeSwiperClass} key={idx}>
              {({ isPrev }: any) => {
                return <>
                  <div className="swiper-slide-frontimg" style={{ backgroundImage: `url(_assets/img/${innovationItem.image})` }}>
                    <div className="swiper-slide-frontimg_description">
                      <span className="swiper-slide-frontimg_description-title">
                        {innovationItem.title}
                      </span>
                      <div className="swiper-slide-frontimg_description-icon">
                        <i className="svg svg--flip-arrow"></i>
                      </div>
                    </div>
                  </div>
                  <div className="swiper-slide-info">
                    <div className="swiper-slide-info_text">
                      <h3>{innovationItem.title}</h3>
                      {window.innerWidth <= 768 ? <SimpleBar style={{ maxHeight: "240px", width: "100%", padding: "5px 15px" }} onClick={(event) => event.stopPropagation()} >
                        {innovationItem.content}

                      </SimpleBar> :
                        <div className="swiper-slide-info_text-description">
                          {innovationItem.content}
                        </div>}

                    </div>
                    <div className="swiper-slide-info_close d-none">
                      <i className="svg svg--close"></i>
                    </div>
                  </div>
                </>
              }}
            </SwiperSlide>
          })}
          <div className={showNextButtonClass}></div>
          <div className={showPrevButtonClass}></div>

        </Swiper>

      </div>
    </div>
  )
}