import { useContext } from 'react';
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import parse from 'html-react-parser';

export const BilgiGuvenligi = () => {
  const appContext = useContext(Context)
  const informationSecurity = appContext.data.subPages.find(subPage => subPage.name === "bilgi-guvenligi-politikasi");

  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            <ul>
              <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
              <li>{informationSecurity?.title}</li>
            </ul>
          </div>
          <h1 className="section-title">{informationSecurity?.title}</h1>
        </div>
      </div>
      <div className="legal-page">
        <div className="section">
          <div className="box">
            {parse(informationSecurity?.content || "")}
          </div>
        </div>

      </div>

    </PageWrapper>
  )
}