
import { useContext, useEffect, useState } from 'react'
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import { Link } from 'react-router-dom';
import { RouteDefinitions } from '../../routes';

export const InterviewRoadmap = () => {
  const appContext = useContext(Context);

  const BreadCrumb = () => {
    return <div className="breadcrumb mb-4">
      <div>
        <ul>
          <li><Link to={RouteDefinitions.MainPage}>Ana Sayfa</Link></li>
          <li><Link to={"/#birlikte-basarmak"}>Kariyer</Link></li>
          <li>Mülakat Planı Sorgulama Sayfası</li>
        </ul>
      </div>
      <h1 className="section-title">Mülakat Planı Sorgulama Sayfası</h1>
    </div>
  }

  const enBreadCrumb = () => {
    return <div className="breadcrumb mb-4">
      <div>
        <ul>
          <li><Link to={RouteDefinitions.MainPage}>Home</Link></li>
          <li><Link to={"/#birlikte-basarmak"}>Career</Link></li>
          <li>Interview Schedule Inquiry Page</li>
        </ul>
      </div>
      <h1 className="section-title">Interview Schedule Inquiry Page</h1>
    </div>
  }




  const Content = () => {
    return <>
      <div className="box">
        {BreadCrumb()}
      </div>
      <div className="error-page">
        <div className="section-one box mb-48">

          <div className="d-flex flex-column flex-wrap gap-4 gap-sm-5 justify-content-center align-items-center text-center">
            <i className="svg svg--error2"></i>
            <h2 className="sub-title">
              Henüz sorgulama yapılabilecek Mülakat Planı bulunmamaktadır.
            </h2>
          </div>
        </div>
      </div>
    </>
  }

  const EnContent = () => {
    return (
      <>
        <div className="box">
          {enBreadCrumb()}
        </div>
        <div className="error-page">
          <div className="section-one box mb-48">
            <div className="d-flex flex-column flex-wrap gap-4 gap-sm-5 justify-content-center align-items-center text-center">
              <i className="svg svg--error2"></i>
              <h2 className="sub-title">
                There are currently no interview schedules available for inquiry.
              </h2>
            </div>
          </div>
        </div>
      </>
    );

  }



  return (<PageWrapper>

    {appContext.userLanguage === "tr" ? Content() : EnContent()}
  </PageWrapper>
  )
}