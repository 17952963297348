import { useContext } from 'react';
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import parse from 'html-react-parser';

export const UstYonetim = () => {

  const appContext = useContext(Context)
  const managementData = appContext.data.subPages.find(subPage => subPage.name === "ust-yonetim");

  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            <ul>
              <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
              <li>{managementData?.title}</li>
            </ul>
          </div>
          <h1 className="section-title">{managementData?.title}</h1>
        </div>
      </div>
      <div className="management-page">

        <div className="section">
          <div className="box">

            {parse(managementData?.content || "")}

          </div>
        </div>

      </div>
    </PageWrapper>
  )
}