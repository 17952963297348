import { Route, Routes } from 'react-router-dom';
import HomePage from 'Pages/Home';
import { JoiningZT } from 'Pages/Career/JoiningZT';
import { LifeInZT } from 'Pages/Career/LifeInZT';
import { List as Announcements } from 'Pages/Announcement/List'
import { AnnouncementDetail } from 'Pages/Announcement/Detail'
import { Standards } from 'Pages/Sub/Standards';
import { Contact } from 'Pages/Sub/Contact';
import { UstYonetim } from 'Pages/Sub/UstYonetim';
import { YasalBilgilendirme } from 'Pages/Sub/YasalBilgilendirme';
import { KVKK } from 'Pages/Sub/KVKK';
import { Cerez } from 'Pages/Sub/Cerez';
import { Gizlilik } from 'Pages/Sub/Gizlilik';
import { Search } from 'Pages/Search';
import { InterviewResults } from 'Pages/Career/InterviewResults';
import { InterviewRoadmap } from 'Pages/Career/InterviewRoadmap';
import { CareerDays } from './Pages/Sub/CareerDays';
import { CareerDaysList } from './Pages/Sub/CareerDaysList';
import { BilgiGuvenligi } from './Pages/Sub/BilgiGuvenligi';


export enum RouteDefinitions {
  MainPage = "/#anasayfa",
  JoiningZT = "/ziraat-teknolojili-olmak",
  InterviewRoadmap = "/mulakat-plani-sorgulama",
  InterviewResults = "/mulakat-sonuc-sorgulama",
  LifeInZT = "/ziraat-teknolojide-hayat",
  Announcements = "/duyurular",
  AnnouncementDetail = "/duyuru",
  Standards = "/standartlarimiz",
  InformationSecurity = "/bilgi-guvenligi-politikasi",
  Contact = "/iletisim",
  CareerDays = "/kariyer-gunleri",
  CareerDaysList = "/kariyer-gunleri-basvuranlar",
  UstYonetim = "/ust-yonetim",
  YasalBilgilendirme = "/yasal-bilgilendirme",
  KVKK = "/kvkk-aydinlatma-metni",
  Cerez = "/cerez-politikasi",
  Gizlilik = "/gizlilik-politikasi",
  Arama = "/arama"
}

export const AppRoutes = () => {

  return (
    <Routes>
      <Route path='/' element={<HomePage />} />
      <Route path={RouteDefinitions.JoiningZT} element={<JoiningZT />} />
      <Route path={RouteDefinitions.LifeInZT} element={<LifeInZT />} />
      <Route path={RouteDefinitions.InterviewResults} element={<InterviewResults />} />
      <Route path={RouteDefinitions.InterviewRoadmap} element={<InterviewRoadmap />} />
      <Route path={RouteDefinitions.Announcements} element={<Announcements />} />
      <Route path={RouteDefinitions.AnnouncementDetail} element={<AnnouncementDetail />} >
        <Route path=':duyuruName' element={<AnnouncementDetail />} />
      </Route>
      <Route path={RouteDefinitions.Arama} element={<Search />}>
        <Route path=':keyword' element={<AnnouncementDetail />} />
      </Route>
      <Route path={RouteDefinitions.Standards} element={<Standards />} />
      <Route path={RouteDefinitions.InformationSecurity} element={<BilgiGuvenligi />} />
      <Route path={RouteDefinitions.Contact} element={<Contact />} />
      <Route path={RouteDefinitions.CareerDays} element={<CareerDays />} />
      <Route path={RouteDefinitions.CareerDaysList} element={<CareerDaysList />} />
      <Route path={RouteDefinitions.UstYonetim} element={<UstYonetim />} />
      <Route path={RouteDefinitions.YasalBilgilendirme} element={<YasalBilgilendirme />} />
      <Route path={RouteDefinitions.KVKK} element={<KVKK />} />
      <Route path={RouteDefinitions.Cerez} element={<Cerez />} />
      <Route path={RouteDefinitions.Gizlilik} element={<Gizlilik />} />
    </Routes>
  );
};

