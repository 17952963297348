import { useContext } from 'react';
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import parse from 'html-react-parser';

export const YasalBilgilendirme = () => {

  const appContext = useContext(Context)
  const legalData = appContext.data.subPages.find(subPage => subPage.name === "yasal-bilgilendirme");

  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            <ul>
              <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
              <li>{legalData?.title}</li>
            </ul>
          </div>
          <h1 className="section-title">{legalData?.title}</h1>
        </div>
      </div>
      <div className="legal-page">

        <div className="section">
          <div className="box">

            {parse(legalData?.content || "")}

          </div>
        </div>

      </div>

    </PageWrapper>
  )
}