import React, { useEffect, useRef, useState } from 'react';
import { Button, Form } from 'react-bootstrap';
import './select.css'
import _find from 'lodash.find'
import classNames from 'classnames';

const FormSelect = (props: any) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOptions, setSelectedOptions] = useState<any>([]);
  const selectDropDownRef = useRef<any>(undefined)
  const dropDownRef = useRef<any>(undefined)

  useEffect(() => {
    if (isOpen)
      selectDropDownRef.current.focus();

  }, [isOpen])

  function getAllParents(element: any): any[] {
    const parents = [];

    let currentElement = element;
    while (currentElement.parentNode) {
      parents.push(currentElement.parentNode);
      currentElement = currentElement.parentNode;
    }

    return parents;
  }


  useEffect(() => {
    const handleClick = (event: any) => {
      let currentElement = event.target;
      const parentsOfCurrentElement = getAllParents(currentElement);

      if (parentsOfCurrentElement.includes(dropDownRef.current))
        setIsOpen(true)
      else if (currentElement === selectDropDownRef.current)
        isOpen ? setIsOpen(false) : setIsOpen(true);
      else
        setIsOpen(false)

    };
    document.addEventListener('click', handleClick);

    return () => {
      document.removeEventListener('click', handleClick);
    };
  }, []);



  const handleMultiOptionClick = (event: any, option: any) => {
    if (_find(selectedOptions, option)) {
      setSelectedOptions(selectedOptions.filter((item: any) => item.value !== option.value));
      props.onChange(
        {
          target:
          {
            name: props.name,
            value: selectedOptions.filter((item: any) => item.value !== option.value)
          }
        })
    } else {
      setSelectedOptions([...selectedOptions, option]);
      props.onChange(
        {
          target:
          {
            name: props.name,
            value: [...selectedOptions, option]
          }
        })
    }
  };
  const handleOptionClick = (event: any, option: any) => {


    setSelectedOptions([option]);
    props.onChange(
      {
        target:
        {
          name: props.name,
          value: option
        }
      })
    setIsOpen(false)
  };

  const getSelectedTitle = () => {
    if (selectedOptions.length === 0) {
      return 'Lütfen Seçiniz'
    }
    else if (selectedOptions.length === 1) {
      return selectedOptions[0].key
    }
    return `${selectedOptions.length} adet seçili`

  }


  const selectWrapperClass = classNames({
    "select-wrapper": true,
    "invalid": props.validated && selectedOptions.length === 0
  });


  return (
    <>
      <Form.Label>{props.title}</Form.Label>
      <div tabIndex={0} ref={selectDropDownRef} className={selectWrapperClass}>
        {getSelectedTitle()}
        <i className="svg svg--down-arrow-new"></i>
      </div>
      {isOpen && (
        <div ref={dropDownRef} className="dropdown-menu" style={{ display: "flex", flexDirection: "column", padding: "10px", width: "100%" }}  >
          {props.options.map((option: any, index: any) => {

            return props.multiple ? <Form.Check
              type="checkbox"
              id={`option-${index}`}
            >
              <Form.Check.Input
                type="checkbox"
                checked={_find(selectedOptions, option)}
                onChange={(event: any) => handleMultiOptionClick(event, option)}

              />
              <Form.Check.Label className='form-check-label-select'>
                {option.key}
              </Form.Check.Label>
            </Form.Check> : <span className="form-select-span" onClick={(event: any) => handleOptionClick(event, option)}> {option.key}</span>
          })}

        </div>
      )}
      {props.validated && selectedOptions.length === 0 && <Form.Control.Feedback type="invalid" style={{ display: props.validated ? "block" : "none" }}>
        Bu alan zorunludur
      </Form.Control.Feedback>
      }
    </>

  );
};

export default FormSelect;
