import { Link } from "react-router-dom"
import { RouteDefinitions } from "routes"
import { Swiper, SwiperSlide } from "swiper/react"
import { useContext } from "react"
import { Context } from "Context"
import { Autoplay, Navigation, Pagination } from "swiper/modules"

export const Footer = (props: any) => {
  const appContext = useContext(Context)



  const FooterTop = () => {
    return <div className="footer__top">
      <div className="footer__top__item">

        <Link to={RouteDefinitions.Announcements} style={{ display: "block", margin: "unset" }}><h4>{appContext.data.announcements.title}</h4></Link>
        <Link to={RouteDefinitions.Announcements} >{appContext.data.commonWords.find((item) => item.key === 'all')?.label} <span className="svg svg--right-arrow"></span></Link>

        <div className="announcements">
          <Swiper
            threshold={0}
            touchReleaseOnEdges className="announcements-swiper"
            loop
            slidesPerView={1}
            spaceBetween={10}
            autoHeight
            autoplay={{
              delay: 5000,
              disableOnInteraction: false,
            }}
            modules={[Autoplay, Navigation, Pagination]}

            navigation={{
              nextEl: ".swiper-button-next",
              prevEl: ".swiper-button-prev",
            }}
            pagination={{
              el: ".swiper-pagination",
              type: "fraction",
            }}
          >
            {appContext.data.announcements.data.map((announcementItem, idx) => {
              return <SwiperSlide key={idx}>
                <Link to={announcementItem.name}>{announcementItem.title}</Link>
              </SwiperSlide>
            })}
          </Swiper>
        </div>

      </div>
      <div className="footer__top__item footer__top__item--social">
        <h4 className="d-block d-lg-none">{appContext.data.commonWords.find(item => item.key === 'follow-us')!.label}</h4>
        <ul className="social-link">
          <li>
            <a href="https://www.instagram.com/ziraatteknoloji/" target="_blank">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M28 15H20C17.2386 15 15 17.2386 15 20V28C15 30.7614 17.2386 33 20 33H28C30.7614 33 33 30.7614 33 28V20C33 17.2386 30.7614 15 28 15ZM31.25 28C31.2445 29.7926 29.7926 31.2445 28 31.25H20C18.2074 31.2445 16.7555 29.7926 16.75 28V20C16.7555 18.2074 18.2074 16.7555 20 16.75H28C29.7926 16.7555 31.2445 18.2074 31.25 20V28ZM28.75 20.25C29.3023 20.25 29.75 19.8023 29.75 19.25C29.75 18.6977 29.3023 18.25 28.75 18.25C28.1977 18.25 27.75 18.6977 27.75 19.25C27.75 19.8023 28.1977 20.25 28.75 20.25ZM24 19.5C21.5147 19.5 19.5 21.5147 19.5 24C19.5 26.4853 21.5147 28.5 24 28.5C26.4853 28.5 28.5 26.4853 28.5 24C28.5027 22.8057 28.0294 21.6596 27.1849 20.8151C26.3404 19.9706 25.1943 19.4973 24 19.5ZM21.25 24C21.25 25.5188 22.4812 26.75 24 26.75C25.5188 26.75 26.75 25.5188 26.75 24C26.75 22.4812 25.5188 21.25 24 21.25C22.4812 21.25 21.25 22.4812 21.25 24Z" fill="#D9D9D9" />
                <rect x="1" y="1" width="46" height="46" rx="23" stroke="#C7C7C7" strokeOpacity="0.2" strokeWidth="2" />
              </svg>

            </a>
          </li>
          <li>
            <a href="https://www.linkedin.com/company/ziraatteknoloji/" target="_blank">
              <svg width="48" height="48" viewBox="0 0 48 48" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M16.7378 18.4819C16.7378 17.5191 17.5191 16.7378 18.4819 16.7378C19.4442 16.7378 20.2255 17.5191 20.2261 18.4819C20.2261 19.4448 19.4448 20.2424 18.4819 20.2424C17.5191 20.2424 16.7378 19.4448 16.7378 18.4819Z" fill="#D9D9D9" />
                <path d="M31.2586 31.2622V31.2616H31.2622V25.9348C31.2622 23.3289 30.7012 21.3215 27.6547 21.3215C26.1902 21.3215 25.2073 22.1251 24.8061 22.8871H24.7637V21.5647H21.8752V31.2616H24.883V26.4601C24.883 25.1958 25.1226 23.9734 26.6882 23.9734C28.2308 23.9734 28.2538 25.4161 28.2538 26.5412V31.2622H31.2586Z" fill="#D9D9D9" />
                <path d="M16.9775 21.5653H19.9889V31.2622H16.9775V21.5653Z" fill="#D9D9D9" />
                <rect x="1" y="1" width="46" height="46" rx="23" stroke="#C7C7C7" strokeOpacity="0.2" strokeWidth="2" />
              </svg>

            </a>
          </li>
        </ul>
      </div>
    </div>
  }

  const FooterBottom = () => {
    return <div className="footer__bottom">
      <div className="copyright">{appContext.data.commonWords.find((item) => item.key === "copyright")?.label} v1.0.3</div>
      <ul>
        {appContext.data.subPages.map((subPage, idx) => {
          return subPage.link ? <li key={idx}> <a href={subPage.link} target="_blank">{subPage.title}</a></li> :
            <li key={idx}>
              <Link to={"/" + subPage.name}>{subPage.title}
              </Link>
            </li>
        })}

      </ul>
    </div>
  }

  return (
    <footer className="footer section fp-auto-height header-white bilgi" data-id="bilgi">
      <div className="box box-full">
        {FooterTop()}
        {FooterBottom()}
      </div>
    </footer>
  )
}