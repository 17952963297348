import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context'
import { useContext } from 'react'
import parse from 'html-react-parser';

export const Standards = () => {
  const appContext = useContext(Context)
  const standardsData = appContext.data.subPages.find(subPage => subPage.name === "standartlarimiz");


  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            <ul>
              <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
              <li>{standardsData?.title}</li>
            </ul>
          </div>
          <h1 className="section-title">{standardsData?.title}</h1>
        </div>
      </div>

      <div className="legal-page standards">
        <div className="section">
          <div className="box">

            {parse(standardsData?.content || "")}


          </div>
        </div>

      </div>

    </PageWrapper>
  )
}