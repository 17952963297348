import React, { useRef, useEffect, useState } from 'react';

interface SpamImageProps {
  text: string;
}


const addNoise = (ctx: CanvasRenderingContext2D, canvasSize: { width: number; height: number }) => {
  const noiseDensity = 0.01; // Gürültü yoğunluğu
  ctx.fillStyle = 'rgba(0, 0, 0, 0.1)';
  for (let x = 0; x < canvasSize.width; x += 2) {
    for (let y = 0; y < canvasSize.height; y += 2) {
      if (Math.random() < noiseDensity) {
        ctx.fillRect(x, y, 1, 1);
      }
    }
  }

  // Çizgiler
  ctx.beginPath();
  ctx.strokeStyle = 'rgba(0, 0, 0, 0.05)';
  for (let i = 0; i < 50; i++) {
    const x = Math.random() * canvasSize.width;
    const y = Math.random() * canvasSize.height;
    ctx.moveTo(x, y);
    ctx.lineTo(x + Math.random() * 10, y + Math.random() * 10);
    ctx.stroke();
  }
};

const SpamImage: React.FC<SpamImageProps> = ({ text }) => {
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const [canvasSize] = useState({ width: 160, height: 40 });

  useEffect(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    // Arka planı beyaza ayarla
    ctx.fillStyle = 'white';
    ctx.fillRect(0, 0, canvasSize.width, canvasSize.height);

    // Gürültü ekle
    addNoise(ctx, canvasSize);

    // Metni rastgele konumlara çiz
    ctx.font = '24px Arial';
    ctx.fillStyle = 'red';

    const textWidth = ctx.measureText(text).width;
    const x = (canvasSize.width - textWidth) / 2;
    const y = canvasSize.height / 2 + 10; // Yüksekliğin ortasına hizala

    ctx.fillText(text, x, y);
  }, [text, canvasSize]);

  return <canvas ref={canvasRef} width={canvasSize.width} height={canvasSize.height} />;
};


export default SpamImage;
