
import { useContext } from 'react'
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context'
import { Link, useLocation } from 'react-router-dom'
import { RouteDefinitions } from 'routes'
import parse from 'html-react-parser';

export const AnnouncementDetail = () => {
  const appContext = useContext(Context)
  const currentLocation = useLocation()
  const announcementDetailData = appContext.data.announcements.data.find(section => section.name === currentLocation.pathname);

  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            <ul>
              <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
              <li><Link to={RouteDefinitions.Announcements}>{appContext.data.announcements.title}</Link></li>
            </ul>
          </div>
          <h1 className="section-title">{announcementDetailData?.title}</h1>
        </div>
      </div>
      <div className="announcements-page">

        <div className="section">
          <div className="box">

            {parse(announcementDetailData?.content || "")}

          </div>
        </div>

      </div>

    </PageWrapper>

  )
}