import React, { useState, createContext, useContext } from 'react';
import { IContent, data } from './data';
import { useCookies } from 'react-cookie';

export const dictionaryList: any = { en: data.en, tr: data.tr };

export const languageOptions: any = {
  en: 'en',
  tr: 'tr'
};


interface IContext {
  languages: string[],
  menuIsTransparent: boolean,
  userLanguage: string,
  data: IContent,
  changeLanguage?(selected: any): void,
  setMenuTransparent?(transparency: boolean): void,
}

export const Context = createContext<IContext>({
  menuIsTransparent: false,
  languages: ['tr', 'en'],
  userLanguage: 'tr',
  data: data.tr
});


export function ContextProvider({ children }: any) {
  const [cookies, setCookie, removeCookie] = useCookies(['language']);
  const [userLanguage, setUserLanguage] = useState(cookies.language || 'tr');
  const [menuIsTransparent, setMenuTransparent] = useState(false);

  const provider: any = {
    userLanguage,
    languages: ['tr', 'en'],
    data: dictionaryList[userLanguage],
    changeLanguage: (selected: string) => {
      setUserLanguage(selected);
      //window.localStorage.setItem('rcml-lang', selected);
    },
    menuIsTransparent,
    setMenuTransparent: (transparency: boolean) => {
      setMenuTransparent(transparency);
      //window.localStorage.setItem('rcml-lang', selected);
    }
  };

  return (
    <Context.Provider value={provider}>
      {children}
    </Context.Provider>
  );
};
