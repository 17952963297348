import JoiningZTPng from '../../_assets/img/ziraat-teknolojili-olmak.png'
import JobOpp1Png from '../../_assets/img/job-opp1.png'
import JobOpp2Png from '../../_assets/img/job-opp2.png'
import JobOpp3Png from '../../_assets/img/job-opp3.png'
import YoungTalentTopPng from '../../_assets/img/young-talent-top.png'
import YoungTalentBottomPng from '../../_assets/img/young-talent-bottom.png'
import { Footer } from 'Components/Footer'
import { RouteDefinitions } from 'routes'
import { Link } from 'react-router-dom'
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination, } from 'swiper/modules';
import { useContext, useEffect, useState } from 'react'
import { Context } from 'Context'
import AOS from 'aos';
import 'aos/dist/aos.css';


export const JoiningZT = () => {
  const appContext = useContext(Context);
  const [visibleIndex, setVisibleIndex] = useState(0);

  useEffect(() => {
    AOS.init();
  }, []);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setVisibleIndex(prevIndex => (prevIndex + 1) % 4);
    }, 2000);

    return () => clearInterval(intervalId);

  }, []);



  const BreadCrumb = () => {
    return <div className="breadcrumb mb-4">
      <div>
        <ul>
          <li><Link to={RouteDefinitions.MainPage}>Ana Sayfa</Link></li>
          <li><Link to={"/#birlikte-basarmak"}>Kariyer</Link></li>
          <li>Ziraat Teknolojili Olmak</li>
        </ul>
      </div>
      <h1 className="section-title">Ziraat Teknolojili Olmak</h1>
    </div>
  }

  const enBreadCrumb = () => {
    return (
      <div className="breadcrumb mb-4">
        <div>
          <ul>
            <li><Link to={RouteDefinitions.MainPage}>Home</Link></li>
            <li><Link to={"/#birlikte-basarmak"}>Career</Link></li>
            <li>Joining Ziraat Teknoloji</li>
          </ul>
        </div>
        <h1 className="section-title">Joining Ziraat Teknoloji</h1>
      </div>
    );
  }

  const Content = () => {

    return <div className="being-ziraat-technology">
      <div className="section-one box box-full">
        <div className="d-flex flex-wrap">
          <div className="col-12 col-md-6 left order-1 order-md-0">
            <div className="title" data-aos="fade-right" data-aos-delay="500">
              <span>Birlikte</span>
              <div className="title__change">
                <span className={visibleIndex === 0 ? 'visible' : ''}>Başarmanın</span>
                <span className={visibleIndex === 1 ? 'visible' : ''}>Yürümenin</span>
                <span className={visibleIndex === 2 ? 'visible' : ''}>Üretmenin</span>
                <span className={visibleIndex === 3 ? 'visible' : ''}>Gelişmenin</span>
              </div>
              <span>Gücüne</span>
              <span>İnanıyoruz</span>
            </div>
            <p className="text-description" data-aos="fade-up">
              Hedeflerimize ulaşmakta, bizleri başarıya taşıyan ana güç çalışanlarımızdır. Ziraat Teknoloji, en gelişmiş teknolojiyi kullanabilen, enerjik, dayanışma ruhuna sahip,
              müşteri ve hizmet bilincinde olan, işe ve kuruma bağlı, sürekli öğrenmeye ve gelişime açık, çalıştığı kurumun geleceğiyle kendi yarınlarını bütünleştirebilen ve yaptığı
              her çalışmayla katma değer yaratan çalışanları ile bir ailedir.
            </p>
          </div>
          <div className="col-12 col-md-6 right order-0 order-md-0" data-aos="fade-left" data-aos-delay="400"  >
            <img src={JoiningZTPng} />
          </div>
        </div>
      </div>

      <div className="section-two">
        <div className="box box-full">
          <h3 className="sub-title" data-aos="fade-in">İşe Alım ve Uyum Sürecimiz</h3>
          <div className="recruitment position-relative">


            <Swiper
              threshold={0}
              touchReleaseOnEdges className='recruitment-swiper'
              spaceBetween={0}
              slidesPerView={3}
              slidesPerGroup={3}
              direction='vertical'
              autoHeight={false}
              simulateTouch={false}
              allowTouchMove={false}
              modules={[Navigation, Pagination]}
              navigation={{
                nextEl: ".swiper-button-next",
                prevEl: ".swiper-button-prev",
              }}
              pagination={{
                el: ".swiper-pagination--mobile",
                type: "fraction",
              }}
              breakpoints={{
                991: {
                  slidesPerView: 3,
                  spaceBetween: 35,
                  slidesPerGroup: 3,
                  direction: 'horizontal',
                  autoHeight: false,
                  pagination: {
                    el: ".swiper-pagination--light",
                    clickable: true,
                    type: "bullets",
                  },
                  simulateTouch: true,
                  allowTouchMove: true,
                },
                1200: {
                  slidesPerView: 4,
                  spaceBetween: 45,
                  slidesPerGroup: 4,
                  direction: 'horizontal',
                  autoHeight: false,
                  pagination: {
                    el: ".swiper-pagination--light",
                    clickable: true,
                    type: "bullets",
                  },
                  simulateTouch: true,
                  allowTouchMove: true,
                }
              }}
            >
              <SwiperSlide >
                <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="100">
                  <div className="swiper-slide__item--icon">
                    <i className="svg svg--user-apply"></i>
                  </div>
                  <span className="swiper-slide__item--title">
                    İlan ve<br />Başvuru
                  </span>
                  <span className="swiper-slide__item--number">1</span>
                </div>
              </SwiperSlide>

              <SwiperSlide >
                <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="200">
                  <div className="swiper-slide__item--icon">
                    <i className="svg svg--online-review"></i>
                  </div>
                  <span className="swiper-slide__item--title">
                    Online Teknik<br />Değerlendirme
                  </span>
                  <span className="swiper-slide__item--number">2</span>
                </div>
              </SwiperSlide>

              <SwiperSlide >
                <div className="swiper-slide">
                  <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="300">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--first-review"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Ön<br />Değerlendirme
                    </span>
                    <span className="swiper-slide__item--number">3</span>
                  </div>
                </div>
              </SwiperSlide>

              <SwiperSlide >
                <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="400">
                  <div className="swiper-slide__item--icon">
                    <i className="svg svg--talent-review"></i>
                  </div>
                  <span className="swiper-slide__item--title">
                    Yetkinlik Bazlı<br />Değerlendirme
                  </span>
                  <span className="swiper-slide__item--number">4</span>
                </div>
              </SwiperSlide>

              <SwiperSlide >
                <div className="swiper-slide__item">
                  <div className="swiper-slide__item--icon">
                    <i className="svg svg--meeting"></i>
                  </div>
                  <span className="swiper-slide__item--title">
                    Mülakat<br />&nbsp;
                  </span>
                  <span className="swiper-slide__item--number">5</span>
                </div>
              </SwiperSlide>

              <SwiperSlide >
                <div className="swiper-slide__item">
                  <div className="swiper-slide__item--icon">
                    <i className="svg svg--offer"></i>
                  </div>
                  <span className="swiper-slide__item--title">
                    Teklif<br />&nbsp;
                  </span>
                  <span className="swiper-slide__item--number">6</span>
                </div>
              </SwiperSlide>

              <SwiperSlide >
                <div className="swiper-slide__item">
                  <div className="swiper-slide__item--icon">
                    <i className="svg svg--starting-work"></i>
                  </div>
                  <span className="swiper-slide__item--title">
                    İşe<br />Başlama
                  </span>
                  <span className="swiper-slide__item--number">7</span>
                </div>
              </SwiperSlide>
              <SwiperSlide >
                <div className="swiper-slide__item">
                  <div className="swiper-slide__item--icon">
                    <i className="svg svg--orientation-trainings"></i>
                  </div>
                  <span className="swiper-slide__item--title">
                    Oryantasyon<br />
                    Eğitimleri
                  </span>
                  <span className="swiper-slide__item--number">8</span>
                </div>
              </SwiperSlide>
            </Swiper>


            <div className="recruitment-navigation pagination-wrapper">
              <div className="swiper-button-next"></div>
              <div className="swiper-button-prev"></div>
              <div className="swiper-pagination swiper-pagination--mobile"></div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-three" id="is-olanaklarimiz">
        <div className="box box-full">
          <h3 className="sub-title" data-aos="fade-in">İş Olanaklarımız</h3>
          <div className="job-opp position-relative">

            <Swiper
              threshold={0}
              touchReleaseOnEdges
              className='job-opp-swiper'
              slidesPerView={1.4}
              autoHeight
              spaceBetween={0}
              slidesOffsetBefore={16}
              breakpoints={{
                768: {
                  slidesPerView: 1.6,
                  spaceBetween: 20
                },
                991: {
                  slidesPerView: 3,
                  slidesOffsetBefore: 0,
                  spaceBetween: 30
                }
              }}
            >
              <SwiperSlide >
                <a href="https://ziraatteknoloji.hrpeak.com/jobs" target="_blank" className="swiper-slide" data-aos="fade-in" data-aos-delay="100">
                  <img src={JobOpp1Png} />
                  <span className="swiper-slide_title">
                    Açık<br />Pozisyonlar
                  </span>
                </a>
              </SwiperSlide>
              <SwiperSlide >
                <Link to={RouteDefinitions.InterviewRoadmap} className="swiper-slide" data-aos="fade-in" data-aos-delay="200" >
                  <img src={JobOpp2Png} />
                  <span className="swiper-slide_title">
                    Mülakat Planı<br />Sorgulama
                  </span>
                </Link>
              </SwiperSlide>
              <SwiperSlide >
                <Link to={RouteDefinitions.InterviewResults} className="swiper-slide" data-aos="fade-in" data-aos-delay="300" >
                  <img src={JobOpp3Png} />
                  <span className="swiper-slide_title">
                    Mülakat Sonuç<br />Sorgulama
                  </span>
                </Link>
              </SwiperSlide>

            </Swiper>

          </div>
        </div>
      </div>

      <div className="section-four">
        <div className="box box-full">
          <h3 className="sub-title" data-aos="fade-in">Genç Yetenekler İçin Staj Programlarımız</h3>
          <div className="d-flex flex-wrap mb-48">
            <div className="col-12 col-md-6 image order-0 order-md-0">
              <img src={YoungTalentBottomPng} data-aos="fade-right" data-aos-delay="500" />
            </div>
            <div className="col-12 col-md-6 description right order-1 order-md-0" data-aos="fade-left" data-aos-delay="400">
              <div className="title">Ziraat Teknoloji Kampüsü</div>
              <div className="text-decoration">
                Uzun dönem staj programımızla üniversite son sınıf öğrencisi genç yeteneklere eğitim sürecinde yarı zamanlı olarak çalışma imkanı sunuyoruz. Uzun soluklu bu kariyer yolculuğuna katılan arkadaşlarımız alanında uzman mühendislerle çalışarak deneyim kazanırken, alacakları eğitimlerle teknik bilgi ve becerilerini de güçlendirecekler. Ayrıca süreci başarıyla tamamlayan adaylar Ziraat Teknoloji ailesine katılma şansı elde edecekler.
              </div>
            </div>
          </div>
          <div className="d-flex flex-wrap">
            <div className="col-12 col-md-6 description left order-1 order-lg-0 mt-3 mt-lg-0" data-aos="fade-right" data-aos-delay="500">
              <div className="title">Kısa Dönem Staj veya Zorunlu Staj</div>
              <div className="text-decoration">
                Her yıl yaz döneminde düzenlenen staj programımız, teknolojiye gönül vermiş ve bu alanda çalışmayı hedef edinmiş genç yeteneklere Ziraat Teknoloji'yi tanıma ve iş alanları üzerinde bilgi toplama olanağı sağlarken, teorik bilgilerini pratikle geliştirme fırsatı sunmaktadır.
              </div>
              <div className="info-block">
                <i className="svg svg--red-info"></i>
                <div className="text-decoration">
                  Staj programımız yalnızca ilgili bölümlerden zorunlu yaz stajı olan öğrencileri kapsamaktadır.
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6 image order-0 order-md-0">
              <img src={YoungTalentTopPng} data-aos="fade-left" data-aos-delay="400" />
            </div>
          </div>
        </div>

      </div>

    </div>
  }

  const enContent = () => {
    return (
      <div className="being-ziraat-technology">
        <div className="section-one box box-full">
          <div className="d-flex flex-wrap">
            <div className="col-12 col-md-6 left order-1 order-md-0">
              <div className="title" data-aos="fade-right" data-aos-delay="500">
                <span>We Believe in</span>
                <span>The Power of</span>
                <div className="title__change">
                  <span className={visibleIndex === 0 ? 'visible' : ''}>Achieving</span>
                  <span className={visibleIndex === 1 ? 'visible' : ''}>Performing</span>
                  <span className={visibleIndex === 2 ? 'visible' : ''}>Producing</span>
                  <span className={visibleIndex === 3 ? 'visible' : ''}>Improving</span>
                </div>
                <span>Together</span>
              </div>
              <p className="text-description" data-aos="fade-up">
                Our main strength in reaching our goals is our employees. Ziraat Teknoloji is a family with employees who can use the most advanced technology, have energy, solidarity spirit,
                customer and service consciousness, dedicated to their work and institution, open to continuous learning and development, and can integrate their own futures with the future of the institution they work for, creating added value with every work they do.
              </p>
            </div>
            <div className="col-12 col-md-6 right order-0 order-md-0">
              <img src={JoiningZTPng} data-aos="fade-left" data-aos-delay="400" />
            </div>
          </div>
        </div>

        <div className="section-two">
          <div className="box box-full">
            <h3 className="sub-title" data-aos="fade-in">Recruitment and Onboarding Process</h3>
            <div className="recruitment position-relative">
              <Swiper
                threshold={0}
                touchReleaseOnEdges className='recruitment-swiper'
                spaceBetween={0}
                slidesPerView={3}
                slidesPerGroup={3}
                direction='vertical'
                autoHeight={false}
                simulateTouch={false}
                allowTouchMove={false}
                modules={[Navigation, Pagination]}
                navigation={{
                  nextEl: ".swiper-button-next",
                  prevEl: ".swiper-button-prev",
                }}
                pagination={{
                  el: ".swiper-pagination--mobile",
                  type: "fraction",
                }}
                breakpoints={{
                  991: {
                    slidesPerView: 3,
                    spaceBetween: 35,
                    slidesPerGroup: 3,
                    direction: 'horizontal',
                    autoHeight: false,
                    pagination: {
                      el: ".swiper-pagination--light",
                      clickable: true,
                      type: "bullets",
                    },
                    simulateTouch: true,
                    allowTouchMove: true,
                  },
                  1200: {
                    slidesPerView: 4,
                    spaceBetween: 45,
                    slidesPerGroup: 4,
                    direction: 'horizontal',
                    autoHeight: false,
                    pagination: {
                      el: ".swiper-pagination--light",
                      clickable: true,
                      type: "bullets",
                    },
                    simulateTouch: true,
                    allowTouchMove: true,
                  }
                }}
              >
                <SwiperSlide>
                  <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="100">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--user-apply"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Advertisement and<br />Application
                    </span>
                    <span className="swiper-slide__item--number">1</span>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="200">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--online-review"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Online Technical<br />Assessment
                    </span>
                    <span className="swiper-slide__item--number">2</span>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide">
                    <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="300">
                      <div className="swiper-slide__item--icon">
                        <i className="svg svg--first-review"></i>
                      </div>
                      <span className="swiper-slide__item--title">
                        Initial<br />Assessment
                      </span>
                      <span className="swiper-slide__item--number">3</span>
                    </div>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide__item" data-aos="fade-in" data-aos-delay="400">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--talent-review"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Competency-Based<br />Assessment
                    </span>
                    <span className="swiper-slide__item--number">4</span>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide__item">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--meeting"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Interview<br />&nbsp;
                    </span>
                    <span className="swiper-slide__item--number">5</span>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide__item">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--offer"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Offer<br />&nbsp;
                    </span>
                    <span className="swiper-slide__item--number">6</span>
                  </div>
                </SwiperSlide>

                <SwiperSlide>
                  <div className="swiper-slide__item">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--starting-work"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Starting<br />Work
                    </span>
                    <span className="swiper-slide__item--number">7</span>
                  </div>
                </SwiperSlide>
                <SwiperSlide>
                  <div className="swiper-slide__item">
                    <div className="swiper-slide__item--icon">
                      <i className="svg svg--orientation-trainings"></i>
                    </div>
                    <span className="swiper-slide__item--title">
                      Orientation<br />
                      Trainings
                    </span>
                    <span className="swiper-slide__item--number">8</span>
                  </div>
                </SwiperSlide>
              </Swiper>
              <div className="recruitment-navigation pagination-wrapper">
                <div className="swiper-button-next"></div>
                <div className="swiper-button-prev"></div>
                <div className="swiper-pagination swiper-pagination--mobile"></div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-three" id="job-opportunities">
          <div className="box box-full">
            <h3 className="sub-title" data-aos="fade-in">Job Opportunities</h3>
            <div className="job-opp position-relative">
              <Swiper
                threshold={0}
                touchReleaseOnEdges className='job-opp-swiper'
                slidesPerView={1.4}
                autoHeight
                spaceBetween={0}
                slidesOffsetBefore={16}
                breakpoints={{
                  768: {
                    slidesPerView: 1.6,
                    spaceBetween: 20
                  },
                  991: {
                    slidesPerView: 3,
                    slidesOffsetBefore: 0,
                    spaceBetween: 30

                  }
                }}
              >
                <SwiperSlide>
                  <a href="https://ziraatteknoloji.hrpeak.com/jobs" target="_blank" className="swiper-slide" data-aos="fade-in" data-aos-delay="100">
                    <img src={JobOpp1Png} />
                    <span className="swiper-slide_title">
                      Open<br />Positions
                    </span>
                  </a>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to={RouteDefinitions.InterviewRoadmap} className="swiper-slide" data-aos="fade-in" data-aos-delay="200" >
                    <img src={JobOpp2Png} />
                    <span className="swiper-slide_title">
                      Interview<br />Plan Inquiry
                    </span>
                  </Link>
                </SwiperSlide>
                <SwiperSlide>
                  <Link to={RouteDefinitions.InterviewResults} className="swiper-slide" data-aos="fade-in" data-aos-delay="300" >
                    <img src={JobOpp3Png} />
                    <span className="swiper-slide_title">
                      Interview Result<br />Inquiry
                    </span>
                  </Link>
                </SwiperSlide>
              </Swiper>
            </div>
          </div>
        </div>

        <div className="section-four">
          <div className="box box-full">
            <h3 className="sub-title" data-aos="fade-in">Internship Programs for Young Talents</h3>
            <div className="d-flex flex-wrap mb-48">
              <div className="col-12 col-md-6 image order-0 order-md-0">
                <img src={YoungTalentBottomPng} data-aos="fade-right" data-aos-delay="500" />
              </div>
              <div className="col-12 col-md-6 description right order-1 order-md-0" data-aos="fade-left" data-aos-delay="400">
                <div className="title">Ziraat Teknoloji Campus</div>
                <div className="text-decoration">
                  With our long-term internship program, we offer the opportunity for final year university students to work part-time during their education process. Our friends who participate in this long-term career journey gain experience by working with expert engineers, while strengthening their technical knowledge and skills with the training they receive. Additionally, candidates who successfully complete the process will have the chance to join the Ziraat Teknoloji family.
                </div>
              </div>
            </div>
            <div className="d-flex flex-wrap">
              <div className="col-12 col-md-6 description left order-1 order-lg-0 mt-3 mt-lg-0" data-aos="fade-right" data-aos-delay="500">
                <div className="title">Short-Term or Mandatory Internship</div>
                <div className="text-decoration">
                  Our internship program, held every summer, provides young talents dedicated to technology and aiming to work in this field with the opportunity to get to know Ziraat Teknoloji, gather information about job areas, and develop their theoretical knowledge with practical experience.
                </div>
                <div className="info-block">
                  <i className="svg svg--red-info"></i>
                  <div className="text-decoration">
                    Our internship program covers only students with mandatory summer internships in relevant departments.
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6 image order-0 order-md-0">
                <img src={YoungTalentTopPng} data-aos="fade-left" data-aos-delay="400" />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }


  return (
    <div className='subpage'>
      {appContext.userLanguage === "tr" ? BreadCrumb() : enBreadCrumb()}
      {appContext.userLanguage === "tr" ? Content() : enContent()}
      <Footer />
    </div>
  )
}