
import { useContext } from 'react';
import { Footer } from 'Components/Footer'
import { Context } from 'Context';
import { Link } from 'react-router-dom';
import { PageWrapper } from 'Components/PageWrapper';

export const List = () => {
  const appContext = useContext(Context)
  return (
    <PageWrapper>
      <div className="box">
        <div className="breadcrumb mb-4 px-0">
          <div>
            <ul>
              <li><a href="/">{appContext.data.commonWords.find(item => item.key === 'home-page')!.label}</a></li>
              <li>{appContext.data.announcements.title}</li>
            </ul>
          </div>
          <h1 className="section-title">{appContext.data.announcements.title}</h1>
        </div>
      </div>
      <div className="announcements-page">
        <div className="section">
          <div className="box">

            <div className="announcements-page__list">

              {appContext.data.announcements.data.map(announcementItem => {
                return <div className="announcements-page__list__item">
                  <Link to={announcementItem.name}>
                    <span className="title">{announcementItem.title}</span>
                    <span className="date">{announcementItem.date}</span>
                  </Link >
                </div>
              })}
            </div>


            <ul className="pagination justify-content-center">
              <li className="page-item disabled">
                <a className="page-link" href="javascript:;"><i className="svg svg--chevron-left"></i></a>
              </li>
              <li className="page-item active"><a className="page-link" href="#">1</a></li>

              <li className="page-item">
                <a className="page-link" href="javascript:;"><i className="svg svg--chevron-right"></i></a>
              </li>
            </ul>

          </div>
        </div>

      </div>
      <Footer />
    </PageWrapper>
  )
}