import Career1Png from '../../_assets/img/career-1.png'
import Career2Png from '../../_assets/img/career-2.png'
import ToplumsalSorumlulukPng from '../../_assets/img/toplumsal-sorumluluk.png'
import GelisimPng from '../../_assets/img/gelisim.png'
import TakimCalismasiPng from '../../_assets/img/takim-calismasi.png'
import CalisanlarPng from '../../_assets/img/calisanlar.png'
import IsAhlakiPng from '../../_assets/img/is-ahlaki.png'
import TedarikcilerPng from '../../_assets/img/tedarikciler.png'
import HibritCalismaModeliPng from '../../_assets/img/hibrit-calisma-modeli.png'
import HayatSigortasiPng from '../../_assets/img/hayat-sigortasi.png'
import YurtdisiGorevlendirmePng from '../../_assets/img/yurtdisi-gorevlendirme.png'
import IsverenDestekliPng from '../../_assets/img/isveren-destekli-bireysel-emeklilik.png'
import UcretveOlanaklarPng from '../../_assets/img/ucret-ve-ek-olanaklar.png'
import ChessPng from '../../_assets/img/etkinlik/Chess.jpg'
import BogaziciJpg from '../../_assets/img/etkinlik/Bogazici.jpg'
import BowlingJpg from '../../_assets/img/etkinlik/Bowling.jpg'
import İkramPng from '../../_assets/img/etkinlik/İkram.png'
import Museum1JPG from '../../_assets/img/etkinlik/Museum1.jpg'
import SummerJPG from '../../_assets/img/etkinlik/Summer.jpg'
import TennisPng from '../../_assets/img/etkinlik/Tennis.png'
import Modal from 'react-bootstrap/Modal';
import { Footer } from 'Components/Footer'
import { Link, useLocation } from 'react-router-dom'
import { RouteDefinitions } from 'routes'
import { useContext, useEffect, useState } from 'react'
import { Context } from 'Context';
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Map } from './Map'

export const LifeInZT = () => {
  const appContext = useContext(Context)
  const [showImageModal, setShowImageModal] = useState("")
  const { hash } = useLocation();

  useEffect(() => {
    AOS.init();

  }, [])

  useEffect(() => {
    if (hash !== null) {
      const elementId = hash.slice(1);
      document.getElementById(elementId)?.scrollIntoView({
        behavior: 'smooth'
      });

    }
  }, [hash])

  const BreadCrumb = () => {
    return <div className="breadcrumb">
      <div>
        <ul>
          <li><Link to={RouteDefinitions.MainPage}>Ana Sayfa</Link></li>
          <li><Link to={"/#birlikte-basarmak"}>Kariyer</Link></li>
          <li>Ziraat Teknoloji'de Hayat</li>
        </ul>
      </div>
      <h1 className="section-title">Ziraat Teknoloji'de Hayat</h1>
    </div>
  }

  const enBreadCrumb = () => {
    return (
      <div className="breadcrumb">
        <div>
          <ul>
            <li><Link to={RouteDefinitions.MainPage}>Home</Link></li>
            <li><Link to={"/#birlikte-basarmak"}>Career</Link></li>
            <li>Life at Ziraat Teknoloji</li>
          </ul>
        </div>
        <h1 className="section-title">Life at Ziraat Teknoloji</h1>
      </div>
    );
  }



  const Content = () => {
    return <div className="life-ziraat-technology">
      <div className="section-one box box-full">
        <div className="d-flex flex-wrap section-one_item">
          <div className="col-12 col-md-6 description left order-1 order-md-0">
            <div id="kariyerine-yon-ver" className="title" data-aos="fade-right">Kariyerine Yön Ver</div>
            <div className="text-decoration" data-aos="fade-right">
              <p>Ziraat Teknoloji kariyer yolculuğunun her adımında çalışanlarımıza yükselme ve gelişim fırsatları sunar.</p>
              <p>Çalışanlarımız kariyer hedeflerini yöneticileri ile birlikte kendileri tanımlar. Böylece Ziraat Teknoloji, yöneticilerini de kendi içinden yetiştirir.</p>
              <p>
                Çalışanın kariyerinde yükselme, dikey veya yatay olabilir. Bir üst göreve geçebilmek için, yeni görev tanımının gerektirdiği bilgi ve deneyime sahip olmanın yanı sıra
                performans düzeyi de dikkate alınır.
              </p>
            </div>
          </div>
          <div className="col-12 col-md-6 image order-0 order-md-0 ps-lg-3">
            <img src={Career1Png} data-aos="fade-left" />
          </div>
        </div>
        <div className="d-flex flex-wrap section-one_item" >
          <div className="col-12 col-md-6 image order-0 order-md-0 pe-lg-3">
            <img src={Career2Png} data-aos="fade-right" />
          </div>
          <div className="col-12 col-md-6 description right order-1 order-md-0" id="potansiyelini-kesfet" >
            <div className="title" data-aos="fade-left">Potansiyelini Keşfet</div>
            <div className="text-decoration" data-aos="fade-left">
              <p>
                Çalışanlarımızın hem kendilerini hem de işlerini geliştirerek organizasyon içindeki katma değerlerini arttırmak amacıyla yurt içi ve yurt dışı eğitim programları
                sunuyoruz.
              </p>
              <p>
                Çalışanlarımızın yetkinliklerini sınıf içi eğitimlerin yanı sıra kişisel ve mesleki gelişimi destekleyen uzaktan eğitim ve işbaşı uygulamalı eğitim yöntemiyle de
                sürekli geliştiriyoruz.
              </p>
              <p>
                E-eğitimlerini internet ortamından zaman ve mekândan bağımsız ulaşarak tamamlayan çalışanlarımızın gelişimlerini desteklerken, motivasyonlarını da arttırmak en
                önemli hedefimiz.
              </p>
            </div>
          </div>
        </div>
      </div>

      <div className="section-two box box-full">
        <div className="sub-title" data-aos="fade-in" >Kurumsal İlke ve Değerlerimiz</div>
        <div className="col-12 section-two_item svg-map" data-aos="fade-in">
          <Map />

          <div className="description" data-aos="fade-in" data-aos-delay="400">
            <div className="description-box">
              <div className="title">Müşteriler</div>
              <div className="text-description">İç ve dış tüm müşterilerin memnuniyetini sağlamak için çalışıyoruz.</div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-three box box-full">
        <div className="row flex-wrap">
          <div className="col-12 col-md-4 left">
            <div className="section-three_item" data-aos="fade-up">
              <img src={ToplumsalSorumlulukPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">Toplumsal Sorumluluk</div>
                  <div className="text-description">Bulunduğumuz toplumun gelişimine katkıda bulunmanın bir görev olduğu bilinci ile hareket ediyoruz.</div>
                </div>
              </div>
            </div>
            <div className="section-three_item" data-aos="fade-up">
              <img src={GelisimPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">Gelişim</div>
                  <div className="text-description">Yapılan her işi geliştirmek için sürekli gözden geçiriyor ve iyileştiriyoruz.</div>
                </div>
              </div>
            </div>
            <div className="section-three_item" data-aos="fade-up">
              <img src={ToplumsalSorumlulukPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">Değişime Uyum</div>
                  <div className="text-description">Değişen teknolojiye ve diğer tüm koşullara uyum sağlamak için kendimizi sürekli yeniliyoruz.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 center">
            <div className="section-three_item" data-aos="fade-up" data-aos-delay="200">
              <img src={TakimCalismasiPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">Takım Çalışması ve Karar Alma</div>
                  <div className="text-description">
                    <p>Kararları, konuya en yakın ve uzman kişilerce yapılan detaylı araştırma ve analize dayanarak alıyor, Bütün işlerde ekip çalışmasından yararlanırken yenilikçilik,yaratıcılık ve çok sesliliği destekliyoruz. Bütün ilişkilerde saygılı, nazik ve hoşgörülü davranmaya özen gösteriyor.</p>
                    <p>Problemler karşısında çözüm odaklı yaklaşımı benimsiyoruz.</p>
                  </div>
                </div>
              </div>
            </div>
            <div className="section-three_item" data-aos="fade-up">
              <img src={CalisanlarPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">Çalışanlar</div>
                  <div className="text-description">Çalışanlara güven ve saygı ile yaklaşıyor, onlara öncülük ediyoruz. Kendilerini geliştirmeleri için, eğitim ve gerekli diğer olanakları sağlayarak destekliyor, Herkesin Şirket başarısına katkı sağlayacağına inanarak, üstün performansı takdir edip ödüllendiriyoruz, Tüm çalışan ilişkilerinde dürüstlüğü esas alıyoruz.</div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-12 col-md-4 right">
            <div className="section-three_item" data-aos="fade-up" data-aos-delay="400">
              <img src={IsAhlakiPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">İş Ahlakı</div>
                  <div className="text-description">Bütün faaliyetler, kararlar ve ilişkilerde en yüksek ahlaki değerleri gözetiyoruz.</div>
                </div>
              </div>
            </div>
            <div className="section-three_item" data-aos="fade-up">
              <img src={TedarikcilerPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">Tedarikçiler</div>
                  <div className="text-description">Tüm müşteri-tedarikçi ilişkilerinde tutarlı, adil ve dürüst davranmak, en yüksek ahlaki değerleri gözetiyoruz.</div>
                </div>
              </div>
            </div>
            <div className="section-three_item" data-aos="fade-up">
              <img src={ToplumsalSorumlulukPng} />
              <div className="description">
                <div className="description-box">
                  <div className="title">İletişim</div>
                  <div className="text-description">İletişimde karşılıklı bilgi verme ve dinlemeyi temel almak, herkesin düşüncelerini organizasyonun her düzeyinde açıkça ifade edebileceği bir ortam oluşturuyoruz.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="section-four">
        <div className="box box-full">
          <h3 className="sub-title" data-aos="fade-in">Ayrıcalıklarımız</h3>
          <div className="section-four_wrap">
            <div className="row flex-wrap mb-48">
              <div className="col-12 col-sm-6 col-md-3 section-four_item" data-aos="fade-up">
                <img src={HibritCalismaModeliPng} />
                <div className="description">
                  <div className="title">Hibrit Çalışma Modeli</div>
                  <div className="text-decoration">Aylık iş döneminin belirli günlerinde ofiste, belirli günlerinde ise uzaktan çalışma ile ofislerin yanı sıra evin ve ortak çalışma alanlarının da dahil edildiği esnek bir çalışma düzeni sunuyoruz.</div>
                </div>
              </div>
              <div className="col-12 col-sm-6 col-md-3 section-four_item" data-aos="fade-up" data-aos-delay="200">
                <img src={HayatSigortasiPng} />
                <div className="description">
                  <div className="title">Hayat Sigortası</div>
                  <div className="text-decoration">Ailemize katılan tüm çalışma arkadaşlarımız için koşulsuz hayat sigortası yapıyoruz.</div>
                </div>
              </div>
              <div className="col-12 col-sm-12 col-md-6 section-four_item" data-aos="fade-up" data-aos-delay="400">
                <img src={YurtdisiGorevlendirmePng} />
                <div className="description">
                  <div className="title">Çalışma Deneyiminizi Sınırların Ötesine Taşıyın</div>
                  <div className="text-decoration">Şirketimiz, Ziraat Bankası’nın yanı sıra yurt içi ve yurt dışı iştirakler dahil Ziraat Finans Grubunun tamamına bilgi teknolojileri alanında hizmet sağlayan küresel bir aktördür. Bu kapsamda; çalışanlarımıza yurt dışı faaliyet noktalarımızda da çalışma deneyimi kazanmaları için fırsatlar sunmayı önemsiyor ve önceliyoruz.</div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-6 section-four_item" data-aos="fade-up">
                <img src={IsverenDestekliPng} />
                <div className="description">
                  <div className="title">İşveren Destekli Bireysel Emeklilik</div>
                  <div className="text-decoration">Bireysel Emeklilik Sistemine dahil olan tüm çalışanlarımıza ücretlerinin %3’ü oranında biz de katkı sağlıyoruz. Ayrıca sisteme dahil olsun olmasın tüm çalışanlarımıza sabit tutarlı bireysel emeklilik katkısı sunuyoruz.</div>
                </div>
              </div>
              <div className="col-12 col-md-6 section-four_item" data-aos="fade-up" data-aos-delay="200">
                <img src={UcretveOlanaklarPng} />
                <div className="description">
                  <div className="title">Ücret ve Ek Olanaklar</div>
                  <div className="text-decoration">Çalışanlarımızı önemsiyor, iş yaşam dengesi önceliğimiz ile çalışanlarımız ve ailelerinin esenlik ve refahını güçlendirecek ücret ve ek olanaklar sağlıyoruz. Ziraat Teknoloji’liler performansa dayalı ödüllendirme sistemi, bireysel emeklilik, sağlık ve hayat sigortası, mobil hat ve telefon, eğitim destekleri, eş ve çocuklarına özel destekler ile Ziraat’li olmanın ayrıcalıklarını yaşıyor.</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>



      <div className="section-seven" data-aos="fade-in">
        <div className="box box-full">
          <h3 className="sub-title">Etkinlikler</h3>
          <div className="row">
            <div className="col-12 col-md-6">
              <div className="section-seven_item section-seven_item-full">
                <div className="section-seven_item-img">
                  <img src={SummerJPG} />
                </div>
                <div className="description">
                  <div className="description-box">
                    <div className="title">Yaza Merhaba Şenliği</div>
                    <div className="date">
                      <i className="svg svg--calendar"></i>
                      15 Ağustos 2023
                    </div>
                    <div className="text-description">Yazın gelişini hep birlikte kutluyoruz. Canlı müzik, eğlenceli aktiviteler ve sokak lezzetleri eşliğinde yazın enerjisini ofise taşıyoruz.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-6">
              <div className="row">

                <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(BogaziciJpg)} >
                  <div className="section-seven_item-right-img">
                    <img src={BogaziciJpg} style={{ cursor: "pointer" }} />
                  </div>
                  <span>
                    <i className="svg svg--event-photos"></i>
                  </span>
                </div>
                <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(BowlingJpg)} >
                  <div className="section-seven_item-right-img">
                    <img src={BowlingJpg} style={{ cursor: "pointer" }} />
                  </div>
                  <span>
                    <i className="svg svg--event-photos"></i>
                  </span>
                </div>
                <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(İkramPng)} >
                  <div className="section-seven_item-right-img">
                    <img src={İkramPng} style={{ cursor: "pointer" }} />
                  </div>
                  <span>
                    <i className="svg svg--event-photos"></i>
                  </span>
                </div>
                <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(Museum1JPG)} >
                  <div className="section-seven_item-right-img">
                    <img src={Museum1JPG} style={{ cursor: "pointer" }} />
                  </div>
                  <span>
                    <i className="svg svg--event-photos"></i>
                  </span>
                </div>
                <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(ChessPng)} >
                  <div className="section-seven_item-right-img">
                    <img src={ChessPng} style={{ cursor: "pointer" }} />
                  </div>
                  <span>
                    <i className="svg svg--event-photos"></i>
                  </span>
                </div>
                <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(TennisPng)} >
                  <div className="section-seven_item-right-img">
                    <img src={TennisPng} style={{ cursor: "pointer" }} />
                  </div>
                  <span>
                    <i className="svg svg--event-photos"></i>
                  </span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>





    </div>
  }

  const enContent = () => {
    return (
      <div className="life-ziraat-technology">
        <div className="section-one box box-full">
          <div className="d-flex flex-wrap section-one_item">
            <div className="col-12 col-md-6 description left order-1 order-md-0">
              <div className="title" data-aos="fade-right">Shape Your Career</div>
              <div className="text-decoration" data-aos="fade-right">
                <p>Ziraat Teknoloji offers advancement and development opportunities to its employees at every step of their career journey.</p>
                <p>Our employees define their career goals in collaboration with their managers. Thus, Ziraat Teknoloji cultivates its managers from within.</p>
                <p>
                  Career advancement can be vertical or horizontal. To move to a higher position, not only the knowledge and experience required by the new job description are considered but also the performance level.
                </p>
              </div>
            </div>
            <div className="col-12 col-md-6 image order-0 order-md-0 ps-lg-3">
              <img src={Career1Png} data-aos="fade-left" />
            </div>
          </div>
          <div className="d-flex flex-wrap section-one_item" >
            <div className="col-12 col-md-6 image order-0 order-md-0 pe-lg-3">
              <img src={Career2Png} data-aos="fade-right" />
            </div>
            <div className="col-12 col-md-6 description right order-1 order-md-0"  id="potansiyelini-kesfet">
              <div className="title" data-aos="fade-left">Discover Your Potential</div>
              <div className="text-decoration" data-aos="fade-left">
                <p>
                  In order to increase the value of our employees within the organization by developing both themselves and their work, we offer domestic and international training programs.
                </p>
                <p>
                  We continuously develop the competencies of our employees through remote education and on-the-job training, in addition to classroom training that supports personal and professional development.
                </p>
                <p>
                  Supporting our employees' development by completing their e-learning independently of time and place over the internet is our main goal to increase their motivation.
                </p>
              </div>
            </div>
          </div>
        </div>

        <div className="section-two box box-full">
          <div className="sub-title" data-aos="fade-in">Our Corporate Principles and Values</div>
          <div className="col-12 section-two_item svg-map" data-aos="fade-in">
            <Map />

            <div className="description" data-aos="fade-in" data-aos-delay="400">
              <div className="description-box">
                <div className="title">Customers</div>
                <div className="text-description">We work to ensure the satisfaction of all internal and external customers.</div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-three box box-full">
          <div className="row flex-wrap">
            <div className="col-12 col-md-4 left">
              <div className="section-three_item" data-aos="fade-up">
                <img src={ToplumsalSorumlulukPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Social Responsibility</div>
                    <div className="text-description">We act with the awareness that contributing to the development of the community we are in is a duty.</div>
                  </div>
                </div>
              </div>
              <div className="section-three_item" data-aos="fade-up">
                <img src={GelisimPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Development</div>
                    <div className="text-description">We continuously review and improve every task we undertake.</div>
                  </div>
                </div>
              </div>
              <div className="section-three_item" data-aos="fade-up">
                <img src={ToplumsalSorumlulukPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Adaptation to Change</div>
                    <div className="text-description">To adapt to changing technology and all other conditions, we constantly renew ourselves.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 center">
              <div className="section-three_item" data-aos="fade-up" data-aos-delay="200">
                <img src={TakimCalismasiPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Teamwork and Decision Making</div>
                    <div className="text-description">
                      <p>We make decisions based on detailed research and analysis by the closest and most knowledgeable individuals to the subject. While benefiting from teamwork in all tasks, we support innovation, creativity, and diversity. We strive to be respectful, polite, and tolerant in all relationships.</p>
                      <p>We adopt a solution-oriented approach in the face of problems.</p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="section-three_item" data-aos="fade-up">
                <img src={CalisanlarPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Employees</div>
                    <div className="text-description">We approach employees with trust and respect, leading them. We support them in developing themselves by providing training and other necessary opportunities. Believing that everyone contributes to the company's success, we appreciate and reward outstanding performance. We prioritize honesty in all employee relationships.</div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-md-4 right">
              <div className="section-three_item" data-aos="fade-up" data-aos-delay="400">
                <img src={IsAhlakiPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Business Ethics</div>
                    <div className="text-description">We adhere to the highest ethical values in all activities, decisions, and relationships.</div>
                  </div>
                </div>
              </div>
              <div className="section-three_item" data-aos="fade-up">
                <img src={TedarikcilerPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Suppliers</div>
                    <div className="text-description">We maintain consistent, fair, and honest behavior in all customer-supplier relationships, adhering to the highest ethical values.</div>
                  </div>
                </div>
              </div>
              <div className="section-three_item" data-aos="fade-up">
                <img src={ToplumsalSorumlulukPng} />
                <div className="description">
                  <div className="description-box">
                    <div className="title">Communication</div>
                    <div className="text-description">We create an environment where mutual information sharing and listening are fundamental in communication, allowing everyone to express their thoughts openly at all levels of the organization.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-four">
          <div className="box box-full">
            <h3 className="sub-title" data-aos="fade-in">Our Privileges</h3>
            <div className="section-four_wrap">
              <div className="row flex-wrap mb-48">
                <div className="col-12 col-sm-6 col-md-3 section-four_item" data-aos="fade-up">
                  <img src={HibritCalismaModeliPng} />
                  <div className="description">
                    <div className="title">Hybrid Working Model</div>
                    <div className="text-decoration">We offer a flexible working arrangement that includes working in the office on specific days and remote work on other days during the monthly work period, incorporating both home and shared working spaces.</div>
                  </div>
                </div>
                <div className="col-12 col-sm-6 col-md-3 section-four_item" data-aos="fade-up" data-aos-delay="200">
                  <img src={HayatSigortasiPng} />
                  <div className="description">
                    <div className="title">Life Insurance</div>
                    <div className="text-decoration">We provide unconditional life insurance for all our colleagues who join our family.</div>
                  </div>
                </div>
                <div className="col-12 col-sm-12 col-md-6 section-four_item" data-aos="fade-up" data-aos-delay="400">
                  <img src={YurtdisiGorevlendirmePng} />
                  <div className="description">
                    <div className="title">Take Your Work Experience Beyond Borders</div>
                    <div className="text-decoration">Our company is a global player that provides information technology services to the entire Ziraat Financial Group, including Ziraat Bank as well as domestic and international subsidiaries. In this context, we value and prioritize offering opportunities for our employees to gain work experience in our international locations.</div>
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-12 col-md-6 section-four_item" data-aos="fade-up">
                  <img src={IsverenDestekliPng} />
                  <div className="description">
                    <div className="title">Employer-Supported Individual Retirement</div>
                    <div className="text-decoration">For all employees enrolled in the Individual Retirement System, we contribute 3% of their salaries. Additionally, whether they are enrolled in the system or not, we offer a fixed amount of individual retirement contribution to all our employees.</div>
                  </div>
                </div>
                <div className="col-12 col-md-6 section-four_item" data-aos="fade-up" data-aos-delay="200">
                  <img src={UcretveOlanaklarPng} />
                  <div className="description">
                    <div className="title">Compensation and Additional Benefits</div>
                    <div className="text-decoration">We value our employees and provide compensation and additional benefits that strengthen the well-being and welfare of our employees and their families as a priority of work-life balance. Ziraat Teknoloji employees experience the privileges of being part of Ziraat through a performance-based reward system, individual retirement, health and life insurance, mobile phone and line, education support, and special support for spouses and children.</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="section-seven" data-aos="fade-in">
          <div className="box box-full">
            <h3 className="sub-title">Events</h3>
            <div className="row">
              <div className="col-12 col-md-6">
                <div className="section-seven_item section-seven_item-full">
                  <div className="section-seven_item-img">
                    <img src={SummerJPG} />
                  </div>
                  <div className="description">
                    <div className="description-box">
                      <div className="title">Summer Greetings Festival</div>
                      <div className="date">
                        <i className="svg svg--calendar"></i>
                        August 15, 2023
                      </div>
                      <div className="text-description">We are celebrating the arrival of summer together. Bringing the energy of summer to the office with live music, fun activities, and street flavors.</div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-6">
                <div className="row">
                  <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(BogaziciJpg)} >
                    <div className="section-seven_item-right-img">
                      <img src={BogaziciJpg} />
                    </div>
                    <span>
                      <i className="svg svg--event-photos"></i>
                    </span>
                  </div>
                  <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(BowlingJpg)} >
                    <div className="section-seven_item-right-img">
                      <img src={BowlingJpg} />
                    </div>
                    <span>
                      <i className="svg svg--event-photos"></i>
                    </span>
                  </div>
                  <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(İkramPng)} >
                    <div className="section-seven_item-right-img">
                      <img src={İkramPng} />
                    </div>
                    <span>
                      <i className="svg svg--event-photos"></i>
                    </span>
                  </div>
                  <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(Museum1JPG)} >
                    <div className="section-seven_item-right-img">
                      <img src={Museum1JPG} />
                    </div>
                    <span>
                      <i className="svg svg--event-photos"></i>
                    </span>
                  </div>
                  <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(ChessPng)} >
                    <div className="section-seven_item-right-img">
                      <img src={ChessPng} />
                    </div>
                    <span>
                      <i className="svg svg--event-photos"></i>
                    </span>
                  </div>
                  <div className="col-6 col-sm-4 section-seven_item-right" onClick={() => setShowImageModal(TennisPng)} >
                    <div className="section-seven_item-right-img">
                      <img src={TennisPng} />
                    </div>
                    <span>
                      <i className="svg svg--event-photos"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>


      </div>
    );
  }


  return (
    <div className="subpage">
      {appContext.userLanguage === "tr" ? BreadCrumb() : enBreadCrumb()}
      {appContext.userLanguage === "tr" ? Content() : enContent()}
      <Footer />


      <Modal centered size='lg' show={showImageModal !== ""} id="fotoModal" onHide={() => setShowImageModal("")}>
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body><img src={showImageModal} /></Modal.Body>
      </Modal>

    </div>
  )
}