import Icon from '../../_assets/img/icons/our-services-icon-1.svg'
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import { useContext, useEffect, useState } from 'react'
import { Context } from 'Context';


export const OurServices = (props: any) => {
  const appContext = useContext(Context)
  const servicesData = appContext.data.sections.find(section => section.name === "hizmetlerimiz");
  const firstItem = servicesData?.children[0]
  const [activeKey, setActiveKey] = useState<any>(firstItem?.name)

  useEffect(() => {
    if (props.selectedSubSection) {
      setActiveKey(props.selectedSubSection)
    }
  }, [props.selectedSubSection])

  return (
    <div className="our-services section header-white hizmetlerimiz" data-id="hizmetlerimiz">
      <div className="box ">
        <h1 className="section-title white">{servicesData?.title}</h1>

        <Tab.Container id="left-tabs-example" activeKey={activeKey} onSelect={setActiveKey}>
          <Row>
            <Col className=" col-12 col-md-4 our-services__left">
              <Nav className="flex-column">
                {servicesData?.children.map((serviceItem, idx) => {
                  return <Nav.Item key={idx}>
                    <Nav.Link eventKey={serviceItem.name}>
                      <span className="title">{serviceItem.title}</span>
                      <span className="content">
                        <img className="icon" src={Icon} alt="" />
                        <span className="sub-title">{serviceItem.title}</span>
                        <p className="text" onWheel={(event) => event.stopPropagation()}>{serviceItem.content}</p>
                      </span>
                    </Nav.Link>
                  </Nav.Item>
                })}
              </Nav>
            </Col>

            <Col className="col-8 our-services__right d-none d-md-block">
              <Tab.Content>
                {servicesData?.children.map((serviceItem, idx) => {
                  return <Tab.Pane key={idx} eventKey={serviceItem.name}>
                    <div className="our-services__right-img" style={{ backgroundImage: `url(_assets/img/${serviceItem.image})` }}></div>
                  </Tab.Pane>
                })}
              </Tab.Content>
            </Col></Row>
        </Tab.Container>



      </div>
    </div>
  )
}