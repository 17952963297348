import { useContext } from "react"
import { Context } from 'Context';
import classNames from "classnames";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";

export const OpenPositions = (props: any) => {
  const appContext = useContext(Context)
  const openPositionsData = appContext.data.sections.find(section => section.name === "acik-pozisyonlar");


  const MobileSwiper = () => {

    return (<Swiper
      threshold={0}
      touchReleaseOnEdges
      className='col-12 success-together-advert-swiper swiper d-block '
      autoHeight
      slidesPerView={1.3}
      modules={[Navigation]}
      spaceBetween={30}
      loop={false}
      grabCursor
      breakpoints={{
        991: {
          slidesPerView: 3,
          slidesOffsetAfter: 0,

        }
      }}
    >
      {openPositionsData?.children.map((openPositionItem, idx) => {

        const wrapperClass = classNames({

          "success-together-advert_item": true,
          "all-item": idx === 0
        })
        return <SwiperSlide key={idx} className={wrapperClass}
        >
          <div className={wrapperClass} >
            <div className=" box success-together_advert">
              <div className="success-together_advert-icon">
                <i className={openPositionItem.image}></i>
              </div>
              <div className="success-together_advert-title">{openPositionItem.title}</div>
              <div className="success-together_advert-location">{openPositionItem.content}</div>
              <a className="success-together_advert-btn" href={openPositionItem.link} target="_blank">{openPositionItem.linkAlt}</a>
            </div>
          </div>
        </SwiperSlide>
      })}

    </Swiper>)

  }


  return (
    <div className="success-together section acik-pozisyonlar" data-id="acik-pozisyonlar">
      <div className="box">
        <h1 className="pl-16 section-title white">{openPositionsData?.title}</h1>
        <div>
          {MobileSwiper()}
        </div>
      </div>
    </div>
  )
}