import Table from 'react-bootstrap/Table';
import Pagination from 'react-bootstrap/Pagination';

import { initializeApp } from 'firebase/app';
import { getFirestore, collection, getDocs, addDoc } from 'firebase/firestore/lite';
import { useContext, useEffect, useReducer, useRef, useState } from 'react';
import { PageWrapper } from 'Components/PageWrapper'
import { Context } from 'Context';
import ExcelJS from 'exceljs';


// Firebase yapılandırma bilgilerini buraya ekleyin
const firebaseConfig = {
    apiKey: "AIzaSyCb55Zgr6QOD7UuJz6cjQreBCMILaY3mLo",
    authDomain: "kariyer-gunleri-414516.firebaseapp.com",
    projectId: "kariyer-gunleri-414516",
    storageBucket: "kariyer-gunleri-414516.appspot.com",
    messagingSenderId: "189306712616",
    appId: "1:189306712616:web:c81a7729afde540855bb1d"
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);

export const CareerDaysList = () => {
    const appContext = useContext(Context)
    const [data, setData] = useState<any>([])
    const [currentPage, setCurrentPage] = useState(1);
    const [search, setSearch] = useState('');



    useEffect(() => {
        getDocuments();
    }, [])

    const getDocuments = async () => {
        try {
            const basvurularCol = collection(db, 'basvurular');
            const documents = await getDocs(basvurularCol);
            const data = documents.docs.map(doc => doc.data());
            setData(data)
        }
        catch (err) {
            console.log("err", err)
        }
    }

    const handleExportToExcel = async () => {
        const workbook = new ExcelJS.Workbook();
        const worksheet = workbook.addWorksheet('data');

        // Başlık satırını ekleyin
        worksheet.columns = [
            { header: 'Ad Soyad', key: 'name', width: 20 },
            { header: 'TCKN', key: 'tckn', width: 20 },
            { header: 'Üniversite', key: 'university', width: 30 },
            { header: 'Lisans', key: 'bachelorDepartment', width: 30 },
            { header: 'Sınıf', key: 'classYear', width: 20 },
            { header: 'Mezuniyet', key: 'graduation', width: 30 },
            { header: 'Başvuru', key: 'applicationType', width: 40 },
            { header: 'Eposta', key: 'email', width: 40 },
            { header: 'Telefon', key: 'phoneNumber', width: 20 },
            { header: 'Kayıt Tarihi', key: 'date', width: 20 },
            { header: 'OSYM', key: 'osym', width: 20 },
            { header: 'GPA', key: 'gpa', width: 20 },
        ];


        // Verileri ekleyin
        data.forEach((item: any) => {
            worksheet.addRow(item);
        });

        const date = new Date();
        const fileName = `basvuranlar_${(date.getDate())}0${(date.getMonth() + 1)}${date.getFullYear()}_${(date.getHours())}${(date.getMinutes())}.xlsx`;


        // Dosyayı kaydedin
        await workbook.xlsx.writeBuffer().then(buffer => {
            const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            a.click();
            window.URL.revokeObjectURL(url);
        });

    };



    const itemsPerPage = 10;

    const handlePageChange = (pageNumber: number) => {
        setCurrentPage(pageNumber);
    };


    const handleSearch = (e: any) => {
        setSearch(e.target.value);
    };

    const filteredData = data.filter((item: any) => {
        const searchTerms = search.toLowerCase().split(' ');
        return searchTerms.every((term) => {
            return Object.values(item).some((value: any) => value.toString().toLowerCase().includes(term));
        });
    });
    const startItem = (currentPage - 1) * itemsPerPage;
    const endItem = startItem + itemsPerPage;
    const currentPageData = filteredData.slice(startItem, endItem);
debugger
    // Tarayıcıdaki URL'yi al
    const currentURL = window.location.href;

    // URL'nin belirli bir desenle başlayıp başlamadığını kontrol et
    const isValidURL = currentURL.startsWith('http://tstfinixweb01.zb:8088/') ||currentURL.startsWith('http://localhost:3000');

    return isValidURL ? <PageWrapper>
        <div className="box" style={{ maxWidth: "750px" }}>
            <div className="breadcrumb mb-3 px-0">
                <div>
                    <ul>
                        <li><a href="/">{appContext.data.commonWords.find((item: any) => item.key === 'home-page')!.label}</a></li>
                        <li>Kariyer Günleri Başvuranlar</li>
                    </ul>
                </div>
            </div>
        </div>
        <div className="contact-page">
            <div className="box" style={{ maxWidth: "95%" }}>
                <div style={{ padding: "10px", display: "flex", width: "325px", justifyContent: "space-between" }}>
                    <input type="text" placeholder="Ara..." value={search} onChange={handleSearch} />
                    <button onClick={handleExportToExcel}>Excel'e Aktar</button>
                </div>
                <Table striped bordered hover style={{ minHeight: "400px" }}>
                    <thead>
                        <tr>
                            <th>#</th>
                            <th>Ad Soyad</th>
                            <th>TCKN</th>
                            <th>Üniversite</th>
                            <th>Lisans</th>
                            <th>Sınıf</th>
                            <th>Mezuniyet</th>
                            <th>Başvuru</th>
                            <th>Eposta</th>
                            <th>Telefon</th>
                            <th>Tarih</th>
                            <th>OSYM </th>
                            <th>GPA</th>
                        </tr>
                    </thead>
                    <tbody>
                        {currentPageData.map((applier: any, index: number) => {

                            return (<tr>
                                <td>{startItem + index + 1}</td>
                                <td>{applier.name}</td>
                                <td>{applier.tckn}</td>
                                <td>{applier.university}</td>
                                <td>{applier.bachelorDepartment}</td>
                                <td>{applier.classYear}</td>
                                <td>{applier.graduation}</td>
                                <td>{applier.applicationType}</td>
                                <td>{applier.email}</td>
                                <td>{applier.phoneNumber}</td>
                                <td>{applier.date}</td>
                                <td>{applier.osym}</td>
                                <td>{applier.gpa}</td>
                            </tr>)
                        })}

                    </tbody>
                </Table>
                <Pagination>
                    <Pagination.Prev onClick={() => handlePageChange(currentPage - 1)} />
                    {Array.from(Array(Math.ceil(data.length / 10))).map((_, index) => (
                        <Pagination.Item key={index + 1} active={currentPage === index + 1} onClick={() => handlePageChange(index + 1)}>
                            {index + 1}
                        </Pagination.Item>
                    ))}

                    <Pagination.Next onClick={() => handlePageChange(currentPage + 1)} />
                </Pagination>
            </div>

        </div>
    </PageWrapper> : null
}